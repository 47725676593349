<template>
    <div>
        <div class="loading" v-if="loading">
            <Pulse loading class="loader" color="#999999"></Pulse>
        </div>
        <div class="loaded" v-if="!loading">
            <b-alert show v-if="webinars.length === 0" title="yo" variant="warning">
                <h4 class="alert-heading">No Upcoming Webinars</h4>
                <p class="mb-0">You are not registered for any upcoming Webinars. You can register for them from the Dashboard.</p>
            </b-alert>
            <div class="webinars" v-else>
                <h4 class="dashboard-pages__title mb-0">My Upcoming CPD Webinars</h4>
                <p>Here are the webinars you're registered onto.</p>
                <MyWebinarItem v-for="webinar in webinars" v-bind:key="webinar.webinarIdStr" :webinar="webinar" />
            </div>
        </div>
    </div>
</template>

<script>
    import Pulse from "../../components/loaders/Pulse";
    import MyWebinarItem from "../../components/webinars/MyWebinarItem";

    export default {
        name: "MyWebinars",
        components: {MyWebinarItem, Pulse},
        data: function() {
            return {
                loading: true,
                webinars: []
            }
        },
        methods: {
          loadWebinars: function() {
              this.loading = true;
              this.$http.get('webinars/registered').then((resp) => {
                  this.webinars = resp.body.map((webinar) => {
                      webinar.webinarDescription = webinar.webinarDescription.replace(/(?:\r\n|\r|\n)/g, '<br>');
                      webinar.startDate = new Date(webinar.startDate);
                      webinar.endDate = new Date(webinar.endDate);
                      return webinar;
                  });
                  this.loading = false;
              }).catch((resp) => {
                  this.$httpError('Failed to load Webinars', resp);
              }).then(() => {
                  this.laoding = false;
              });
          }
        },
        created() {
            this.loadWebinars();
        }
    }
</script>

<style scoped>

</style>