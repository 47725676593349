<template>
    <div class="activities">
        <p>
            This section will guide you through pulling together CPD activities which you have undertaken in the last
            two years (since you last renewed your registration). This demonstrates to the HCPC that you have undertaken
            CPD and kept an accurate record (Standard 1).
        </p>
        <p>
            Please select the CPD Diary entries below that you wish to submit to the HCPC. This should be between 12 -
            50 entries, ensuring you have no more than 3 months gap between each activity.
        </p>
        <p>
            If you do have a gap of more than 3 months in your CPD activities, please ensure justification is included
            in your statement as part of Step 3 - "Statement".
        </p>

        <EntryList :access-token="accessToken" v-model="selectedEntries" />

        <div class="actions">
            <AnimatedButton title="Create PDF" alt-title="PDF Created!" variant="light" alt-variant="success" @click="createPdf" :disabled="selectedEntries.length === 0"><FileDocumentOutlineIcon /></AnimatedButton>
            <AnimatedButton ref="markComplete" :alt="completed" variant="light" alt-variant="success" title="Mark Complete" alt-title="Completed" @click="markComplete"><CheckCircleOutlineIcon /></AnimatedButton>
        </div>

        <PreviewActivityModal ref="previewModal" />

    </div>
</template>

<script>
    import EntryList from "../../components/hcpc-portal/EntryList";
    import AnimatedButton from "../../components/common/AnimatedButton";
    import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline';
    import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline';
    import PreviewActivityModal from "../../components/hcpc-portal/PreviewActivityModal";

    export default {
        name: "Activities",
        components: {PreviewActivityModal, AnimatedButton, EntryList, FileDocumentOutlineIcon, CheckCircleOutlineIcon},
        computed: {
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            },
            selectedEntries: {
                get() {
                    return this.$store.state.hcpcPortal.entries;
                },
                set(val) {
                    this.$store.commit('hcpcPortal/setEntries', val);
                }
            },
            completed: {
                get() {
                    return this.$store.state.hcpcPortal.completed.activities;
                },
                set(val) {
                    this.$store.commit('hcpcPortal/markCompleted_Activities', val);
                }
            }
        },
        data() {
        },
        watch: {
            selectedEntries(newValue, oldValue) {
                if (!this.$utilities.arraysEqual(newValue, oldValue)) {
                    this.unmarkComplete();
                }
            }
        },
        methods: {
            createPdf($e) {
                  $e.animate();
                  this.$refs.previewModal.show(this.selectedEntries);
            },
            markComplete($e) {
                $e.update();
                this.$store.commit('hcpcPortal/markCompleted_Activities', true);
            },
            unmarkComplete() {
                this.$refs.markComplete.reset();
                this.$store.commit('hcpcPortal/markCompleted_Activities', false);
            }
        }
    }
</script>

<style scoped>
    div.actions {
        margin-top: 20px;
        width: 100%;
        text-align: right;
    }

    div.actions > * {
        margin-right: 5px;
    }
</style>
