<template>
  <div class="calendar-events__listing">
    <CalendarEvent v-for="(event, index) in sortEvents(events)" @eventClicked="$emit('eventClicked', event)"
      :event="event" :key="index" />
  </div>
</template>

<script>
import CalendarEvent from "./CalendarEvent.vue"
export default {
  name: 'CalendarDashboard',
  components: { CalendarEvent },
  props: {
    events: Array
  },
  methods: {
    sortEvents(events) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      
      const filteredEvents = events.filter((event) => {
        const eventDate = new Date(event.start);
        const eventMonth = eventDate.getMonth();
        const eventYear = eventDate.getFullYear();
        
        return eventYear > currentYear || (eventYear === currentYear && eventMonth >= currentMonth);
      });
      
      const sortedEvents = filteredEvents.sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return dateA - dateB;
      });
      
      return sortedEvents;
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-events__listing {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
