<template>
    <div class="welcome-box">
        <h1 class="font-weight-light text-light h1">Welcome to</h1>
        <img width="300" alt="cpdme logo" class="logo" src="../../assets/auth/cpdme-white-logo.png">
        <p class="h5 pt-2 font-weight-light">Portfolio Building Made Simple</p>
        <div class="pt-4">
            <a href="https://www.cpdme.com/join"><b-button variant="default" size="lg" class="cpd-blue button-pill text-uppercase pl-4 pr-4 pt-2 pb-2 shadow">Learn More</b-button></a>
            <a href="#" class="float-right" @click.prevent="showVideo"><img alt="play button" width="50" src="../../assets/auth/ic-play.svg"></a>
        </div>
        <ModalVideo ref="videoModal" />
    </div>
</template>

<script>
    import ModalVideo from "@/components/authentication/ModalVideo";
    export default {
        name: "WelcomeBox",
        components: {ModalVideo},
        methods: {
            showVideo() {
                this.$refs.videoModal.show();
            }
        }
    }
</script>

<style scoped>
    .button-pill {
        border-radius: 20px;
        height: 50px;
        font-size: 14px;
        color: white;
    }

    .cpd-blue{
        background-color:#6a87d3 !important;
        border-color:#6a87d3 !important;
    }
    .cpd-blue:hover{
        background-color:#7795db !important;
    }
    .welcome-box {
        font-family: 'Roboto', sans-serif;
        width: 300px;
        height: 330px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .welcome-box p {
        font-size: 22px;
        color: white;
    }

    .welcome-box .h1 {
        font-size: 54px;
    }

    @media (max-width: 768px) {
        .welcome-box {
            padding-top: 20px;
            width: 200px;
        }
        .welcome-box h1{
            font-size:37px !important;
        }
        .welcome-box > img{
            width:200px;
        }
        .welcome-box p{
            font-size:14px;
        }
    }

</style>
