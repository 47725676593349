<template>
    <div class="login-nav">
        <div class="tab mt-3">

            <router-link exact-active-class="active" class="tab-links p-3 hide-mobile" :to="{name: 'mobile-login-v2'}">MOBILE APP</router-link>
            <router-link active-class="active" class="tab-links p-3" :to="{name: 'login-v2'}">LOGIN</router-link>
            <router-link active-class="active" class="tab-links p-3" :to="{name: 'register-v2'}">REGISTER</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Navigation"
    }
</script>

<style scoped>
    .login-nav {
        position: absolute;
        right:0;
    }
    .tab {
        display: grid;
    }
    .tab a {
        background:none;
        border: none;
        color:white;
    }
    .tab a:hover {
        text-decoration: none;
    }
    .tab a:focus {
        outline: 0 !important;
    }
    .tab-links.active {
        background-color:white;
        border: none;
        outline: none;
        border-radius:15px 0 0 15px;
        color:black;
        -webkit-box-shadow: 0px 3px 15px -6px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 3px 15px -6px rgba(0,0,0,0.5);
        box-shadow: 0px 3px 15px -6px rgba(0,0,0,0.5);
    }

    @media (max-width: 768px) {
        .tab {
            display: unset;
        }
        .login-nav {
            bottom:0;
        }
        .tab-links.active {
            border-radius: 15px 15px 0 0 !important;
        }

        .tab-links.hide-mobile {
            display:none;
        }

    }

</style>