<template>
    <b-form-group label="Tag this entry" label-class="label-title pb-0" description="You can add and customise your tags in the Profile section.">
        <b-form-tags
            id="tags-component-select"
            v-model="selectedOptions"
            size="lg"
            class="mb-2"
            add-on-change
            no-outer-focus
        >
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                            @remove="removeTag(tag)"
                            :title="tag"
                            :disabled="disabled"
                            variant="info"
                        >{{ tag }}</b-form-tag>
                    </li>
                </ul>
                <b-form-select
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                    :disabled="disabled || availableOptions.length === 0"
                    :options="availableOptions"
                >
                    <template #first>
                        <!-- This is required to prevent bugs with Safari -->
                        <option disabled value="">Choose a tag...</option>
                    </template>
                </b-form-select>
            </template>
        </b-form-tags>
    </b-form-group>
</template>

<script>
import Providers from "@/providers";

export default {
    name: "Tags",
    props: {
        value: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            selectedOptions: [],
            options: [],
        }
    },
    watch: {
        selectedOptions() {
            this.$emit('input', [...this.selectedOptions]);
        }
    },
    computed: {
        availableOptions() {
            return this.options.filter(val => this.selectedOptions.indexOf(val) === -1);
        }
    },
    methods: {
        loadTagOptions() {
            Providers.profile.tags().then((tags) => {
                this.options = tags;
            }).catch((err) => {
                this.$httpError('Failed to load tags', err);
            });
        }
    },
    created() {
        if (this.value) {
            this.selectedOptions = [...this.value];
        }
        this.loadTagOptions();
    }
}
</script>

<style scoped>

</style>
