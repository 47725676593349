<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  #app {
    display: flex;
    flex-direction: column;
    font-family: Arial, 'Avenir', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    height: 100%;
  }

</style>

<script>

  export default {
    name: "app",
    created: function() {
      this.$store.dispatch('authentication/configureHttpClient');
    }
  }

</script>

