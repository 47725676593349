<template>
    <b-card header="Upcoming Webinars" class="webinar-card" header-bg-variant="white"
        header-class="view-all__webinars-card-header">
        <PulseLoader v-if="this.loading"></PulseLoader>
        <div class="webinars" v-else>
            <carousel :per-page="1" :navigation-enabled="true" :pagination-enabled="false" :draggable="false"
                v-if="this.webinars.length > 0">
                <slide class="webinars-carousel__item" v-for="webinar in this.webinars" :key="webinar.webinarId">
                    <h4 class="webinar-title"> {{ webinar.title }} </h4>
                    <ReadMore class="webinar-description" :action-disabled="webinar.registered"
                        :action-callback="function () { registerForWebinar(webinar) }" :action-title="actionTitle(webinar)"
                        more-str="See more..." :date="webinar.startDate" less-str="Show less" :text="webinar.webinarDescription" :max-chars="200">
                    </ReadMore>
                </slide>
            </carousel>
            <b-alert variant="info" show v-else>
                Sorry, there are no Webinars available at the moment. Please check back later!
            </b-alert>
        </div>
    </b-card>
</template>

<script>
import ReadMore from './ReadMore';
import PulseLoader from './loaders/Pulse'

export default {
    name: "Webinars",
    components: { ReadMore, PulseLoader },
    data: function () {
        return {
            webinars: [],
            loading: true,
            error: null
        }
    },
    methods: {
        loadWebinars: function () {
            this.error = null;
            this.loading = true;
            this.$http.get('webinars').then(response => {
                this.webinars = response.body.map((webinar) => {
                    webinar.webinarDescription = webinar.webinarDescription.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    webinar.startDate = new Date(webinar.startDate);
                    webinar.endDate = new Date(webinar.endDate);
                    return webinar;
                });
            }, error => {
                this.error = error.body.message;
            }).then(() => {
                this.loading = false;
            });
        },
        registerForWebinar: function (webinar) {
            this.loading = true;
            this.$http.post(`webinars/${webinar.webinarIdStr}/register`).then(() => {
                webinar.registered = true;
                this.$success('Registration Successful', 'Thanks for registering. You\'ll shortly receive an email containing further details.')
            }).catch((resp) => {
                alert('Failed to register for webinar. ' + resp.body.message);
            }).finally(() => {
                this.loading = false;
            });
        },
        actionTitle: function (webinar) {
            if (webinar.registered) {
                return 'Already Registered';
            } else {
                return 'Register for webinar';
            }
        }
    },
    created: function () {
        this.loadWebinars();
    }
}
</script>

<style scoped lang="scss">
.loading {
    text-align: center;
    align-items: center;
}

.webinar-title {
    font-size: 18px;
    font-weight: bold;
}

.webinar-time {
    color: #7c7c7c
}

.webinar-description {
    color: #343434;
    font-size: 16px;
}

.webinar-description p {
    transition: all 0.4s ease 0s;
}

.webinar-register {
    float: right;
}

.VueCarousel {
    margin-left: 10px;
    margin-right: 10px;
}

.VueCarousel-wrapper {
    padding-left: 10px;
    padding-right: 10px;
}

.view-all__webinars-card-header {
    color: #8ba7b8;
    font-weight: 500;
}

.webinars-carousel__item {
    height: fit-content;
}
</style>

<style>.VueCarousel-navigation--disabled {
    opacity: 0.2 !important;
}</style>