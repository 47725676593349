<template>
    <div class="mobile-sidebar">
        <b-modal header-class="mobile-sidebar__header" body-class="mobile-sidebar__modal" ref="modal" title="" size="full-screen" class="full-screen" hide-footer>
            <div class="mobile-sidebar-container">
                <SidebarContent :profile="profile" :mobile="true" />
                <div class="button-container">
                    <b-button variant="outline-light" block @click="settings">Settings</b-button>
                    <b-button variant="outline-warning" block @click="logout">Logout</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import SidebarContent from "./SidebarContent";
    import EventBus from "../../event-bus";
    export default {
        name: "MobileSidebar",
        components: {SidebarContent},
        props: ['profile'],
        methods: {
            show() {
                this.$refs.modal.show();
            },
            sidebarItemClicked() {
                this.$refs.modal.hide();
            },
            settings() {
                this.$router.push({name: 'settings'});
                this.sidebarItemClicked();
            },
            logout() {
                this.$store.dispatch('authentication/logout').then(() => {
                    this.$router.push({name: 'login'});
                });
                this.sidebarItemClicked();
            }
        },
        created() {
            EventBus.$on('sidebar-item-clicked', this.sidebarItemClicked);
        },
        destroyed() {
            EventBus.$off('sidebar-item-clicked', this.sidebarItemClicked);
        }
    }
</script>

<style>
    div.modal-dialog.modal-full-screen {
        width: 100% !important;
        max-width: none;
        margin: 0;
    }
</style>

<style lang="scss">
    .mobile-sidebar-container {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .button-container {
        padding: 20px;
    }
    
    
    .mobile-sidebar__modal {
        background-color: #2c3e50;
    }
    
    .mobile-sidebar__header {
        background-color: #2c3e50;
        border-bottom: 1px solid rgba(218, 219, 211, 0.7);
        .close {
            color:rgba(218, 219, 211, 0.7); 
        }
    }
</style>