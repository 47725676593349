import Vue from 'vue';


export default {
    tags() {
        return Vue.http.get('personal-profile/tags').then((val) => val.body.tags);
    },
    updateTags(tags) {
        return Vue.http.put('personal-profile/tags', {
            tags: tags
        });
    }
}
