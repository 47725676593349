<template>
		<b-row v-if="!isLoading">
			<b-col sm="12" class="data-table__parent">
				<b-table details-td-class="test" class="data-table" thead-class="table-header" tbody-tr-class="table-body__tr" tbody-class="table-body"
					ref="emailChangeHistoryTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :busy.sync="isBusy" striped
					:items="logs" :fields="fields" :per-page="perPage" :current-page="currentPage"  :show-empty="!isBusy">
					<template #empty>
     					 <b-alert show style="text-align:center">No email change history recorded</b-alert>
    				</template>
				</b-table>
			</b-col>
			<div class="email-change__history-pagination w-100">
				<b-pagination pills v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true" prevText="Previous"
					nextText="Next" size="sm" v-if="!isLoading" :total-rows="totalRows" :per-page="perPage"
					v-model="currentPage" />
			</div>
		</b-row>
</template>

<script>
export default {
  name: 'EmailHistory',
  data() {
    return {
			logs: [],
			isLoading: false,
			isBusy: false,
			perPage: 10,
			currentPage: 1,
			totalRows: 0,
			years: [],
			tags: [],
			categories: [],
			sortBy: 'date',
			sortDesc: true,
			evidence: null,
			fields: [
				{
					key: 'dateChanged',
					label: 'Date changed',
					sortable: true,
					formatter: (val) => this.moment(val).format('DD/MM/YYYY')
				},
				{
					key: 'previousEmail',
					label: 'Previous Email',
					sortable: true
				},
				{
					key: 'newEmail',
         			label: 'New Email',
					sortable: true
				},
			],
		}
	},
	mounted() {
		this.fetchEmailHistoryLogs()
	},
  methods: {
		openRenameEvidenceModal(e) {
			this.evidence = e
			this.$refs.EvidenceRenameModal.handleOpenModal()
		},
		creationDateFormatter: function (val) {
			if (val != null && val.length === 10) {
				return this.moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
			}
			return val;
		},
		fetchEmailHistoryLogs(ctx, callback) {
			this.isBusy = true;
			let url = `profile/email-change-history`;
			this.$http.get(url).then(response => {
				this.logs = response.data;
				this.totalRows = response.data.length;
				this.isBusy = false;
			}).catch((resp) => {
				this.$httpError('Failed to load email change history', resp);
			}).then(() => {
				this.isBusy = false;
			});

			// Must return null or undefined to signal b-table that callback is being used
			return null
		},
		doSearch: function (e) {
			e.preventDefault();
			this.refresh();
		},
		refresh: function () {
			this.$refs.emailChangeHistoryTable.refresh();
		},
	},
}
</script>
