<template>
    <div class="authentication text-center">
        <h4 class="pb-4">Mobile Login</h4>

        <div class="qr-container mb-4">
            <qr-code-vue background="#ffffff" foreground="#666" v-if="token" :value="token" :size="200" level="Q"></qr-code-vue>
            <Pulse loading v-if="loading" class="loader" color="#999999" />
        </div>

        <p class="mb-5 pb-5">Scan the code</p>
        <a class="d-block m-3" target="_blank" href="https://play.google.com/store/apps/details?id=eu.cpdcloud.cpdme&amp;hl=en"><img alt="google play button" width="200" src="../../assets/auth/btn-google-play.png"></a>
        <a class="d-block m-3" target="_blank" href="https://itunes.apple.com/gb/app/cpd-portfolio/id668426981?mt=8"><img alt="app store button" width="200" src="../../assets/auth/btn-app-store.png"></a>

    </div>
</template>

<script>
    import QrCodeVue from 'qrcode.vue';
    import Pulse from "../../components/loaders/Pulse";

    export default {
        name: "MobileLoginV2",
        components: {Pulse, QrCodeVue},
        data() {
            return {
                token: '',
                loading: false,
                message: '',
            }
        },
        methods: {
            loadNewToken: function() {
                this.loading = true;
                this.$http.post('auth/otp').then((resp) => {
                    this.token = resp.body.token;
                }).catch((resp) => {
                    this.loading = false;
                    this.$httpError('Failed to get session', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            stopTimer() {
                if (this.timer) {
                    window.clearInterval(this.timer);
                }
            },
            clearMessage: function() {
                this.message = null;
            },
            loginViaApp: function() {
                this.clearMessage();
                this.$http.post('auth/otp/login', { token: this.token}).then((response) => {
                    this.login(response.body.token, response.body.roles);
                }).catch((resp) => {
                    if (resp.status !== 403) {
                        this.stopTimer();
                        this.message = {
                            text: resp.body.message,
                            type: 'danger'
                        };
                    }
                }).finally(() => {
                })
            },
            login(token, roles) {
                let admin = roles.indexOf('Admin') > -1;
                this.$store.dispatch('authentication/storeLoginDetails', {
                    token: token,
                    isAdmin: admin,
                    roles: roles
                }).then(() => {
                    if (this.$route.params.nextUrl != null) {
                        this.$router.push(this.$route.params.nextUrl);
                    } else {
                        this.$router.push({name: 'dashboard-home'})
                    }
                });
            },
        },
        created() {
            this.loadNewToken();
            this.timer = window.setInterval(() => {
                this.loginViaApp();
            }, 2000);
        },
        beforeDestroy() {
            this.stopTimer();
        }
    }
</script>

<style scoped>
    .qr-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
</style>