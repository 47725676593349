import Vue from 'vue';

export default {
    onboardingSession(token) {
        return Vue.http.get(`auth/onboarding/${token}`);
    },
    completeOnboarding(token, password, governingBody) {
        return Vue.http.post(`auth/onboarding/${token}`, {password: password, governingBody: governingBody});
    },
    forgotPasswordComplete(token, password) {
        return Vue.http.post(`auth/password-reset/${token}`, {password: password});
    }
}
