<template>
    <b-overlay :show="loading" class="w-100">
        <div v-if="membership" class="membership-card">
            <b-row no-gutters class="membership__card-row justify-content-start">

                <b-col md="8" class="membership__card-text mt-2">
                    <span v-if="!membership.active">
                        CPDme Basic Free Member since {{memberSince}}
                        <br/>
                        <b-button size="sm" class="mt-2" target="_blank" variant="info" @click="upgradeMembership"><StarIcon /> Upgrade Now</b-button>
                    </span>
                    <div v-else>
                        <b><StarIcon /> CPDme Premium Membership {{renewsOrExpires}} {{onEndDate}}<br /></b>
                        <b>Member Since: {{memberSince}}</b><br />
                        Payment Method: {{membership.membershipSource}}<br />
                        <a href="#" v-if="membership.cancellable" @click.prevent="cancelMembership" v-requires-role="'User-Basic'">Cancel Renewal</a>
                    </div>
                </b-col>
                <b-col v-if="membershipType" md="4" style="padding: 4px;" class="d-flex align-items-center justify-content-center">
                    <img class="membership-image" :src="membershipBadgeImage" :alt="membershipType + ' Membership'" style="width: 100%;"/>
                </b-col>
            </b-row>
        </div>
        <UpgradeModal ref="upgradeModal" />
        <CancelMembershipModal ref="cancelModal" />
    </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import StarIcon from 'vue-material-design-icons/Star';
import CancelMembershipModal from "@/components/profile/CancelMembershipModal";
import UpgradeModal from '../UpgradeModal.vue';

export default {
    name: "MembershipDetails",
    components: {CancelMembershipModal, StarIcon, UpgradeModal},
    props: {
        isCorporateUser: {
			type: Boolean,
			required: true,
		},
    },
    data() {
        return {
            loading: false,
            brandingLoading: false,
            membership: null,
        }
    },
    computed: {
        ...mapGetters({
			isFullMember: 'authentication/isFullMember',
		}),
        membershipBadgeImage() {
            if (this.membershipType) {
                return `/img/membership-badge/${this.membershipType}.png`;
            }
            return ''
        },
        memberSince() {
            if (this.membership) {
                return this.moment(this.membership.memberSince).format('DD/MM/YYYY');
            }
            return '';
        },
        onEndDate() {
            if (this.membership.endDate === null) {
                return 'never'
            }
            return 'on ' + this.moment(this.membership.endDate).format('DD/MM/YYYY');
        },
        renewsOrExpires() {
            if (this.membership) {
                if (this.membership.autoRenew) {
                    return 'renews'
                } else {
                    return 'expires'
                }
            }
            return '';
        },
        membershipType() {
            if(this.isCorporateUser && this.isFullMember) {
                return "Corporate"
            } else if (this.isFullMember) {
                return "Premium"
            } else {
                return "Basic"
            }
        }
    },
    methods: {
        loadMembership() {
            this.loading = true;
            this.$http.get('profile/membership').then((resp) => {
                this.membership = resp.body;
            }).catch((resp) => {
                this.$httpError('Failed to load membership information', resp);
            }).finally(() => {
                this.loading = false;
            })
        },
        upgradeMembership() {
            this.$refs.upgradeModal.show();
        },
        cancelMembership() {
            this.$refs.cancelModal.show(this.membership);
        }
    },
    created() {
        this.loadMembership();
    }
}
</script>

<style scoped>
    .membership-card, .membership-card span, .membership-card div {
        font-size: 0.95em;
    }
    
    .membership__card-text {
        text-align: right;
    }


    .membership-image {
        margin-left: 16px;
        max-width: 100px;
    }
    
    
	@media (max-width: 1200px) {
        .membership__card-row {
            justify-content: flex-start;   
        }

        .membership__card-text {
            text-align: left;
        }
	}


    @media (max-width: 768px) {
        .membership-image {
            max-width: 100px;
            margin-top: 16px;
        }
    }
</style>
