<template>
    <div class="auth-promo">
        <WelcomeBox />
        <SocialMedia />
        <Navigation />
    </div>
</template>

<script>
    import SocialMedia from "./SocialMedia";
    import WelcomeBox from "./WelcomeBox";
    import Navigation from "./Navigation";

    export default {
        name: "LeftColumn",
        components: {WelcomeBox, SocialMedia, Navigation}
    }
</script>

<style scoped>
    @media (max-width: 768px) {
        .auth-promo {
            min-height: 50vh;
        }
    }

</style>