<template>
    <b-button :variant="_variant" @click="click" :size="size" :disabled="disabled"><slot></slot> {{_title}}</b-button>
</template>

<script>
    export default {
        name: "AnimatedButton",
        props: {
            alt: {
                type: Boolean,
                required: false,
                default: false
            },
            variant: {
                type: String
            },
            altVariant: {
                type: String
            },
            title: {
                type: String
            },
            altTitle: {
                type: String
            },
            size: {
                type: String,
                required: false,
                default: 'md'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data() {
            return {
            }
        },
        computed: {
            _variant() {
                if (this.alt) {
                    return this.altVariant;
                }
                return this.variant;
            },
            _title() {
                if (this.alt) {
                    return this.altTitle;
                }
                return this.title;
            }
        },
        methods: {
            animate() {
                this.alt = true;
                setTimeout(() => {
                    this.alt = false;
                }, 2000);
            },
            click($e) {
                this.$emit('click', {animate: this.animate, update: this.setAlt , event: $e});
            },
            setAlt() {
                this.alt = true;
            },
            reset() {
                this.alt = false;
            }
        }
    }
</script>

<style scoped>

</style>