<template>
    <div>
        <b-alert show variant="primary">
            Please wait...
        </b-alert>
    </div>
</template>

<script>
    import Managers from '../../managers';

    export default {
        name: "LoginUsingOverride",
        created() {
            let promise = Managers.authentication.configureForOverrideSession();
            if (!promise) {
                this.$router.push({name: 'dashboard-home'});
                this.$error('Failed to start session', 'Please close the window and try again, or contact support if the issue persists.')
            } else {
                promise.then(() => {
                    this.$router.push({name: 'dashboard-home'});
                })
            }
        }
    }
</script>

<style scoped>

</style>