<template>
    <div class="authentication">
        <h4 class="pb-4">Forgot Password</h4>
        <p>Forgotten your password? Enter your email address below and we'll send you a link to set a new one.</p>

        <b-alert :show="true" variant="warning" v-if="alert">{{alert}}</b-alert>

        <form @submit="forgotPassword">
            <b-form-group class="pb-2">
                <b-input-group class="mb-3">
                    <b-input-group-prepend>
                        <b-input-group-text class="p-0 no-background"><img alt="icon user" width="20" src="../../assets/auth/ic-user.svg"></b-input-group-text>
                    </b-input-group-prepend>
                    <b-input type="email" placeholder="EMAIL" v-model="emailAddress" aria-label="Email Address" />
                </b-input-group>
            </b-form-group>


            <b-row>
                <b-col md="12" class="p-0">
                    <b-button variant="primary" type="submit" class="button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Reset Password</b-button>
                </b-col>
                <b-col md="12">
                    <router-link :to="{name: 'login-v2'}"><b-button variant="default" block>Back to login</b-button></router-link>
                </b-col>
            </b-row>

        </form>
    </div>
</template>

<script>
    export default {
        name: "ForgotPasswordV2",
        data() {
            return {
                emailAddress: '',
                alert: '',
            }
        },
        methods: {
            forgotPassword($e) {
                $e.preventDefault();
                this.$http.post('auth/reset', {
                    email: this.emailAddress
                }).then((resp) => {
                    this.$router.push({name: 'login-v2', params: {
                            'message': resp.body.message
                        }});
                }).catch((resp) => {
                    this.alert = resp.body.message;
                });
            }
        },
        created() {
            if (this.$route.params && this.$route.params.email) {
                this.emailAddress = this.$route.params.email;
            }
        }
    }
</script>

<style scoped>
    @import '../../assets/auth/auth.css';
</style>
