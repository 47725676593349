<template>
    <Pulse v-if="loadingBranding"/>
    <b-overlay v-else :show="loadingDeleteRequest">
        <b-row>
            <b-col md="6" class="mt-4">
                <b-card header="Email Management">
                    <p v-if="!usesOauth">If you wish to change the email address associated with your CPDme account, click the button below to make this change.</p>
                    <p v-else>Please contact your organisation for email change support.</p>
                    <b-button class="ovr-button" :disabled="usesOauth" @click="changeEmail">Change Email</b-button> 
                    <b-button class="ovr-button mt-2" :to="{name: 'email-history'}" style="width: fit-content">View Email Change History</b-button>
                </b-card>
            </b-col>
            <b-col md="6" class="mt-4">
                <b-card header="Password Management">
                    <p>If you wish to change the password associated with your CPDme account, click the button below to make this change.</p>
                    <b-button class="ovr-button" @click="changePassword">Change Password</b-button> 
                </b-card>
            </b-col>
            <b-col md="6" class="mt-4">
                <b-card header="Delete Account">
                    <b-alert variant="success" show v-if="isSuccessfulDeleteRequest">Your request has been successfully sent to our support team. <br>Please check your emails for further details and contact us directly if you do not receive an email from us within 24 hours.</b-alert>
                    <p>If you would like to close and permanently delete your account, click the button below and our support team will be in touch to process your request.</p>
                    <b-button :disabled="loadingDeleteRequest || isSuccessfulDeleteRequest" variant="outline-danger" @click="requestAccountDeletion">Request Account Deletion</b-button> 
                </b-card>
            </b-col>
            <ChangeUserEmailModal ref="changeEmailModal" />
            <ChangePasswordModal ref="changePasswordModal" />
        </b-row>
</b-overlay>

</template>

<script>

import ChangeUserEmailModal from "../../components/tools/ChangeUserEmailModal";
import ChangePasswordModal from "../../components/tools/ChangePasswordModal";
import Pulse from "@/components/loaders/Pulse";

export default {
    name: "AccountManagement",
    components: {ChangeUserEmailModal, ChangePasswordModal, Pulse},
    data() {
        return {
            loadingBranding: true,
            usesOauth: false,
            loadingDeleteRequest: false,
            isSuccessfulDeleteRequest: false,
        }
    },
    methods: {
        changeEmail() {
			    this.$refs.changeEmailModal.show();
            },
        changePassword() {
                this.$refs.changePasswordModal.show();
            },
        loadBranding() {
            this.$http.get("branding").then((resp) => {
                this.usesOauth = resp.body.usesOauth;
            })
            this.loadingBranding = false;
        },
        requestAccountDeletion() {
            this.loadingDeleteRequest = true,
            this.$http.post("user-account/request-account-deletion").then(() => {
                    this.isSuccessfulDeleteRequest = true;
                }).catch(error =>  {
                    this.$httpError('Failed to request account deletion.', error);
                }).finally(() => {
                    this.loadingDeleteRequest = false;
                });
        }

    },
    created() {
        this.loadBranding();
    }
}

</script>

<style>

</style>