<template>
    <div class="top-banner-notice">
        <b-alert v-if="useOverride" :show="true" variant="primary">
            <InformationOutlineIcon /> <b>You are viewing {{name}}'s dashboard. </b> Close this window or refresh the page to return to your account.
        </b-alert>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline';

    export default {
        name: "TopBannerNotice",
        components: {InformationOutlineIcon},
        computed: {
            ...mapGetters({
                useOverride: 'session/useOverride'
            }),
        },
        data() {
            return {
                name: ''
            }
        },
        methods: {
            loadUserInfo() {
                this.$http.get('profile').then((resp) => {
                    this.name = `${resp.body.firstName} ${resp.body.lastName}`;
                });
            }
        },
        created() {
            this.loadUserInfo();
        }
    }
</script>

<style scoped>

</style>