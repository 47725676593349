<template>
	<div class="period-selector">
		<button variant="outline-primary" :class="{'dashboard-home__period-selected': periodValue === period}" class="dashboard-home__period" v-for="(period, index) in periodValues" :key="index" @click="changePeriod(period)"><calendar-icon /><span class="period-selector__text" v-html="renderText(period)" /></button>
		<DateRangeModal title="Select a date range to view statistics between" ref="dateRangeModal" @dateRangeConfirmed="confirmChangePeriod"/>
	</div>
</template>

<script>

import DateHelper from '@/utilities/date'
import DateRangeModal from './common/DateRangeModal.vue'

export default {
    name: "PeriodSelector",
	components: [DateRangeModal],
    props: ['value'],
    methods: {
        changePeriod: function (value) {
            if (value === 'Custom') {
                this.$refs.dateRangeModal.show();
                return;
            }
            let msg;
            if (value === 'Lifetime') {
                msg = `Currently showing data for all time`;
            }
            else {
                msg = `Currently showing data for the last ${value}`;
            }
            this.$emit('formattedPeriod', msg);
            this.$data.periodValue = value;
            this.$emit('input', this.periodKey(value));
        },
        confirmChangePeriod(dateRange) {
            this.$data.periodValue = 'Custom';
            this.$emit('input', `?from=${dateRange.from}&to=${dateRange.to}`);
            this.$emit('formattedPeriod', `Currently showing data from ${DateHelper.formatDateToDDMMYYYY(dateRange.from)} to ${DateHelper.formatDateToDDMMYYYY(dateRange.to)}`);
        },
        periodKey: function (periodName) {
            if (periodName === 'This Month')
                return 'month';
            if (periodName === 'This Year')
                return 'year';
            if (periodName === '12 months')
                return '12months';
            if (periodName === '24 months')
                return '24months';
            if (periodName === '36 months')
                return '36months';
            return 'lifetime';
        },
        renderText(period) {
            if (period === '12 months')
                return '<span class="dashboard-home__period-capital">12</span> months';
            if (period === '24 months')
                return '<span class="dashboard-home__period-capital">24</span> months';
            if (period === '36 months')
                return '<span class="dashboard-home__period-capital">36</span> months';
            if (period === 'Custom')
                return 'Custom';
            if (period === 'Lifetime')
                return 'Lifetime';
        }
    },
    data: function () {
        return {
            periodValue: '12 months',
            periodValues: ['12 months', '24 months', '36 months', 'Lifetime', 'Custom'],
        };
    },
    computed: {
        period: {
            get() {
                return this.periodValue;
            },
            set(newValue) {
                this.periodValue = newValue;
            }
        }
    },
    components: { DateRangeModal }
}
</script>

<style lang="scss" scoped>
.period-selector {
	width: 100%;
	display: flex;
	justify-content: space-between;

	margin-bottom: 12px;
	flex-wrap: wrap;
	// gap: 8px;
}

.dashboard-home__period-capital {
	font-size: 14px;
}

.dashboard-home__period {
	width: 19%;
	font-size: 12px;
	border-radius: 4px;
	color: rgba(44, 62, 80, .4);
	border: 1px solid rgba(44, 62, 80, .2);
	// background-color: transparent;
	outline: none;
	padding: 8px 12px;
	transition: 200ms ease-out;
	font-weight: 600;

	display: flex;
	align-items: center;

	.material-design-icon {
		width: 16px !important;
		height: 16px !important;

		margin-right: 8px;
		svg {
			width: 16px !important;
			height: 16px !important;
		}
	}

	&:not(.dashboard-home__period-selected):hover {
		color: rgba(44, 62, 80, .5);
		border: 1px solid rgba(44, 62, 80, .5);
		transition: 200ms ease-out;
	}
}

.dashboard-home__period-selected {
	color: white;
	border-color: $blue-300;
	background: $blue-300;
	// color: #3498DB;
	// border-color: #3498DB;
}

@media (max-width: 768px) {
	.period-selector {
		justify-content: flex-start;
	}

	.dashboard-home__period {
		width: 90px;

		margin-top: 8px;
		margin-right: 8px;
	}
}
</style>