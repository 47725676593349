<template>
    <nav class="col-md-2 d-none d-md-block sidebar"
        :style="brandedNavStyle" 
        ref="left-sidebar">
        <div class="sidebar-sticky">
            <SidebarContent :profile="profile" :branding="branding"/>
        </div>
    </nav>
</template>

<script>
import SidebarContent from "./sidebar/SidebarContent";

export default {
    name: "Sidebar",
    components: { SidebarContent },
    props: ['profile'],
    data() {
        return {
            branding: null
        }
    },
    mounted() {
		this.loadBranding();
	},
    methods: {
        loadBranding() {
			this.$http.get('branding').then((resp) => {
				this.branding = resp.body;
			}).catch(() => { });
		},
    },
    computed: {
        brandedNavStyle() {
            //If any more organisations wish to have custom navbar colours, don't extend this if statement. Instead, add a column to the DB with a true/false if it's branded. Use this and the branding.coloUrHex to render background colour
            if (this.branding && this.branding.organisationId === 170) {
                return {
                    backgroundColor: `#${this.branding.colourHex}` 
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
nav.sidebar {
    background-color: $sidebar;
    max-width: 240px;
    transition: all 200ms ease-out;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 10px);
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

// For ipads
@media screen and (max-width: 1000px) {
		nav.sidebar {
			background-color: $sidebar;
			max-width: 240px !important;
			transition: all 200ms ease-out;
	}
}
</style>