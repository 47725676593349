<template>
	<div>
		<div v-if="loading || membershipLoading">
			<Pulse loading class="loader" color="#999999"></Pulse>
		</div>
		<div class="libraries" v-else>
			<b-row class="mb-4 d-flex align-items-start">
				<b-col sm="12">
					<ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
				</b-col>
				<b-col sm="12" xl="8" class="d-flex align-items-center mb-1">
					<h4 class="dashboard-pages__title mb-0">CPDhub of previous broadcast content and support guides</h4>
				</b-col>

				<b-col sm="12" xl="4" v-if="videos.length > 0">
					<SearchBar placeholder="Filter videos..." @updateVal="(val) => filterQuery = val"
						:incomingValue="filterQuery" />
				</b-col>
			</b-row>

			<b-card>
				<b-row>
					<b-col sm="12">
						<div class="video-categories mt-2 mb-5">
							<div v-for="category in videos" :key="category.name" class="video-whole__button">
								<sup v-if="category.premium" class="video-premium">Premium</sup>
								<sup v-else-if="category.name === 'Private Symposium Recordings'" class="video-premium video-premium__basic">CPD Event</sup>
								<sup v-else class="video-premium video-premium__basic">Premium/Basic</sup>
								<b-button class="ovr-button category-button" :class="{ 'active-category': category === selectedCategory }" @click="selectCategory(category)">
									{{ category.name }}
								</b-button>
							</div>
						</div>
					</b-col>
					<b-col sm="12" v-if="!selectedCategory && videos.length > 0" class="mt-4">
							<b-alert show variant="warning">
								You need to select a category first
							</b-alert>
					</b-col>

					<b-col sm="12" v-if="videos.length === 0" class="mt-4">
							<b-alert show variant="info">
								No videos currently available.
							</b-alert>
					</b-col>

					<b-col class="mb-2 mt-2" sm="12">
						<b-alert show v-if="selectedCategory && selectedCategory.name === 'Private Symposium Recordings'" variant="info">This video library is not available to people who have not purchased a ticket for these private events</b-alert>
						<div v-if="selectedCategory" class="videos-container" style="gap: 4px;">
							<VideoCard v-for="video in filteredLibraries" @handleVideoClick="handleVideoClick" :key="video.videoId" :isPremium="selectedCategory.premium && !membership.active" :video="video" :library-name="selectedCategory.name" />
						</div>
					</b-col>


				</b-row>
			</b-card>
			<b-modal ref="membershipNeededModal" size="md" title="Uh oh, it seems you do not have an active membership" header-text-variant="light" header-bg-variant="primary" header-close-variant="dark">
					<p class="membership-needed__modal-text">Sorry you need to be a premium member to access this.</p>
					<template slot="modal-footer">
							<p class="close-button" :block="true" role="button" variant="outline-info" @click="close">Cancel</p>
							<b-button class="upgrade-button ml-3" variant="dark" @click="addMembership">Upgrade</b-button>
					</template>
			</b-modal>
		</div>

		<PageTour ref="pageTourRef" description="This section is where we keep all of the previous CPD Webinars along with some of the guides and help videos. <br> <br> Some content may be limited to specific levels of member (Basic / Premium) and some corporate customers have limited or additional videos. <br> <br> We also keep our private symposium videos here too for ticket holders of these private CPD events." @handleShowTour="handleShowTour"  />
		<v-tour name="videoTour" :steps="steps" :options="{ highlight: true }"></v-tour>
	</div>
</template>

<script>
import Pulse from "../../components/loaders/Pulse";
import SearchBar from "../../components/common/SearchBar";
import PageTour from "../../components/common/PageTour";
import VideoCard from "./VideoCard";
import ShowTourButton from "../../components/common/ShowTourButton";
export default {
	name: "VideoLibraries",
	components: { ShowTourButton, Pulse, SearchBar, VideoCard, PageTour },
	data: function () {
		return {
			videos: [],
			selectedCategory: null,

			filterQuery: '',
			membership: null,

			steps: [
				{
					target: '.search-tour',
				content: `This allows you to search by title or speaker of a previous webinar. You can also search for help guides such as 'how do I delete an entry?' <br> <br> The videos are organised into folders to easily allow you to find the most relevant content across different time periods or topics.`
				},
			],
			loading: true,
			membershipLoading: true,
		}
	},
	methods: {
		handleShowTour() {
			this.$tours['videoTour'].start()
		},
		loadMembership() {
			this.membershipLoading = true;
			this.$http.get('profile/membership').then((resp) => {
				this.membership = resp.body;
				this.membershipLoading = false;
			}).catch((resp) => {
				this.$httpError('Failed to load membership information', resp);
				this.membershipLoading = false;
			})
		},
		handleVideoClick() {
			if (!this.membership.active && this.selectedCategory.premium) {
				this.$refs.membershipNeededModal.show()
				return
			}
		},
		loadVideoLibraries () {
			this.loading = true;
			this.$http.get('videos/v2').then((resp) => {
				this.selectedCategory = resp.body[0]
				this.videos = resp.body.map(v => {
					if (v.thumbnailUrl == null) {
						v.thumbnailUrl = require('../../assets/video_thumbnail.jpg');
					}
					return v;
				});

				this.loading = false
			}).catch((resp) => {
				this.loading = false
			})
		},
		goToLibrary: function (library) {
			this.$router.push({ name: 'videos/library', params: { library: library } });
		},
		addMembership() {
			window.open('https://pay.gocardless.com/AL0009FCR4M3J0', '_blank');
		},
		close() {
			this.$refs.membershipNeededModal.hide();
		},
		selectCategory(category) {			
			this.selectedCategory = category;
		}
	},
	computed: {
		filteredLibraries: function () {
			return this.selectedCategory.videos.filter(vl => this.$utilities.stringContains(this.filterQuery.toLowerCase(), vl.name.toLowerCase()));
		}
	},
	mounted() {
		this.loadMembership();
		this.loadVideoLibraries();
	}
}
</script>


<style scoped lang="scss">
.upgrade-button {
	background: #385372 !important;
	font-weight: 600;
	border-radius: 8px;
	padding: 0.375rem 22px;
}

.close-button {
	font-weight: 600;
}

.card h4 {
	font-size: 20px;
}

.video-categories {
	display: flex;
	flex-wrap: wrap;
	gap: 18px;
}

.videos-container {
	display: grid;
	// grid-template-columns: repeat(auto-fill,minmax(240px, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	* {
		min-width: 0;
		box-sizing: border-box;
		max-width: unset !important;
		height: 100%;
	}
}

.emulated-flex-gap > * {
  margin: 12px 0 0 12px;
}

.emulated-flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -12px 0 0 -12px;
  width: calc(100% + 12px);
}

.active-category {
	background-color: $blue-300 !important;
	color: white !important;
}

.category-button {
	outline: none;
	border: none;
	padding: 4px 8px;

	border: 1px solid $border-color;
}

.video-whole__button {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	margin-bottom: 8px;
}

.video-premium {
	color: $lime;
	font-weight: 600;
}

.video-premium__basic {
	color: $yellow;
}

.membership-needed__modal-text {
	text-align: center;
}

.click-here__text {
	text-decoration: underline;
	cursor: pointer;
}

</style>