<template>
    <div class="login-sso">
        <Logo />
        <div class="sso-error" v-if="this.error">
            <b-alert variant="danger" show>{{this.error}}</b-alert>
            <router-link :to="{'name':'login'}">
                <b-button variant="default" block>Back to login</b-button>
            </router-link>
        </div>
        <b-alert variant="info" v-else show>Logging in...</b-alert>
    </div>
</template>

<script>
    import Logo from "../../components/authentication/Logo";
    export default {
        name: "LoginSSO",
        components: {Logo},
        data: function() {
            return {
                error: null,
            }
        },
        created: function() {
            let code = this.$route.query.code;
            if (code === undefined) {
                this.error = 'Login information not provided';
            } else {
                let redirectTo = encodeURI(process.env.VUE_APP_SSO_REDIRECT);
                this.$http.get(`auth/login/sso/${code}?redirectTo=${redirectTo}`).then(response => {
                    if (response.status === 200) {
                        let token = response.body.token;
                        let admin = response.body.roles.indexOf('Admin') > -1;
                        this.$store.dispatch('authentication/storeLoginDetails', {
                            token: token,
                            isAdmin: admin,
                            roles: response.body.roles
                        }).then(() => {
                            if (this.$route.params.nextUrl != null) {
                                this.$router.push(this.$route.params.nextUrl);
                            } else {
                                this.$router.push({name: 'dashboard-home'})
                            }
                        });
                    } else {
                        this.error = response.body.message;
                    }
                }, response => {
                   this.error = response.body.message;
                });


            }
        }
    }
</script>

<style scoped>
    .login-sso {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>