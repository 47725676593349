<template>
    <div class="basic-details basic-details__tour">
            <b-row class="w-100 m-0" v-if="!isLoading">
                <b-col sm="12">
                    <b-alert variant="warning" show v-if="!profile.mobileNumber && !profile.alternativeEmail && !editing">
                        <lock-outline-icon/> To better protect your account, please provide a mobile number and/or an alternative email address.
                        <a href="#" @click.prevent="edit">Click here</a> to add these.
                    </b-alert>
                    <b-alert variant="warning" show v-if="profile.mobileNumber && !profile.mobileVerified && !editing">
                        Please <a href="#" @click.prevent="showVerifyMobileModal">click here</a> to verify your mobile number
                    </b-alert>
                    <b-alert variant="warning" show v-if="profile.alternativeEmail && !profile.alternativeEmailVerified && !editing">
                        Please <a href="#" @click.prevent="showVerifyAlternativeEmailModal">click here</a> to verify your alternative email address
                    </b-alert>
                </b-col>
                <VerifyContactDetailsModal ref="verifyMobileModal" verification-type="mobile" @verified="loadProfile"/>
                <VerifyContactDetailsModal ref="verifyAlternativeEmailModal" verification-type="alternativeEmail" @verified="loadProfile"/>
                <b-col sm="12" :xl="!editing ? '2' : '4'" class="profile-image-container" >
                    <ProfileImage :width="80" :profile-image-url="profileImageUrl" />
                    <small v-requires-role="'User-Basic'"><a href="#" @click="changeImage">Upload</a></small>
                    <input type="file" accept="image/jpeg, image/png" v-on:change="imageChanged" ref="profileImageInput" style="display: none">
                </b-col>
                <b-col sm="12" md="6" xl="5" v-if="!editing" class="mt-2 profile-details__left">
                    <b>Name: </b>{{profile.firstName}} {{profile.lastName}}<br />
                    <b>Job Title: </b> {{profileAttribute('jobTitle')}}<br />
                    <b>Nickname: </b> {{profileAttribute('nickname')}}<br />
                    <b>Mobile: </b> {{profileAttribute('mobileNumber')}} 
                    <b-badge v-if="profile.mobileNumber && !profile.mobileVerified" variant="primary">Unverified</b-badge>
                    <b-badge v-if="profile.mobileNumber && profile.mobileVerified" variant="success">Verified</b-badge><br/>
                    <b>Email Address: </b> {{profile.emailAddress}}
                    <b-badge variant="success">Verified</b-badge><br/>
                    <b>Alternative Email Address: </b> {{profileAttribute('alternativeEmail')}}
                    <b-badge v-if="profile.alternativeEmail && !profile.alternativeEmailVerified" variant="primary">Unverified</b-badge>
                    <b-badge v-if="profile.alternativeEmail && profile.alternativeEmailVerified" variant="success">Verified</b-badge><br/>
                    <small v-requires-role="'User-Basic'"><a href="#" @click.prevent="edit" >Edit</a></small>
                </b-col>
                <b-col sm="12" md="12" :xl="!editing ? '5' : '7'" class="mb-2 mt-1 d-flex align-items-center justify-content-betweem">
                    <MembershipDetails :is-corporate-user="branding ? true : false"/>
                </b-col>
                <b-col sm="12" md="8" xl="12" v-if="editing">
                    <b-form @submit.prevent="saveProfile">
                        <b-form-group label-cols-sm="4" label-size="sm" label="First Name">
                            <b-input size="sm" type="text" v-model.trim="profile.firstName" />
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Last Name">
                            <b-input size="sm" type="text" v-model.trim="profile.lastName" />
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Job Title">
                            <b-input size="sm" type="text" v-model.trim="profile.jobTitle" />
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Nickname">
                            <b-input size="sm" type="text" v-model.trim="profile.nickname" />
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Mobile Number">
                            <VueTelInput @validate="onValidateMobile" v-model.trim="profile.mobileNumber" mode="international" />
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Email Address">
                            <b-input disabled size="sm" type="email" v-model="profile.emailAddress" />
                            <div v-if="!isBrandingLoading">
                                <small v-if="!branding || !branding.usesOauth"><a href="#" @click="changeEmail">Click here</a> to change your primary email address</small>
                                <small v-else>Please contact your organisation to change your primary email address</small>
                            </div>
                        </b-form-group>
                        <b-form-group label-cols-sm="4" label-size="sm" label="Alternative Email Address">
                            <b-input size="sm" type="email" v-model.trim="profile.alternativeEmail" />
                            <small>In the event of losing access to your primary email address, providing an alternative email address will allow us to help you regain access to your account</small>
                        </b-form-group>

                        <b-button type="submit" size="sm" variant="outline-dark">Save</b-button>
                    </b-form>
                </b-col>
            </b-row>
            <Pulse loading v-else class="loader" color="#999999"></Pulse>
            <ChangeUserEmailModal ref="changeEmailModal" />
    </div>
</template>

<script>
    import Pulse from "../loaders/Pulse";
    import ProfileImage from "./ProfileImage";
    import Managers from '../../managers';
    import MembershipDetails from "@/components/profile/MembershipDetails";
    import { VueTelInput } from 'vue-tel-input'
    import ChangeUserEmailModal from "../../components/tools/ChangeUserEmailModal";
    import { EventBus } from "../../event-bus"
    import VerifyContactDetailsModal from "./VerifyContactDetailsModal.vue";

    export default {
        name: "ProfileBasicDetails",
        components: { MembershipDetails, ProfileImage, Pulse, VueTelInput, ChangeUserEmailModal, VerifyContactDetailsModal },
        data: function() {
            return {
                isLoading: false,
                isBrandingLoading: false,
                profile: null,
                baseUrl: '',
                editing: false,
                branding: null,
                isMobileValid: true,
            }
        },
        computed: {
            profileImageUrl() {
                let authToken = Managers.authentication.loginToken();
                return `${this.baseUrl}profile/image?auth_token=${authToken}`
            }
        },
        methods: {
            edit() {
                this.editing = true;
            },
            onValidateMobile(phoneObj) {
                this.isMobileValid = phoneObj.isValid;
            },
            imageChanged(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                let file = files[0];
                if (file.size > 10 * 1024 * 1024) {
                    alert('File size cannot exceed 10MB');
                    return;
                }
                let type = file.type;
                this.isLoading = true;
                this.$http.post(`profile/image`, file, {
                    headers: {
                        'Content-Type': type
                    }
                }).then(() => {
                    this.isLoading = false;
                    EventBus.$emit('profile-image__changed');
                }).catch((resp) => {
                    this.$httpError('Failed to upload picture', resp);
                });
            },
            saveProfile() {
                if (this.validateProfile()) {
                    this.$hideToast();
                    this.isLoading = true;
                    this.$http.put('profile', this.profile).then(() => {
                        this.isLoading = false;
                        this.editing = false;
                        this.loadProfile();
                    }).catch((resp) => {
                        this.$httpError('Failed to save profile', resp);
                        this.isLoading = false;
                    })
                }
            },
            loadProfile() {
                this.isLoading = true;
                this.$http.get('profile').then((resp) => {
                    this.profile = resp.data;
                    this.isLoading = false;
                }).catch((resp) => {
                    this.$httpError('Failed to load profile', resp);
                    this.isLoading = false;
                });
            },
            loadBranding() {
                this.isBrandingLoading = true;
                this.$http.get("branding").then((resp) => {
                    this.branding = resp.body;
                }).catch(() => {
                    this.branding = null;
                }).finally(() => {
                    this.isBrandingLoading = false;
                })
            },
            profileAttribute(name) {
                if (this.profile[name]) {
                    return this.profile[name];
                } else {
                    return '--';
                }
            },
            validateProfile() {
                if (this.profile.firstName.trim() === '') {
                    alert('First Name must not be empty');
                } else if (this.profile.lastName.trim() === '') {
                    alert('Last name must not be empty');
                } else if (this.profile.mobileNumber !== '' && !this.isMobileValid) {
                    alert('Please enter a valid mobile number');
                } else {
                    return true;
                }
                return false;
            },
            changeImage() {
                this.$refs.profileImageInput.click()
            }, 
            changeEmail() {
                this.$refs.changeEmailModal.show();
            },
            showVerifyMobileModal() {
                this.$refs.verifyMobileModal.show();
            },
            showVerifyAlternativeEmailModal() {
                this.$refs.verifyAlternativeEmailModal.show();
            }
        },
        created() {
            this.baseUrl = process.env.VUE_APP_API_ROOT;
            this.loadProfile();
            this.loadBranding();
        }
    }
</script>

<style scoped>
    .profile-image-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .profile-image {
        width: 120px;
        height: 120px;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        border-radius: 60px;
        border-color: #2c3e50;
        border-width: 2px;
        border-style: solid;
    }

    .profile-details__left {
        font-size: 14px;
    }
</style>
