<template>
	<div class="change-email">
		<b-modal ref="changeEmailModal" size="md" title="Change Email" header-text-variant="light" header-bg-variant="primary"
			no-close-on-backdrop no-close-on-esc>

			<b-alert show v-if="errorMessage" variant="danger">
				{{ errorMessage }}
			</b-alert>

			<b-form-group label="New Email">
				<b-input type="email" v-model.trim="newEmail" @input="resetError('email')" />
			</b-form-group>

			<b-form-group label="Confirm New Email">
				<b-input type="email" v-model.trim="confirmEmail" @input="resetError('email')" />
			</b-form-group>

			<div slot="modal-footer">
				<b-button variant="light" size="md" class="mr-2" @click="close" :disabled="loading">Cancel</b-button>
				<b-button :to="{name: 'email-history'}" @click="close" variant="primary" size="md" class="mr-2">See History</b-button>
				<b-button variant="primary" size="md" @click="requestVerificationCode"
					:disabled="loading || !newEmail.length || !confirmEmail.length || verificationCodeRequested">Request Verification Link</b-button>
			</div>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: "ChangeUserEmailModal",
	data() {
		return {
			newEmail: '',
			errorMessage: '',
			confirmEmail: '',
			loading: false,

			verificationCode: '',
			verificationCodeRequested: false
		}
	},
	methods: {
		close() {
			this.reset();
			this.$refs.changeEmailModal.hide();
		},
		show() {
			this.$refs.changeEmailModal.show();
		},
		reset() {
			this.newEmail = '';
			this.confirmEmail = '';
			this.loading = false;
			this.verificationCodeRequested = false
			this.resetError();
		},
		resetError(email) {
			this.errorMessage = '';
		},

		requestVerificationCode() {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(this.newEmail)) {
				this.errorMessage = 'Please enter a valid email address'
				return;
			}

			if (!emailRegex.test(this.confirmEmail)) {
				this.errorMessage = 'Please enter a valid email address'
				return;
			}

			if (this.newEmail !== this.confirmEmail) {
				this.errorMessage = 'The provided emails do not match. Please try again.';
				return;
			}

			this.loading = true

			this.$http.post('profile/change-email', {emailAddress: this.newEmail}).then(() => {
				this.verificationCodeRequested = true;
				this.handleLogout()
			}).catch((resp) => {
				this.$error('Failed to request verification code', resp.body.message);
			}).finally(() => {
				this.loading = false;
			})
		},
		handleLogout() {
			this.$store.dispatch('authentication/logout').then(() => {
				this.$router.push({name: 'login', params: { successfullyInitiatedEmailChange: true }});
			});
		},
	}
}
</script>

<style scoped></style>