<template>
    <div class="page">
        <label class="dashboard-pages__title" v-if="this.allCheckboxes()">{{page.title}}</label>
        <component :is="displayInCard ? 'b-card' : 'div'" body-class="form__card">
            <div id="form-item" v-for="item in activatedItems" :key="item.identifier">

                <FreeTextFormItem @handleTitleInput="$emit('handleTitleInput')" class="diary-title__tour reflection-title__tour" :item="item" v-if="itemActivated(item, 1)" />

                <LongTextFormItem :class="`${item.title === 'Introduction' ? 'reflection-intro__tour' : item.title === 'Appendix' ? 'appendix__tour' : item.title === 'References' ? 'references__tour' : ''}`" :item="item" v-else-if="itemActivated(item, 2)" />

                <DropdownFormItem :class="`${item.title === 'Governing Body' ? 'governing-body__tour' : item.title === 'Arranged In' ? 'arranged-in__tour' : item.title === 'The category this CPD activity relates to is' ? 'relates-to__tour' : item.title === 'Reflection Model' ? 'reflective-model__tour' : ''}`" :item="item" v-else-if="itemActivated(item, 4)" @valueChanged="valueChanged" />

                <div :class="`hours ${item.title === 'Number of Hours' ? 'nunber-of__hours-tour' : ''}`" v-else-if="itemActivated(item, 5) && item.title === 'Number of Hours'" >

                    <HoursFormItem :item="item" v-if="item.value <= 24"/>

                    <FreeTextFormItem :item="item" :type="'number'" v-else />

                </div>

                <!-- Need to hide the items that are shown on the right side of the b-card with the v-show directive -->
                <FreeTextFormItem :item="item" type="number" v-else-if="itemActivated(item, 5)" />

                <CheckboxFormItem :item="item" v-else-if="itemActivated(item, 7)" />

                <div v-else-if="itemActivated(item, 9)" :class="`${item.title === 'Start Date' ? 'start-date__tour' : item.title === 'End Date' ? 'end-date__tour' : ''}`">

                  <DateFormItem :item="item" />

                </div>

                <FreeTextFormItem :item="item" type="url" v-else-if="itemActivated(item, 10)" />

                <Upload :item="item" v-else-if="itemActivated(item, 8)" class="attach-evidence__tour"/>

                <p v-else>Not implemented: {{item.title}} (Type: {{item.type}})</p>

            </div>
            <div v-if="displayInCard" class="mt-2">
					<span class="disclaimer-text">By submitting this entry, you confirm that the information provided above is
						a true reflection of your personal development and does not contain any person, patient, client or
						service user identifiable data that could breach data protection laws or legislation relating to your
						employer, governing bodies, professional organisations, code of conduct and associated revalidation
						guidance. 
                    </span>
			</div>
        </component>
    </div>
</template>

<script>
    import FreeTextFormItem from './FreeTextFormItem'
    import DropdownFormItem from "./DropdownFormItem";
    import LongTextFormItem from "./LongTextFormItem";
    import DateFormItem from "./DateFormItem";
    import CheckboxFormItem from "./CheckboxFormItem";
    import Upload from "./Upload";
    import HoursFormItem from "./HoursFormItem";

    export default {
        name: "FormPage",
        props: ['page', 'formData', 'displayInCard'],
        computed: {
            activatedItems: function() {
                return this.$formTools.activatedItems(this.page);
            }
        },
        methods: {
          valueChanged(value)
          {
            this.$emit('valueChanged', value)
          },
            allCheckboxes: function() {
                let allCheckboxes = true;
                for (let i = 0; i < this.page.items.length; i++) {
                    if (this.page.items[i].type === 7) {
                        allCheckboxes = allCheckboxes && true;
                    } else {
                        allCheckboxes = false;
                    }
                }
                return allCheckboxes;
            },
            itemActivated: function(item, expectedType) {
                return item.type === expectedType;
            },
            isEmptyObject: function(obj) {
                for(const prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }
                return true;
            }
        },
        components: {
            HoursFormItem,
            Upload,
            CheckboxFormItem, DateFormItem, LongTextFormItem, DropdownFormItem, FreeTextFormItem}
    }
</script>

<style scoped>
label, legend {
    font-weight: bold !important;
}

.form__card {
	background-color: #f4f7f8;
}

.disclaimer-text {
	font-weight: 600;
	display: block;
	line-height: 1.2;
}

</style>