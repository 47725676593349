import Vue from 'vue';

export default {
    info(accessToken) {
        return Vue.http.get(`hcpc-portal/${accessToken}/info`);
    },
    summary(accessToken) {
        return Vue.http.get(`hcpc-portal/${accessToken}/summary`);
    },
    updateSummary(accessToken, summary) {
        return Vue.http.put(`hcpc-portal/${accessToken}/summary`, {summary: summary});
    },
    entries(accessToken) {
        return Vue.http.get(`hcpc-portal/${accessToken}/entries`);
    },
    statement(accessToken) {
        return Vue.http.get(`hcpc-portal/${accessToken}/statement`);
    },
    updateStatement(accessToken, statement) {
        return Vue.http.put(`hcpc-portal/${accessToken}/statement`, statement);
    },
    evidence(accessToken, selectedEntries) {
        return Vue.http.get(`hcpc-portal/${accessToken}/evidence`, {params: {entryId: selectedEntries}});
    },
    evidenceMetadata(accessToken, storageId) {
        return Vue.http.get(`hcpc-portal/${accessToken}/evidence/${storageId}`);
    }
}
