import { render, staticRenderFns } from "./FullScreenVideo.vue?vue&type=template&id=52d6f184"
import script from "./FullScreenVideo.vue?vue&type=script&lang=js"
export * from "./FullScreenVideo.vue?vue&type=script&lang=js"
import style0 from "./FullScreenVideo.vue?vue&type=style&index=0&id=52d6f184&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports