<template>
  <b-button v-if="!isMobile()" class="ovr-button show-tour__button" @click="$emit('handleClick')">
    Show tour
  </b-button>
</template>

<script>
export default {
  name: 'ShowTourButton',
  methods: {
    isMobile() {
			return (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) // If true, means its a mobile device
    },
  }
}
</script>

<style lang="scss" scoped>
.show-tour__button {
  padding: 0px 8px !important;
  margin-bottom: 20px;
}
</style>