<template>
	<div class="dropdown" :class="tourClass">
		<b-dropdown :toggle-class="toggleClass" :size="size" :text="currentDisplayValue" :variant="variant"
			:disabled="disabled" :class="{ fill: fill }">
			<template #button-content>
				<span class="mb-0">{{ currentDisplayValue }}</span>
			</template>

			<b-dropdown-header>{{ displayTitle }}</b-dropdown-header>
			<b-dropdown-item v-if="includeEmpty" @click="changeState(null)">{{ emptyTitle }}</b-dropdown-item>
			<b-dropdown-item v-for="option in options" v-bind:key="option.value" link-class="d-flex" :disabled="option.disabled"
				@click="changeState(option.value)"><span :style="`color: ${getColor(option)}`" class="mb-0" v-html="hasOptionColor(option)" />{{ option.title }}</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
/*
				Options should be passed in like:
				[
						{
								title: '',
								value: ''
						}
				]
 */

export default {
	name: "Dropdown",
	props: {
		tourClass: {
			type: String,
			required: false,
			default: ''
		},
		toggleClass: {
			type: String,
			required: false,
			default: ''
		},
		size: {
			type: String,
			required: false,
			default: 'md'
		},
		variant: {
			type: String,
			required: false,
			default: 'info'
		},
		options: {
			type: Array,
			required: true
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [String, Number],
			required: false,
			default: null
		},
		showTitle: {
			type: Boolean,
			required: false,
			default: false
		},
		emptyTitle: {
			type: String,
			required: false,
			default: 'None'
		},
		includeEmpty: {
			type: Boolean,
			required: false,
			default: false
		},
		fill: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		displayTitle() {
			return `Select ${this.title}`.trim();
		},
		currentDisplayValue() {
			if (typeof this.value === "string" && !this.value.trim().length) {
				return this.emptyTitle
			}
			for (let option of this.options) {
				if (option.value === this.value) {
					return this.showTitle ? this.title + ': ' + option.title : option.title;
				}
			}
			return this.emptyTitle;
		},

	},
	methods: {
		changeState(value) {
			this.$emit('input', value);
		},
		hasOptionColor(option) {
			return option.color ? '<span class="dot-option__color">.</span>' : ''
		},
		getColor({ color }) {
			let hex;
			switch (color) {
				case 'green': {
					hex = '#27ae60';
					break;
				}
				case 'amber': {
					hex = '#f39c12';
					break;
				}
				case 'red': {
					hex = '#e74c3c';
					break;
				}

				default: {
					hex = 'inherit'
				}
			}

			return hex
		}
	}

}
</script>

<style scoped>
.fill {
	width: 100%;
}
</style>

<style lang="scss">
.green {
	color: #27ae60 !important;
}

.amber {
	color: #f39c12 !important;
}

.red {
	color: #e74c3c !important;
}

.dot-option__color {
	display: flex;
	font-size: 88px;
	line-height: 0;
	top: -50%;
	position: relative;
	margin-left: -8px;
}

</style>