<template>
    <b-row>
        <b-col sm="12" class="reflection-container">
            <div>
                <ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
            </div>
            <Pulse loading v-if="isBusy" class="loader" color="#999999" />
            <b-alert v-if="message != null" :show="true" :dismissible="true" @dismissed="messageDismissed"
                :variant="message.type"> {{ message.message }} </b-alert>

            <div class="add-reflection__title-section">
                <h4 class="dashboard-pages__title">Describe your Reflective Practice Activity or Event</h4>
            </div>
            <b-card>
                <Form @handleTitleInput="messageDismissed" :load-form="loadForm" ref="form" :entry-id="entryId" @show-message="showMessage" @hide-message="messageDismissed"
                    @loading-started="isBusy = true" @loading-entry="entrySubmitted" @loading-finished="isBusy = false"
                    :form="form" v-if="form" v-show="!isBusy" />
            </b-card>

            <PageTour description="This is the Reflection section where you can complete a reflective piece from one of our model templates to support you in your professional development. <br> <br> Let’s take you for a tour" ref="pageTourRef" @handleShowTour="handleShowTour"  />
            <v-tour name="reflectionTour" :steps="steps" :options="{ highlight: true }"></v-tour>
        </b-col>
    </b-row>
</template>

<script>
import Form from '../../../components/form/Form';
import Pulse from '../../../components/loaders/Pulse'
import PageTour from "../../../components/common/PageTour";
import ShowTourButton from '../../../components/common/ShowTourButton';

export default {
    name: "Reflection",
    components: { Form, Pulse, PageTour, ShowTourButton },
    data: function () {
        return {
            isBusy: false,
            form: null,
            message: null,

            steps: [
                {
                    target: '.reflection-title__tour',
                    content: `This is the description of the reflection such as “Incident regarding miscounting
controlled drugs” or “Took part in a discussion to discuss better financial advice given to
clients”. <br> <br> <b> Top Tip: </b> This is a searchable field and unique to your entry that you will be able to search for later on.`
                },
                {
                    target: '.reflection-intro__tour',
                    content: `This is where you can give a brief introduction to the reflective piece.`
                },
                {
                    target: '.reflective-model__tour',
                    content: `You can click this option to change between the different reflective
models. <br> <br> The great thing is that you can partially complete these and then return and edit them at any time in the future.
<br> <br> Students can also copy any previous reflections into this section to support them in future practice.`
                },
                {
                    target: '.appendix__tour',
                    content: `The appendix contains supplementary material that may not play an essential
part of the reflection itself, but which may be helpful in providing a more comprehensive
understanding of the reflection, incident or problem encountered.`
                },
                {
                    target: '.references__tour',
                    content: `Referencing is an important part of academic work. It puts your work in
context, demonstrates the breadth and depth of your research, and acknowledges other
people&#39;s work. You should reference whenever you use someone else's idea!`
                },
                {
                    target: '.tag-entry__tour',
                    content: `You can create some personal tags in your Profile so that you can easily search and produce a personalised portfolio at some stage in the future.`
                },
				{
					target: '.attach-evidence__tour',
					content: `Here you can attach evidence to your reflection, for example: perhaps some of the journals or research that you used.`
				},
                {
					target: '.footer-buttons__tour',
					content: `Here you can choose ‘Save and Close’ to save the work that you have done so far and close the page. <br> <br> You can choose ‘Save and Continue’ if you are continuing this piece of work and wish to save your progress so far. <br> <br> You can choose ‘Preview’ to see what your entry would look like in a print-ready format.`
				},
            ]
        }
    },
    methods: {
        handleShowTour() {
			this.$tours['reflectionTour'].start()
		},
        loadForm: function () {
            this.form = null;
            if (this.$route.params && this.$route.params.id !== undefined) {
                this.loadExistingEntry(this.$route.params.id);
            } else {
                this.loadNewForm();
            }
        },
        loadExistingEntry: function (id) {
            this.isBusy = true;
            this.entryId = id;
            this.$http.get('form/entry/' + id).then(response => {
                this.form = response.body;
            }).catch((resp) => {
                this.$httpError('Failed to load entry', resp);
            }).then(() => {
                this.isBusy = false;
            })
        },
        loadNewForm: function () {
            this.isBusy = true;
            this.$http.get('form/reflection').then(response => {
                this.form = response.body;
            }).catch((resp) => {
                this.$httpError('Failed to load Reflection form', resp);
            }).then(() => {
                this.isBusy = false;
            });
        },
        entrySubmitted: function () {
            // alert('Received');
        },
        showMessage: function (event) {
            this.message = event;
        },
        messageDismissed: function () {
            this.message = null;
        },
        onLoad: function () {
            this.loadForm();
        }

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.form = null;
            vm.message = null;
            vm.entryId = null;
            vm.onLoad();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.form.leavingRoute(to, from, next);
    }
}
</script>

<style scoped lang="scss">
.add-reflection__title {
	font-weight: 600;
    color: $blue-300;
}

.reflection-container {
    margin: 0 auto;
    width: 100%;
    max-width:950px;
}
</style>
