<template>
	<div class="header" id="main-header">
		<b-navbar type="dark" :toggleable="false" :style="headerStyle">
			<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
			<b-navbar-brand v-if="isMobile" style="margin-right: 5px;" to="/">
				<img v-if="!branding || !branding.logoUrl" class="navbar-img" src="../assets/cpdme-logo-white.png" alt="CPDme">
				<img v-else class="navbar-img" :src="branding.logoUrl" alt="CPD Cloud">
				<b v-if="!isMobile"> {{title}}</b>
			</b-navbar-brand>
			<!-- <b class="arrow-button__breadcrumb" v-if="breadcrumbTitle && !isMobile"><span class="arrow-back__button" role="button"><arrow-left-circle role="button" v-if="!isMobile" @click="navigateBack" /></span>{{ breadcrumbTitle }}</b> -->
			<b v-if="breadcrumbTitle && !isMobile"><chevron-right class="mr-1" v-if="!isMobile" />{{ breadcrumbTitle }}</b>

			


			<b-collapse is-nav id="nav_collapse">
				<b-navbar-nav class="ml-auto my-profile__header settings-name__tour" v-if="!isMobile && profile">
					<ProfileImage :key="profileImageChangeCount" :width="40" :profile-image-url="profileImageUrl" />
					<b-nav-item-dropdown class="my-profile__header-item" right>
						<!-- Using button-content slot -->
						<template slot="button-content" v-if="loading">
							Loading...
						</template>
						<template slot="button-content" v-else>
							{{ fullName }}
						</template>
						<b-dropdown-item active-class="header-dropdown__active" :to="{ name: 'profile' }"><card-account-details-outline-icon/>
							Profile</b-dropdown-item>
						<b-dropdown-item active-class="header-dropdown__active" :to="{ name: 'settings' }"><settings-icon/> Settings</b-dropdown-item>
						<b-dropdown-item active-class="header-dropdown__active" :to="{ name: 'account-management' }"><account-edit-outline-icon/> Account Management</b-dropdown-item>
						<b-dropdown-item active-class="header-dropdown__active" class="logout" @click="logout"><logout-icon></logout-icon> Logout</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>

			<!-- Logo for mobile -->
			<b-button right variant="light" class="ml-auto" size="sm" @click="$refs.mobileSidebar.show()"
				v-if="isMobile"><menu-icon></menu-icon> Menu</b-button>
		</b-navbar>

		
		<b-modal ref="idleModal" size="md" header="" title="Session Expiring Soon" :hide-header-close="true"
			:no-close-on-esc="true" :no-close-on-backdrop="true" header-bg-variant="dark" header-text-variant="light"
			ok-variant="warning" ok-title="Logout" @ok="logout" cancel-title="Keep me logged in">
			<p>You have been inactive for 15 minutes. For security purposes, we'll log you out shortly.</p>
			<small>You can disable this logout feature in the Settings menu.</small>
		</b-modal>
		<MobileSidebar ref="mobileSidebar" :profile="profile" v-if="isMobile" />
	</div>
</template>

<script>
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import ArrowLeftCircle from 'vue-material-design-icons/ArrowLeftCircle'
import ShieldAccountIcon from 'vue-material-design-icons/ShieldAccountOutline';
import EmailIcon from 'vue-material-design-icons/EmailOutline';
import MenuIcon from 'vue-material-design-icons/Menu';
import { mapGetters } from "vuex";
import MobileSidebar from "./sidebar/MobileSidebar";
import EventBus from "@/event-bus";
import Managers from '../managers';
import ProfileImage from "../components/profile/ProfileImage";

export default {
	name: "Header",
	props: {
		profile: {
			type: Object,
			required: false
		},
	},
	components: { ChevronRight, ProfileImage, ArrowLeftCircle, MobileSidebar, ShieldAccountIcon, MenuIcon, EmailIcon },
	data: function () {
		return {
			breadcrumbTitle: '',
			logoutTimer: null,
			browserWidth: 0,
			branding: null,
			logoutTimerPaused: false,
			baseUrl: '',
			profileImageChangeCount: 0
		}
	},
	computed: {
		profileImageUrl() {
			let authToken = Managers.authentication.loginToken();
			return `${this.baseUrl}profile/image?auth_token=${authToken}`
		},
		fullName: {
			get() {
				return this.profile.firstName + ' ' + this.profile.lastName;
			}
		},
		loading: {
			get() {
				return this.profile == null;
			}
		},
		...mapGetters({
			settings: 'settings/getSettings'
		}),
		isMobile() {
			return this.browserWidth < 768;
		},
		headerStyle() {
			if (this.branding && this.isMobile) {
				return { 'background-color': `#${this.branding.colourHex}` }
			}
			return {};
		}
	},
	methods: {
		navigateBack() {
			this.$router.go(-1)
		},
		logout: function () {
			this.$store.dispatch('authentication/logout').then(() => {
				if (this.branding && this.branding.loginLink) {
					window.location = this.branding.loginLink;
				} else {
					this.$router.push({ name: 'login-v2' });
				}
			});
		},
		handleResize() {
			this.browserWidth = window.innerWidth;
		},
		loadBranding() {
			this.$http.get('branding').then((resp) => {
				this.branding = resp.body;
			}).catch(() => { });
		},
		pauseLogoutTimer() {
			this.logoutTimerPaused = true;
			this.clearLogoutTimer();
		},
		resumeLogoutTimer() {
			this.logoutTimerPaused = false;
		},
		clearLogoutTimer() {
			if (this.logoutTimer != null) {
				clearTimeout(this.logoutTimer);
			}
		}
	},
	watch: {
		$route(to) {
			this.breadcrumbTitle = to.meta.breadcrumbTitle;
		}
	},
	created: function () {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
		this.loadBranding();
		this.baseUrl = process.env.VUE_APP_API_ROOT;
		this.breadcrumbTitle = this.$router.currentRoute.meta.breadcrumbTitle;
		EventBus.$on('logout-timer/pause', this.pauseLogoutTimer);
		EventBus.$on('logout-timer/resume', this.resumeLogoutTimer);
		EventBus.$on('profile-image__changed', () => {
			this.profileImageChangeCount += 1
		});
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	onIdle() {
		if (this.settings.logoutTimer.enabled && !this.logoutTimerPaused) {
			this.$refs.idleModal.show();
			this.logoutTimer = setTimeout(() => {
				this.logout();
			}, 120000);
		}
	},
	onActive() {
		this.clearLogoutTimer();
	}
}
</script>

<style lang="scss">
li.logout a.dropdown-item {
	color: red !important
}

.my-profile__header-item {
	.dropdown-toggle {
		color: $border-color !important;
		&:hover {
			color: rgba(0, 0, 0, .5) !important;
		}
	}
}
</style>

<style scoped lang="scss">
.arrow-button__breadcrumb {
	display: flex;
	align-items: center;
}

.arrow-back__button {
	height: 32px !important;
	width: 32px !important;
	margin-bottom: 4px;
	margin-right: 8px;
	span {
		height: 32px !important;
		width: 32px !important;
		svg {
			height: 32px !important;
			width: 32px !important;
		}
	}
}

.header {
	margin-left: -25px;
	margin-right: -25px;
	padding-bottom: 24px;

	width: calc(100% - 200px);
	margin-left: auto;
}

.navbar {
	background-color: #fff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.header-dropdown__active {
	color: $white !important;
	span {
		color: $white;
	}
}

.navbar-brand {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: 1rem;
}

.navbar-img {
	height: 30px;
	margin-right: 20px;
}

.navbar .form-control {
	padding: .75rem 1rem;
	border-width: 0;
	border-radius: 0;
}


.nav-item {
	font-weight: bold;
	color: white;
}

@media screen and (max-width: 767px) {
	.header {
		width: calc(100% + 30px);
		margin-left: -15px !important;
		margin-right: -15px !important;
	}
	.navbar {
		background-color: #2c3e50;
	}
}
</style>
