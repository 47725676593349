<template>
    <div class="dashboard">
        <div class="container-fluid">
        <Header :profile="profile"></Header>
            <div class="row">
                <Sidebar :profile="profile" />
                <MainContent :profile="profile"/>
            </div>
        </div>
        <LoadingOverlay :show="showInitialLoadingScreen" :label="loadingLabel" />
    </div>
</template>

<script>
    import MainContent from "../components/MainContent";
    import Sidebar from "../components/Sidebar";
    import Header from "../components/Header";
    import LoadingOverlay from "../components/LoadingOverlay";
    import EventBus from '../event-bus';

    export default {
        name: "Dashboard",
        components: {LoadingOverlay, MainContent, Sidebar, Header },
        data: function() {
            return {
                showInitialLoadingScreen: true,
                sidebarMethod: '',
                profile: {
                    firstName: '',
                    lastName: ''
                },
                date: new Date()
            }
        },
        computed:  {
          loadingLabel: {
              get() {
                  let greeting = 'Hello';
                  if (this.date.getHours() < 12) {
                      greeting = 'Good morning';
                  } else if (this.date.getHours() >= 12 && this.date.getHours() < 16) {
                      greeting = 'Good afternoon';
                  } else {
                      greeting = 'Good evening';
                  }
                  if (this.profile.firstName !== '') {
                      return greeting + ' ' + this.profile.firstName;
                  } else {
                      return greeting;
                  }
              }
          }
        },
        methods: {
            updateProfileInformation: function() {
                this.$http.get('profile').then(response => {
                    this.profile = response.data;
                    this.storeMembershipStatus();
                });
            },
            storeMembershipStatus: function() {
                this.$store.dispatch('authentication/setFullMembershipStatus', {fullMember: this.profile.fullMember});
                this.$gtag.config({
                    'membership': this.profile.fullMember ? 'premium' : 'basic'
                });
            },
            loadSettings() {
                this.$http.get('settings/dashboard').then((resp) => {
                    this.$store.dispatch('settings/storeSettings', resp.body);
                }).catch((resp) => {
                    this.$httpError('Failed to load settings', resp);
                })
            },
            loadFeatures() {
                this.$http.get('features/user').then((resp) => {
                    let featuresObj = resp.body.features;
                    let features = [];
                    for (let featureKey in featuresObj) {
                        if (featuresObj[featureKey]) {
                            features.push(featureKey);
                        }
                    }
                    this.$store.dispatch('features/storeFeatures', features);
                }).catch((resp) => {
                    this.$httpError('Failed to load features', resp);
                })
            },
            showToast({title, message}) {
                this.$toast('success', title, message, 5000);
            }
        },
        created() {
            if (process.env.VUE_APP_SHOW_LOADER) {
                setTimeout(() => {
                    this.showInitialLoadingScreen = false;
                }, 5000);
            } else {
                this.showInitialLoadingScreen = false;
            }
            this.updateProfileInformation();
            this.loadSettings();
            this.loadFeatures();
            EventBus.$on('user-changed', this.updateProfileInformation);
            EventBus.$on('settings-updated', this.loadSettings);
            EventBus.$on('show-toast', this.showToast);
        },
        destroyed() {
            EventBus.$off('show-toast', this.showToast);
        }
    }
</script>

<style scoped>
    .dashboard {
        min-height: 100%;
    }
</style>
