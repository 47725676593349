<template>
<div>
    <b-form-group class="mt-3" label="Category *" required>
        <b-form-select v-model="sharedCpd.category" :options="categories"></b-form-select>
    </b-form-group>

    <b-form-group label="Title *" label-for="title" :invalid-feedback="titleValid" :state="titleState" required>
        <b-form-input id="title" v-model="sharedCpd.title" type="text" :state="titleState" trim autocomplete="off" required/>
    </b-form-group>

    <b-form-group class="mt-3" label="Description *" :invalid-feedback="descriptionValid" :state="descriptionState" required>
        <b-textarea v-model="sharedCpd.description" :state="descriptionState" required></b-textarea>
        <small>{{ sharedCpd.description.length }} / 400 characters</small>
    </b-form-group>

    <b-form-group label="Website URL" label-for="website-url" :invalid-feedback="websiteUrlValid" :state="websiteUrlState" required>
        <b-form-input id="website-url" v-model="sharedCpd.websiteUrl" type="text" :state="websiteUrlState" trim autocomplete="off" required/>
    </b-form-group>
</div>
</template>

<script>
export default {
    name: "SharedCPDEditor",
    props: {
        sharedCpd: {
            type: Object,
            required: true
        }
    },
    data( ) {
        return {
            categories: [
                'Business Management and Admin',
                'Clinical Education',
                'Coaching and Mentoring',
                'Computer Science and Technology',
                'Financial Management and Admin',
                'Health, Safety and Security',
                'Leadership',
                'Mandatory Training',
                'Management',
                'Trauma Care',
                'Wellbeing',
                'Other'
            ]
        }
    },
    methods: {
        validURL(str) {
            let pattern = new RegExp('^https?:\\/\\/'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        }
    },
    computed: {
        websiteUrlValid() {
            if (this.websiteUrlState) {
                return ''
            }
            return 'Please enter a valid website address, starting with http(s)://';
        },
        websiteUrlState() {
            return this.validURL(this.sharedCpd.websiteUrl);
        },
        titleValid() {
            if (this.titleState) {
                return '';
            }
            return 'A title is required'
        },
        titleState() {
            return !!(this.sharedCpd.title && this.sharedCpd.title.trim() !== '')
        },
        descriptionValid() {
            if (this.descriptionState) {
                return '';
            }
            if (this.sharedCpd.description && this.sharedCpd.description.length > 400) {
                return 'The description is too long.'
            }
            return 'A description is required'
        },
        descriptionState() {
            return !!(this.sharedCpd.description && this.sharedCpd.description.length <= 400);
        },
    },
    created() {
        this.categories.sort();
    }
}
</script>

<style scoped>

</style>
