<template>
    <div class="footer-login text-center">
        <a class="pr-1" href="https://www.cpdme.com/about"> About </a> • <a class="pl-1 pr-1" href="https://www.cpdme.com/privacy"> Privacy </a> • <a class="pl-1" href="https://www.cpdme.com/contactus"> Contact </a>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    .footer-login {
        background-color: white;
        width:calc(100% - 30px);
        position: absolute;
        bottom:5px;
        padding:20px 0 20px 0;
        font-size:14px;
    }
    .footer-login a{
        color:#26314c;
    }

    /*@media (max-width: 768px) {*/
    /*    .footer-login {*/
    /*        position: unset !important;*/
    /*        display: none !important;*/
    /*    }*/
    /*}*/

</style>
