<template>
	<div class="dashboard-home">
		<ShowTourButton class="dashboard-show__tour-button" @handleClick="$refs.pageTourRef.handleOpen()" />
		<div ref="dashboardRoot" class="dashboard-home__header">
			<div class="dashboard-home__header-left">
				<h4 class="dashboard-pages__title mb-0">Dashboard</h4>
				<router-link style="color: #222222" class="customise-tour customise-button"
					:to="{ name: 'settings' }"><settings-icon /> Customise</router-link>
			</div>
			<div class="dashboard-home__header-right">
				<PeriodSelector class="period-selector__tour" @formattedPeriod="_formattedPeriod" v-model="selectedPeriod"
					v-on:input="handlePeriodChange" />
			</div>
		</div>

		<b-alert :show="true" variant="warning" v-if="profile && !profile.mobileNumber && !profile.alternativeEmail">
			<lock-outline-icon/> Help us to better protect your account by adding a mobile number or an alternative email address to your <b><router-link to="profile">Profile</router-link></b>
		</b-alert>

		<b-alert :show="true" variant="warning" v-if="profile && ((profile.mobileNumber && !profile.mobileVerified) || (profile.alternativeEmail && !profile.alternativeEmailVerified))">
			<lock-outline-icon/> Help us to better protect your account by verifying your contact details in your <b><router-link to="profile">Profile</router-link></b>
		</b-alert>

		<b-alert :show="true" variant="secondary" v-if="isFullMember && showTagsBanner">
			<InformationOutlineIcon /> <b>Did you know?</b> You can now tag your entries to easily find and group them later -
			set yours up now in your <b><router-link to="profile">Profile</router-link></b>!
		</b-alert>

		<b-alert show variant="warning" v-if="!isFullMember">
			<h4 class="alert-heading">Upgrade Today</h4>
			<p class="mb-0">
				You're currently using CPDme as a Basic Member. Upgrade today to store as many entries as you need, generate a
				portfolio and more.
			</p>
			<b-button variant="primary" class="mt-3" target="_blank" @click="upgradeMembership"><star-icon></star-icon> Upgrade Now</b-button>
		</b-alert>


		<b-row>
			<b-col sm="12">
				<h6 class="showing-data__for">{{ formattedPeriod }}</h6>
				<TopStatistics :period="selectedPeriod" />
			</b-col>
			<b-col sm="12">
				<b-row class="widgets" v-if="settings">
					<b-col sm="12" xl="6" v-if="settings.dashboardWidgets.cpdCategories">
						<b-card header-bg-variant="white" header-class="custom-card__header" header="Top CPD Categories" style="background-color: white;">
							<PulseLoader loading v-if="loading" />
							<div class="cpdcategories-graph" v-if="!loading && pieGraphData.cpdCategories.datasets">
								<PieGraph :height="graphHeight" :chart-data="this.pieGraphData.cpdCategories"
									v-if="this.pieGraphData.cpdCategories.datasets[0].data.length > 0" />
								<b-alert variant="info" v-else show>
									No entries within the specified period.
								</b-alert>
							</div>
						</b-card>
					</b-col>
					<b-col sm="12" xl="6" :class="`${isMobile ? 'mt-4' : ''}`" v-if="settings.dashboardWidgets.activityTypes">
						<b-card header-bg-variant="white" header-class="custom-card__header" header="Activity Types" style="background-color: white;">
							<PulseLoader loading v-if="loading" />
							<div class="cpdcategories-graph" v-if="!loading && pieGraphData.activityArranged.datasets">
								<PieGraph :height="graphHeight" :chart-data="this.pieGraphData.activityArranged"
									v-if="this.pieGraphData.activityArranged.datasets[0].data.length > 0" />
								<b-alert variant="info" v-else show>
									No entries within the specified period.
								</b-alert>
							</div>
						</b-card>
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="branding && branding.organisationId === 51">
						<EFIChartESHIDashboardWidget :period="selectedPeriod" :graph-height="graphHeight" />
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="settings.dashboardWidgets.entriesGraph">
						<EntryFrequencyGraph />
					</b-col>
					<b-col sm="12" xl="6" class="mt-4"  v-if="settings.dashboardWidgets.governingBodyStandards" v-for="graph in governingBodyStandardsGraphs">
						<BarChartWidget :title="graph.title" :show-legend-on-mobile="graph.showLegendOnMobileDevices" :graph-data="graph.graphData" :browser-width="browserWidth" :graph-height="graphHeight"/>
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="settings.dashboardWidgets.sharedCPD" v-requires-feature="'SharedCPD'">
						<SharedCPDDashboardWidget />
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="settings.dashboardWidgets.calendar" v-requires-feature="'SharedCPD'">
						<CalendarDashboardWidget />
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="settings.dashboardWidgets.webinars" v-requires-feature="'SharedCPD'">
						<Webinars />
					</b-col>
					<b-col sm="12" xl="6" class="mt-4" v-if="settings.dashboardWidgets.clinicalSkills">
						<ClinicalSkillsOverview />
					</b-col>
				</b-row>
			</b-col>

			<!-- <b-col sm="12" xl="6">
				<CalendarDashboardWidget />
				<Webinars />
			</b-col> -->
		</b-row>


		<PreviewEntryModal />
		<UpgradeModal ref="upgradeModal" />
		<CalendarEventDetailsModal ref="calendarEventModal"/>
		
		
		<PageTour ref="pageTourRef" description="Firstly, a warm welcome to CPDme. We hope that this simple guide will allow you to quickly get to grips with using our amazing platform to make recording and managing your CPD simple.<br> <br> The Dashboard is where all your CPD submissions are analysed to give you a quick overview. Let me take you for a tour." @handleShowTour="handleShowTour"  />
		<v-tour name="dashboardTour" :steps="steps" :options="{ highlight: true }"></v-tour>
		<v-tour name="miniTour" :steps="miniTourSteps" :options="{ highlight: true }"></v-tour>

	</div>
</template>

<script>
import TopStatistics from "../../components/TopStatistics";
import PieGraph from "../../components/graphs/PieGraph";
import PeriodSelector from "../../components/PeriodSelector";
import Webinars from "../../components/Webinars";
import PulseLoader from "../../components/loaders/Pulse";
import { mapGetters } from "vuex";
import ClinicalSkillsOverview from "../../components/clinical-skills/ClinicalSkillsOverview";
import PreviewEntryModal from "../../components/entries/PreviewEntryModal";
import EntryFrequencyGraph from "../../components/EntryFrequencyGraph";
import CalendarDashboardWidget from "../../components/calendar/CalendarDashboardWidget";
import SharedCPDDashboardWidget from "../../components/shared-cpd/SharedCPDDashboardWidget";
import StarIcon from "vue-material-design-icons/Star";
import EFIChartESHIDashboardWidget from "@/components/organisations/EFIChartESHIDashboardWidget";
import InformationOutlineIcon from "vue-material-design-icons/InformationOutline";
import Providers from "@/providers";
import PageTour from "@/components/common/PageTour";
import ShowTourButton from "../../components/common/ShowTourButton";
import BarChartWidget from "../../components/BarChartWidget.vue";
import UpgradeModal from "../../components/UpgradeModal.vue";
import CalendarEventDetailsModal from "@/components/calendar/CalendarEventDetailsModal.vue";

export default {
	name: "DashboardHome",
	components: {
		EFIChartESHIDashboardWidget,
		StarIcon,
		SharedCPDDashboardWidget,
		CalendarDashboardWidget,
		EntryFrequencyGraph,
		PreviewEntryModal,
		ClinicalSkillsOverview, PeriodSelector, TopStatistics, PieGraph, Webinars, PulseLoader, InformationOutlineIcon,
		PageTour,
		ShowTourButton, 
		BarChartWidget,
		UpgradeModal, 
		CalendarEventDetailsModal
	},
	data: function () {
		return {
			formattedPeriod: 'Currently showing data for the last 12 months',
			selectedPeriod: '12months',
			branding: null,
			showTagsBanner: false,
			pieGraphData: {
				cpdCategories: {},
				activityArranged: {}
			},
			governingBodyStandardsGraphs: [],
			profile: null,
			pieColors: [
				'#3D99C2',
				'#e1974c',
				'#84ba5b',
				'#d35e60',
				'#808585',
				'#9067a7',
				'#ab6857',
				'#ccc210'
			],
			loading: true,
			eventLoading: false,
			graphHeight: 300,
			miniTourSteps: [
				{
					target: '.dashboard-show__tour-button',
					content: 'If you’d like to get an overview of the CPDme platform, click the ‘Show tour’ button available on most pages for a guided walkthrough of how it works.',
					params: {
						enableScrolling: false
					}
				}
			],
			steps: [
				{
					target: '.period-selector',
					content: 'Toggle this box to change your dashboard between 12, 24, 36 Months or select a custom two dates to change your dashboard statistics.'
				},
				{
					target: '.time-logged__tour',
					content: 'This will display the number of hours that you have logged in the period selected previously.'
				},
				{
					target: '.total-entries__tour',
					content: 'This will highlight the amount of CPD diary entries and reflections that you have over this period.'
				},
				{
					target: '.diary-entries__tour',
					content: 'This is the amount of individual diary entries that you have documented over the defined period.'
				},
				{
					target: '.reflections-tour',
					content: 'This is the amount of individual reflective practice entries that you have documented over the defined period.'
				},
				{
					target: '.customise-tour',
					content: 'You can click this option and customise your dashboard to hide and reveal information that you might find useful.'
				},
				{
					target: '.settings-name__tour',
					content: "This is where you can access the settings of your 'CPDme' Dashboard"
				},
			],
			browserWidth: 0,
		}
	},
	computed: {
		...mapGetters({
			isFullMember: 'authentication/isFullMember',
			settings: 'settings/getSettings'
		}),
		isMobile() {
			return this.browserWidth <= 1200;
		},
	},
	methods: {
		handleShowMiniTour() {
			this.$tours['miniTour'].start()
			localStorage.setItem('skipMiniTour', 'true');
		},
		handleShowTour() {
			this.$tours['dashboardTour'].start()
		},
		handleSkipTour() {
			localStorage.setItem('skipTourDashboard', 'true');
		},
		_formattedPeriod(period) {
			this.formattedPeriod = period
		},
		isLoadingRendered() {
			// This function makes sure the loading screen is not visible on screen before rendering
			// Any element on the dashboard, such as the tour for the Show Tour button
			return new Promise((resolve, reject) => {
				const interval = setInterval(() => {
					const loadingText = document.querySelector('.loading-text')
					if (!loadingText) {
						clearInterval(interval);
						resolve(loadingText);
					}
				}, 1000);
			});
		},
		handleResize() {
			this.graphHeight = window.innerWidth > 800 ? 200 : 300;
			this.browserWidth = window.innerWidth;
		},
		loadBranding() {
			this.$http.get('branding').then((resp) => {
				this.branding = resp.data;
			}).catch(() => { })
		},
		loadPieGraphData: function () {
			this.loading = true;
			this.$http.get(`graphs/${this.selectedPeriod}`).then(response => {
				let graphs = response.body;

				let cpdDataPoints = [];
				let cpdCategories = [];
				for (let i = 0; i < graphs.cpdCategories.length; i++) {
					cpdDataPoints.push(graphs.cpdCategories[i].value);
					cpdCategories.push(graphs.cpdCategories[i].category);
				}

				let arrangedDataPoints = [];
				let arrangedCategories = [];
				for (let i = 0; i < graphs.activityArranged.length; i++) {
					arrangedDataPoints.push(graphs.activityArranged[i].value);
					arrangedCategories.push(graphs.activityArranged[i].category);
				}

				this.pieGraphData = {
					cpdCategories: {
						datasets: [
							{
								data: cpdDataPoints,
								backgroundColor: this.pieColors
							}
						],
						labels: cpdCategories
					},
					activityArranged: {
						datasets: [
							{
								data: arrangedDataPoints,
								backgroundColor: this.pieColors
							}
						],
						labels: arrangedCategories
					}
				};

			}).catch((error) => {
				this.$httpError('Failed to load graphs', error);
			}).finally(() => {
				this.loading = false;
			});
		},
		loadGoverningBodyStandardsGraphs: function() {
			this.loading = true; 
			this.$http.get(`graphs/governing-body-standards/${this.selectedPeriod}`).then(response => {
				this.governingBodyStandardsGraphs = response.body;
			}).catch((error) => {
				this.$httpError('Failed to load governing body standards graphs', error);
			}).finally(() => {
				this.loading = false;
			})
		},
		loadProfile() {
            this.loading = true;
            this.$http.get('profile').then((resp) => {
                this.profile = resp.data;
                this.loading = false;
            }).catch((resp) => {
                this.loading = false;
            });
        },
		handlePeriodChange: function() {
			this.loadPieGraphData();
			this.loadGoverningBodyStandardsGraphs();
		},
		loadTags() {
			Providers.profile.tags().then((tags) => {
				this.showTagsBanner = tags.length === 0;
			}).catch(() => { });
		},
		upgradeMembership() {
            this.$refs.upgradeModal.show();
        }, 
		showEvent(eventId) {
			this.eventLoading = true; 
			this.$http.get(`calendar/events/${eventId}`).then(response => {
				this.$refs.calendarEventModal.show(response.body)
			}).catch((error) => {
				this.$httpError('Failed to load event details', error);
			}).finally(() => {
				this.eventLoading = false;
			})
		}
	},
	created: async function () {
		this.loadPieGraphData();
		this.loadGoverningBodyStandardsGraphs();
		this.loadBranding();
		this.loadProfile();
		this.loadTags();
		window.addEventListener('resize', this.handleResize);
		this.handleResize();

		const isLoading = await this.isLoadingRendered()
		const tourFinished = localStorage.getItem('skipMiniTour');
		if (!isLoading && !tourFinished) {
			this.handleShowMiniTour()
		}

		if (this.$route.query?.event) {
			this.showEvent(this.$route.query.event);
		}
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
}
</script>

<style scoped lang="scss">
.customise-button {
	border-radius: 12px;
	padding: 0px 8px;
	border: 1px solid $border-color;
}

.dashboard-home__header-right {
	display: flex;
	justify-content: flex-end;

	width: 35%;
	max-width: 600px;
	min-width: 600px;
}

.dashboard-home__header {
	margin-bottom: 24px;
	display: flex;
	flex-wrap: wrap;
}

.dashboard-home__title {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 0;
}

.showing-data__for {
	font-weight: 600;
	margin-bottom: 8px;
	color: $blue-300;
}

.dashboard-home__header-left {
	display: flex;
	align-items: center;
	margin-right: auto;

	margin-bottom: 16px;
	width: 250px;
	justify-content: space-between;
}

@media screen and (max-width: 1024px) {
	.dashboard-home__header-right {
		display: flex;
		justify-content: baseline;

		width: 100%;
		max-width: unset;
		min-width: unset;
	}

	.dashboard-home__header {
		display: flex;
		flex-direction: column;
	}
}
</style>

<style>
.custom-card__header {
	color: #8ba7b8;
	font-weight: 500;
}
</style>
