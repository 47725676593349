<template>
	<div class="shared-cpd">
		<b-row class="mb-4">
			<b-col sm="12">
				<ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
			</b-col>
			<b-col sm="12" md="5" lg="7" xl="6" class="d-flex align-items-start mb-1">
				<h4 class="dashboard-pages__title mb-0">Shared CPD</h4>
			</b-col>
			<b-col sm="12" md="7" lg="5" xl="6" class="sharedcpd-header__right">
				<b-form class="sharedcpd-form" :inline="true" @submit.prevent="refresh" style="gap: 8px;">
					<b-form-group class="mb-0">
						<b-form-radio-group id="btn-radios-2" v-model="sortBy" :options="sortOptions" buttons size="md"
							name="radio-btn-outline" class="radio-buttons__tour category-sort__button" @change="sortUpdated"></b-form-radio-group>
					</b-form-group>
					<b-form-group class="mb-0">
						<b-dropdown class="shared-cpd__category-tour" toggle-class="ovr-button" :text="filterCategoryText" :disabled="loading"
							size="md">
							<b-dropdown-header>Select Category</b-dropdown-header>
							<b-dropdown-item v-for="category in categoryValues" v-bind:key="category.value"
								@click="changeCategory(category.value)">{{ category.title }}</b-dropdown-item>
						</b-dropdown>
					</b-form-group>

					<SearchBar placeholder="Search..." @updateVal="(val) => filter.text = val" :incomingValue="filter.text" :readonly="loading"/>
				</b-form>
			</b-col>
		</b-row>

		<b-row v-if="totalItems === 0">
			<b-col sm="12">
				<b-alert show variant="info">
					<h4 class="alert-heading">No Results</h4>
					<p class="mb-0">
						There is no Shared CPD matching your search.
					</p>
				</b-alert>
			</b-col>
		</b-row>

		<b-card>
			<b-row>

				<b-col sm="12" lg="6" class="mb-4" v-for="(item, idx) of items" v-bind:key="idx">
					<SharedCPDListItem :shared-cpd-id="item.id" :title="item.title" :description="item.description"
						:avatar-url="item.avatarUrl" :category="item.category" :nickname="item.nickname" :date="item.date"
						:website-url="item.websiteUrl" :upvotes="item.upvotes" :has-upvoted="item.hasUpvoted" :show-helpful="true" />
				</b-col>

			</b-row>
		</b-card>

		<PageTour ref="pageTourRef" description="This great section is where other users of CPDme have found some useful CPD or learning opportunities that they have shared with our community. Watch this short video to explain this feature. These are all moderated by our CPD team, so if you spot something you think shouldn’t be here, please let us know." @handleShowTour="handleShowTour"  />
		<v-tour name="sharedCPDtour" :steps="steps" :options="{ highlight: true }"></v-tour>
		
		<Pulse v-if="loading" color="#888888" />
	</div>
</template>

<script>
import SharedCPDListItem from "../../components/shared-cpd/SharedCPDListItem";
import Pulse from '../../components/loaders/Pulse';
import SearchBar from "../../components/common/SearchBar";
import PageTour from "@/components/common/PageTour";
import ShowTourButton from "../../components/common/ShowTourButton";

export default {
	name: "SharedCPD",
	components: { SharedCPDListItem, ShowTourButton, Pulse, SearchBar, PageTour },
	data: function () {
		return {
			loading: true,
			items: [],
			itemsPerPage: 10,
			currentPage: 0,
			totalItems: -1,
			sortOptions: ['Newest', 'Most Helpful'],
			sortBy: 'Newest',
			filter: {
				text: '',
				category: ''
			},
			categories: [
			],

			steps: [
				{
					target: '.radio-buttons__tour label:nth-child(1)',
					content: `This orders submissions to the shared CPD area by newest to oldest.`
				},
				{
					target: '.radio-buttons__tour label:nth-child(2)',
					content: `This orders the submissions by the most thumbs up.`
				},
				{
					target: '.shared-cpd__category-tour',
					content: `This allows you to filter the submissions by the category that is most relevant to you.`
				},
				{
					target: '.search-tour',
					content: `This allows you to search all the Shared CPD for a specific keyword.`
				},
			]
		}
	},
	computed: {
		filterCategoryText() {
			if (this.filter.category === '') {
				return 'All';
			}
			return this.filter.category;
		},
		categoryValues() {
			let values = [];
			values.push({ value: '', title: 'All' });
			let categories = [];
			for (let cat of this.categories) {
				categories.push({ value: cat, title: cat });
			}
			values.push(...categories);
			return values;
		},
		sortParam() {
			if (this.sortBy === 'Most Helpful') {
				return 'helpful'
			} else {
				return 'newest'
			}
		}
	},
	mounted() {
		this.scroll()
	},
	watch: {
		'filter.text'() {
			if (!this.filter.text.length) {
				this.refresh();
			}
		}
	},
	methods: {
		handleShowTour() {
			this.$tours['sharedCPDtour'].start()
		},
		loadData: function () {
			this.loading = true;
			this.$http.get(`shared-cpd/limit/${this.itemsPerPage}/page/${this.currentPage}?category=${encodeURI(this.filter.category)}&search=${encodeURI(this.filter.text)}&sort=${this.sortParam}`).then((resp) => {
				let body = resp.data;
				this.totalItems = body.total;
				this.items.push(...body.items.map(item => {
					item.date = this.moment(item.date).format('DD/MM/YYYY');
					return item;
				}));

			}).catch((resp) => {
				this.$httpError('Failed to load Shared CPD', resp);
			}).then(() => {
				this.loading = false;
			})
		},
		nextPage: function () {
			if (this.totalItems === -1 || this.currentPage < Math.ceil(this.totalItems / this.itemsPerPage)) {
				this.currentPage++;
				this.loadData();
			}

		},
		scroll() {
			window.onscroll = () => {
				let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.scrollHeight;

				if (bottomOfWindow) {
					this.nextPage();
				}
			}
		},
		refresh() {
			this.items = [];
			this.currentPage = 1;
			this.loadData();
		},
		sortUpdated(newValue) {
			this.sortBy = newValue;
			this.refresh();
		},
		changeCategory(category) {
			this.filter.category = category;
			this.refresh();
		},
		loadCategories() {
			this.$http.get('shared-cpd/categories').then((resp) => {
				this.categories = resp.body;
			})
		}
	},
	created() {
		this.categories.sort();
		this.nextPage();
		this.loadCategories();
	},
}
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
	.sharedcpd-form {
		justify-content: flex-start !important;
	}
}

.sharedcpd-form {
	display: flex;
	justify-content: flex-end;
}

.sharedcpd-header__right {
	justify-content: flex-end;
	display: flex;

	form {
		justify-content: flex-end;
	}

	@media (max-width: 1024px) {
		display: flex;
		justify-content: flex-start;
	}
}

.category-sort__button {
	label {
		background: $border-color;

		&:hover {
			background: #20304e !important;
		}
	}

	label.active {
		background: #20304e !important;
	}
}
</style>