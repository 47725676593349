// Polyfills for IE11
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'

// App
import Vue from 'vue'
import './assets/css/style.css'
import "vue-material-design-icons/styles.css"
import "nprogress/nprogress.css"
import 'vue-croppa/dist/vue-croppa.css'
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import App from './App.vue'
import router from './router'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'
import Croppa from 'vue-croppa'
import * as Sentry from "@sentry/vue";

import "url-polyfill/url-polyfill.min";
import MoonLoader from 'vue-spinner/src/MoonLoader'
import ApplicationIcon from "vue-material-design-icons/Application"
import EyeOutline from "vue-material-design-icons/EyeOutline.vue"
import PencilOutline from "vue-material-design-icons/PencilOutline.vue"
import DeleteOutline from "vue-material-design-icons/DeleteOutline.vue"
import FolderAccountOutlineIcon from 'vue-material-design-icons/FolderAccountOutline'
import LogoutIcon from "vue-material-design-icons/Logout.vue"
import CogOutlineIcon from "vue-material-design-icons/CogOutline.vue"
import MagnifyIcon from "vue-material-design-icons/Magnify.vue"
import AccountIcon from "vue-material-design-icons/Account.vue"
import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue"
import AccountOutlineIcon from "vue-material-design-icons/AccountOutline.vue"
import AccountTieOutline from "vue-material-design-icons/AccountTieOutline.vue"
import AccountStarOutline from "vue-material-design-icons/AccountStarOutline.vue"
import AccountEditOutlineIcon from "vue-material-design-icons/AccountEditOutline.vue"
import CardAccountDetailsOutlineIcon from "vue-material-design-icons/CardAccountDetailsOutline.vue"
import NoteEditOutlineIcon from "vue-material-design-icons/NoteEditOutline.vue"
import MenuIcon from "vue-material-design-icons/Menu.vue"
import HomeIcon from 'vue-material-design-icons/Home.vue'
import CalendarIcon from 'vue-material-design-icons/Calendar.vue'
import ArrowRightBottomIcon from "vue-material-design-icons/ArrowRightBottom.vue"
import ViewListIcon from 'vue-material-design-icons/ViewList'
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline';
import PlusIcon from 'vue-material-design-icons/Plus';
import LockOutlineIcon from 'vue-material-design-icons/LockOutline';
import VideoAccountIcon from 'vue-material-design-icons/VideoOutline';
import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline'
import VueCarousel from 'vue-carousel';
import Spinner from 'vue-spinkit'
import WordCounter from './utilities/wordCount'
import FormTools from "./utilities/formTools";
import Utilities from "./utilities/utilities";
import moment from 'moment';
import VueImgOrientationChanger from 'vue-img-orientation-changer';
import toasterMixin from "./utilities/toaster";
import IdleVue from 'idle-vue';
import EventBus from "./event-bus";
import PrintUtils from "./utilities/printUtils";
import RequireFeature from './directives/requireFeature';
import RequireRole from "./directives/requireRole";
import VueTour from 'vue-tour'

import "@/assets/css/custom.scss";
import "vue-tour/dist/vue-tour.css"
require('vue-tour/dist/vue-tour.css')

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "dashboard-staging.cpdcloud.co.uk","dashboard.cpdcloud.co.uk"]
});

Vue.config.productionTip = false;
Vue.use(VueTour)
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueCarousel);
Vue.use(WordCounter);
Vue.use(FormTools);
Vue.use(Utilities);
Vue.use(PrintUtils);
Vue.use(Croppa);
Vue.use(VueImgOrientationChanger);
Vue.use(IdleVue, {
  eventEmitter: EventBus,
  idleTime: 60 * 15 * 1000 // 15 mins
});

Vue.mixin(toasterMixin);

Vue.directive('requires-feature', RequireFeature);
Vue.directive('requires-role', RequireRole);

Vue.component('eye-outline-icon', EyeOutline);
Vue.component('search-icon', MagnifyIcon);
Vue.component('pencil-outline-icon', PencilOutline);
Vue.component('delete-outline-icon', DeleteOutline);
Vue.component('logout-icon', LogoutIcon);
Vue.component('settings-icon', CogOutlineIcon);
Vue.component('account-icon', AccountIcon);
Vue.component('account-group-icon', AccountGroupIcon);
Vue.component('account-outline-icon', AccountOutlineIcon);
Vue.component('account-star-outline-icon', AccountStarOutline);
Vue.component('account-edit-outline-icon', AccountEditOutlineIcon);
Vue.component('account-tie-outline-icon', AccountTieOutline);
Vue.component('card-account-details-outline-icon', CardAccountDetailsOutlineIcon);
Vue.component('note-edit-outline-icon', NoteEditOutlineIcon);
Vue.component("menu-icon", MenuIcon);
Vue.component("home-icon", HomeIcon);
Vue.component('calendar-icon', CalendarIcon);
Vue.component('lock-outline-icon', LockOutlineIcon);
Vue.component("list-icon", ViewListIcon);
Vue.component('application-icon', ApplicationIcon);
Vue.component("clock-outline-icon", ClockOutlineIcon);
Vue.component('plus-icon', PlusIcon);
Vue.component('video-account-icon', VideoAccountIcon);
Vue.component('folder-account-outline', FolderAccountOutlineIcon);
Vue.component('information-outline-icon', InformationOutlineIcon);
Vue.component('arrow-right-bottom', ArrowRightBottomIcon);

Vue.component("moon-loader", MoonLoader);

Vue.component('Spinner', Spinner);


Vue.prototype.moment = moment;

Vue.http.options.root = process.env.VUE_APP_API_ROOT;

window.env = process.env;

Vue.http.interceptors.push(function(request) {
  return function(response) {
    if (response.status === 401 && !request.url.startsWith('auth/')) { /* ignore any 401's from authentication endpoints */
      store.dispatch("authentication/logout");
      router.push({'name': 'login'})
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
