import { render, staticRenderFns } from "./SharedCPDListItem.vue?vue&type=template&id=31e450bc&scoped=true"
import script from "./SharedCPDListItem.vue?vue&type=script&lang=js"
export * from "./SharedCPDListItem.vue?vue&type=script&lang=js"
import style0 from "./SharedCPDListItem.vue?vue&type=style&index=0&id=31e450bc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e450bc",
  null
  
)

export default component.exports