<template>
    <div class="list-profile-field">
        <b-form-tags v-model="value" size="md" class="mb-2" tag-variant="dark" placeholder="Add new..."  v-on:input="updateValue($event)" />
    </div>
</template>

<script>

    export default {
        name: "ListProfileField",
        components: {},
        props: {
            field: {
                type: Object,
                required: true
            },
            value: {
                type: Array
            }
        },
        data() {
            return {
                internalValue: []
            }
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            edit() {
                this.$refs.listEditModal.show();
            }
        },
        created() {
            this.internalValue = this.value;

        }
    }
</script>

<style scoped>

</style>