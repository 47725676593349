<template>
    <div class="evidence-list">
        <b-overlay :show="loading">

            <b-alert :show="true" v-if="this.value.length !== 0 && this.value.length < 4" variant="warning">
                You have currently selected {{this.value.length}} out of 12 evidence items. When submitting to the HCPC you should choose between 4 and 12 items.
            </b-alert>

            <b-alert :show="true" v-if="this.value.length >=4 && this.value.length <= 12" variant="success">
                You have currently selected {{this.value.length}} out of 12 evidence items. When submitting to the HCPC you should choose between 4 and 12 items.
            </b-alert>

            <b-alert :show="true" v-if="this.value.length >= 12" variant="warning">
                You have chosen more than 12 evidence items. When submitting to the HCPC you should choose between 4 and 12 items.
            </b-alert>

            <b-table ref="table" table-class="light" :fields="fields" :items="loadEvidence" show-empty selectable select-mode="multi"
                     selected-variant="success" @row-selected="rowSelected" @refreshed="preselectEvidence">
                <template slot="empty">
                    <b-alert variant="warning" :show="true">There is no evidence attached to the chosen activities</b-alert>
                </template>
                <template v-slot:cell(selected)="{ rowSelected }" style="max-width: 50px">
                    <CheckIcon v-if="rowSelected" />
                </template>
                <template v-slot:cell(actions)="{item, rowSelected}" style="max-width: 50px">
                    <div class="actions">
                        <AnimatedButton size="sm" variant="info" alt-variant="primary" :disabled="!rowSelected" title="Download" alt-title="Downloaded" @click="($e) => download($e, item)" />
                        <b-button size="sm" variant="info" title="Preview" @click="preview(item)"><EyeOutlineIcon /> Preview</b-button>
                    </div>
                </template>
            </b-table>
        </b-overlay>

        <ImageEditorModal ref="imageEditorModal"/>

    </div>
</template>

<script>
    import Providers from '../../providers';
    import CheckIcon from 'vue-material-design-icons/Check';
    import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline';
    import AnimatedButton from "../common/AnimatedButton";
    import ImageEditorModal from "@/components/ImageEditorModal";

    export default {
        name: "EvidenceList",
        components: {AnimatedButton, CheckIcon, ImageEditorModal, EyeOutlineIcon},
        props: {
            value: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        computed: {
            selectedEntries() {
                return this.$store.state.hcpcPortal.entries;
            },
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            }
        },
        data() {
            return {
                loading: false,
                evidence: [],
                fields: [
                    {
                        key: 'selected',
                        label: ''
                    },
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'entryTitle',
                        label: 'Attached To'
                    },
                    {
                        key: 'actions',
                        label: ''
                    }
                ]
            }
        },
        methods: {
            loadEvidence(ctx, callback) {
                this.loading = true;
                Providers.hcpcPortal.evidence(this.accessToken, this.selectedEntries).then((resp) => {
                    this.evidence = resp.body;
                    callback(this.evidence);
                }).catch((resp) => {
                    this.$httpError('Failed to load evidence', resp);
                }).finally(() => {
                    this.loading = false;
                });
                return null;
            },
            preselectEvidence() {
                setTimeout(() => {
                    let idx = 0;
                    for (let evidence of this.evidence) {
                        if (this.value.includes(evidence.storageId)) {
                            this.$refs.table.selectRow(idx);
                        }
                        idx++;
                    }
                }, 100);
            },
            rowSelected($e) {
                this.$emit('input', $e.map(v => v.storageId));
            },
            download($e, item) {
                $e.update();
                this.doDownload(item.storageId);
            },
            doDownload(storageId) {
                window.open(`${this.apiRoot}file/${storageId}`);
            },
            preview(item) {
                Providers.hcpcPortal.evidenceMetadata(this.accessToken, item.storageId).then((resp) => {
                    let filename = resp.body.filename.toLowerCase();
                    if (filename.endsWith('.jpg') || filename.endsWith('.gif') || filename.endsWith('.png')) {
                        this.$refs.imageEditorModal.show({
                            name: filename,
                            id: resp.body.id
                        });
                    } else {
                        this.doDownload(item.storageId);
                    }

                }).catch((resp) => {
                    this.$httpError('Failed to Preview File', resp);
                }).finally(() => {

                });
            }
        },
        created() {
            this.apiRoot = process.env.VUE_APP_API_ROOT;
        }
    }
</script>

<style scoped>
.actions > * {
    margin-right: 5px;
}
</style>
