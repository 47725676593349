<template>
	<div class="overflow-hidden video-card" @click="openVideo(video)">
		<b-col md="12" class="video-thumbnail__container">
			<div class="video-thumbnail__curtain">
				<img v-if="!isPremium" width="60" height="60" src="../../assets/auth/ic-play.svg" alt="">
				<img v-else width="60" height="60" src="../../assets/auth/lock.svg" alt="">
			</div>
			<b-card-img :src="video.thumbnail" class="rounded-0 video-thumbnail" />
			<div class="video-timage">
				{{ secondsToHours(video.duration) }}
			</div>
		</b-col>
		<b-col md="12" class="p-0 mb-2 video-title__container">
			<p class="video-title">
				{{ video.name }}
			</p>
		</b-col>
		<b-col sm="12" class="video-footer">
			<!-- <div class="video-footer__left d-flex align-items-center">
				<span>
					<ThumbUpOutline />
				</span>
				{{ video.likes }}
			</div> -->

			<div class="video-footer__right d-flex align-items-center mr-2">
				<span>
					<eye-outline-icon style="transform" />
				</span>
				{{ video.views }}
			</div>
		</b-col>
	</div>
</template>

<script>
import ThumbUpOutline from 'vue-material-design-icons/ThumbUpOutline';

export default {
	name: "VideoCard",
	props: {
		video: Object,
		libraryName: String,
		isPremium: Boolean
	},
	components: {
		ThumbUpOutline
	},
	data: function () {
		return {
			filterQuery: ''
		}
	},
	methods: {
		openVideo: function (video) {
			this.$emit('handleVideoClick')

			if (!this.isPremium) {
				this.$router.push({ name: 'video', params: { video: video, libraryName: this.libraryName } });
			}
		},
		secondsToHours(seconds) {
			const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
			const remainingSeconds = seconds % 3600;
			const minutes = Math.floor(remainingSeconds / 60).toString().padStart(2, '0');
			const remainingSecondsFinal = (remainingSeconds % 60).toString().padStart(2, '0');
			return `${hours}:${minutes}:${remainingSecondsFinal}`;
		}
	},
}
</script>

<style scoped lang="scss">
.card h4 {
	font-size: 16px;
}

.card {
	cursor: pointer;
}

.video-footer__left {
	font-size: 14px;
	span {
		margin-right: 8px !important;
		svg {
			height: 21px !important;
			width: 21px !important;
		}
	}
}

.video-footer__right {
	font-size: 14px;
	span {
		margin-right: 8px !important;
		margin-bottom: 0 !important;
		svg {
			height: 21px !important;
			width: 21px !important;
		}
	}
}

.video-timage {
	background-color: $blue-300;
	color: white;
	padding: 4px 6px;
	border-radius: 8px 0 8px 0;

	font-size: 12px;

	position: absolute;
	bottom: 0;
	right: 0;
}

.video-footer {
	display: flex;
	align-items: center;
	// justify-content: space-between;
	justify-content: flex-end;

	padding: 0 !important;

	font-size: 12px;
	color: $blue-300;

	span {
		height: 18px;
		width: 18px;

		margin-bottom: 4px;
		margin-right: 4px;

		svg {
			height: 18px;
			width: 18px;
		}
	}
}

.video-title__container {
	height: 30px;
	max-height: 30px;
	margin-bottom: 14px !important;
}

.video-title {
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 16px;
	line-height: 1.2;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.video-card__container {
	flex: 24.5%;

	@media (max-width: 768px) {
		flex: 100%;
	}

	@media (max-width: 1024px) {
		flex: 48%;
	}
}

.video-card {
	max-width: 24.5%;
	margin-bottom: 40px;

	@media (max-width: 768px) {
		max-width: 100%;
	}

	@media (max-width: 1024px) {
		max-width: 48%;
	}

	.video-thumbnail__container {
		position: relative;
		padding: 0;
	}

	.video-thumbnail__curtain {
		position: absolute;
		top: 0;
		left: 0;

		cursor: pointer;

		opacity: 0;
		transition: opacity 200ms ease-out;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;
		border-radius: 12px !important;
		background-color: rgba(0, 0, 0, .5);
	}

	&:hover {
		.video-thumbnail__curtain {
			opacity: 1;
			transition: opacity 200ms ease-out;
		}
	}
}

.video-thumbnail {
	height: 100%;

	border-radius: 12px !important;
}</style>