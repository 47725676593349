<template>
    <div class="settings" v-if="settings">
        <b-row >
            <b-col md="6" class="mt-4">
                <b-card header="Dashboard Customisation">
                    <b-form-group description="Choose whether to show additional features, or keep your dashboard down to basics.">
                        <template #label>
                            Dashboard Mode
                        </template>
                        <div class="d-flex">
                            <span class="mr-2">
                                Basic mode
                            </span>
                            <b-form-checkbox v-model="settings.allFeatures" switch>
                                Advanced Mode
                            </b-form-checkbox>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox v-model="settings.welcomeVideo" switch>Welcome Video</b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Dashboard Display Options" description="Decide what to show on your Dashboard Overview page.">
                        <b-form-checkbox v-model="settings.dashboardWidgets.cpdCategories" switch>Top CPD Categories Graph</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.activityTypes" switch>Activity Types Graph</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.entriesGraph" switch>Entries Over the Last 12 Months Graph</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.governingBodyStandards" switch>Governing Body Standards Graph <small>(if applicable to your governing body)</small></b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.sharedCPD" switch v-requires-feature="'SharedCPD'">Recent Shared CPD</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.calendar" switch>Upcoming Events</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.webinars" switch v-requires-feature="'Webinars'">Upcoming Webinars</b-form-checkbox>
                        <b-form-checkbox v-model="settings.dashboardWidgets.clinicalSkills" switch>Clinical Skills</b-form-checkbox>
                    </b-form-group>


                </b-card>
            </b-col>
            <b-col md="6" class="mt-4">
                <b-card header="Security" class="mb-4">
                    <b-form-group
                            id="fieldset-1"
                            description="Log out automatically after 15 minutes of no activity."
                            label="Logout after Inactivity">
                        <b-form-checkbox v-model="settings.logoutTimer.enabled" switch>Enable</b-form-checkbox>
                    </b-form-group>
                </b-card>
                <b-card header="Entries Customisation" class="mt-4">
                    <b-form-group
                            label="Diary Entry Options" description="Choose which features to show on your Diary Entry Form.">
                        <b-form-checkbox v-model="settings.entriesSettings.showKSF" switch>Allow Mapping to KSF Standards <small>(if applicable to governing body selected)</small></b-form-checkbox>
                        <b-form-checkbox v-model="settings.entriesSettings.showACP" switch>Allow Mapping to Four Pillars of Advanced Clinical / Professional Practice <small>(if applicable to governing body selected)</small></b-form-checkbox>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col md="6" class="mt-4">
                <b-card header="Notification Options">
                    <b-form-group label="Emails" description="Choose which emails you'd like to receive.">
                        <b-form-checkbox v-model="settings.emails.entryReceipt" switch>Entry Receipt <small>Sent after you submit a new entry.</small></b-form-checkbox>
                        <b-form-checkbox v-model="settings.emails.plannedCPD" switch>Planned CPD <small>A reminder if you submit an entry for the future.</small></b-form-checkbox>
                        <!-- <b-form-checkbox v-model="settings.emails.monthlyReport" switch>Monthly Report <small>Monthly digest of your CPD activity.</small></b-form-checkbox>
                        <b-form-checkbox v-model="settings.emails.quarterlyReport" switch>Quarterly Report <small>Quarterly digest of your CPD activity.</small></b-form-checkbox> -->

                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col md="4" offset-md="4" offset-sm="0" sm="12">
                <b-button block variant="success" :disabled="loading" @click="saveSettings"><content-save-icon /> Save Settings</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import ContentSaveIcon from "vue-material-design-icons/ContentSave";
    import EventBus from "../../event-bus";
    export default {
        name: "Settings",
        components: {ContentSaveIcon},
        data() {
            return {
                loading: false,
                settings: null,
            }
        },
        methods: {
            loadSettings() {
                this.loading = true;
                this.$http.get('settings/dashboard').then((resp) => {
                    this.settings = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load settings', resp.body);
                }).finally(() => {
                    this.loading = false;
                })
            },
            saveSettings() {
                this.loading = true;
                this.$http.post('settings/dashboard', this.settings).then(() => {
                    EventBus.$emit('settings-updated');
                    this.$router.push({name: 'dashboard-home'});
                    this.$success('Settings Saved', 'Your settings have been saved successfully');
                }).catch((resp) => {
                    this.$httpError('Failed to save settings', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadSettings();
        }
    }
</script>

<style>
.settings fieldset legend {
    font-weight: bold;
}
</style>
