import Vue from 'vue'
import Vuex from 'vuex'
import authentication from "./modules/authentication";
import settings from "./modules/settings";
import features from './modules/features';
import hcpcPortal from './modules/hcpc-portal';
import session from './modules/session';
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'data',
    storage: window.localStorage,
    modules: ['authentication', 'settings', 'features', 'hcpcPortal']
});

export default new Vuex.Store({
    modules: {
        authentication,
        settings,
        features,
        hcpcPortal,
        session
    },
    state: {

    },
    mutations: {

    },
    actions: {

    },
    plugins: [vuexLocal.plugin]
})
