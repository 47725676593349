<template>
    <div class="upgrade-container">
        <b-modal ref="upgradeModal" size="md" title="Upgrade Your Account" header-bg-variant="primary" header-text-variant="light" body-bg-variant="primary" body-text-variant="light" no-close-on-backdrop no-close-on-esc >

        <p v-if="reachedLimits" style="color: white;">You have reached your entries and/or storage limit. As a Basic member, you are allowed up to 5 entries.</p>
        <p v-if="isLockedPage" style="color: white;">To unlock this feature, upgrade your account and become a Premium member.</p>
        <p v-else style="color: white;">Upgrade your account and become a Premium member.</p>
        <p style="color: white;"> This will give you full, unlimited access to CPDme and will unlock fantastic features
        including the ability to record reflections and generate your bespoke portfolio. </p>
        <div slot="modal-footer" style="width:100%; text-align: center;">
            <b-row style="width: 100%;">
                <b-col cols="6" >
                    <b-button class="pb-3 pt-3 pr-4 pl-4" variant="info" size="md" @click="upgradeMonthly">Monthly<br /> membership <br/>£2.99<br><small style="color: white;">per month</small></b-button>
                </b-col>
                <b-col cols="6">
                    <b-button class="pb-3 pt-3 pr-4 pl-4" variant="info" size="md" @click="upgradeYearly">Yearly<br /> membership <br/>£24.99<br><small style="color: white;">per year</small></b-button>
                </b-col>
            </b-row>
        </div>

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "UpgradeModal",
        props: {
            reachedLimits: Boolean,
            isLockedPage: {
                type: Boolean,
                defaultValue: false
            }
        },
        methods: {
            show() {
                this.$refs.upgradeModal.show();
            },
            close() {
                this.$refs.upgradeModal.hide();
            },
            upgradeYearly() {
                window.open('https://pay.gocardless.com/AL0009FCR4M3J0', '_blank');
            },
            upgradeMonthly() {
                window.open('https://pay.gocardless.com/AL0009FCS98227', '_blank');
            }
        }
    }
</script>

<style scoped>
.modal-footer {
    width: 100%;
    background-color: white;
    margin: 0;
}
</style>
