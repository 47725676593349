<template>
    <div class="image-editor-modal">

        <b-modal class="image-modal" :title="modalTitle" ref="modal" size="lg">

            <Pulse v-if="loading" color="#999999"/>
            <img v-if="image" :src="imageUrl" @load="imageLoaded" />

        </b-modal>

    </div>
</template>

<script>
    import Pulse from "./loaders/Pulse";

    export default {
        name: "ImageEditorModal",
        components: {Pulse},
        data: function() {
            return {
                image: null,
                loading: false,
                apiRoot: '',
                loginToken: ''
            }
        },
        computed: {
          modalTitle() {
              if (this.image) {
                  return this.image.name;
              }
              return 'Edit';
          },
          imageUrl() {
              return `${this.apiRoot}file/${this.image.id}`
          }
        },
        methods: {
            show(image) {
                if (this.image !== image) {
                    this.loading = true;
                }
                this.image = image;
                this.$refs.modal.show();
            },
            imageLoaded() {
                this.loading = false;
            }
        },
        created() {
            this.loginToken = this.$store.state.authentication.loginToken;
            this.apiRoot = process.env.VUE_APP_API_ROOT
        }
    }
</script>

<style scoped>
    .image-modal {
        text-align: center;
    }
img {
    max-width: 600px;
}
</style>