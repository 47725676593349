<template>
    <b-overlay :show="loading">
        <div class="video-background" >
            <FullScreenVideo
                ref="videoPlayer"
                v-if="!isMobile && url"
                :url="url"
                @stop="videoStopped"
                :loop="false"
                align="crop"
                style="top: 0 !important;" />

            <div class="skip-container" v-if="!loading">
                <b-form inline style="float: right; margin-left: auto; margin-right: auto">
                    <b-form-checkbox v-model="skipInFuture" class="mr-4">Skip this Video in future</b-form-checkbox>
                    <b-button type="primary" @click.prevent="stopVideoAndContinue">Continue to Dashboard</b-button>
                </b-form>

            </div>

        </div>
    </b-overlay>
</template>

<script>

import FullScreenVideo from "@/components/common/FullScreenVideo";

export default {
    name: "Welcome",
    components: {FullScreenVideo},
    data() {
        return {
            browserWidth: 0,
            skipInFuture: false,
            url: '',
            loading: false,
        }
    },
    computed: {
        isMobile() {
            return this.browserWidth < 800;
        },
    },
    methods: {
        stopVideoAndContinue() {
            this.$refs.videoPlayer.stop();
        },
        videoStopped() {
            if (this.skipInFuture) {
                this.$http.get('settings/dashboard').then((resp) => {
                    let settings = resp.body;
                    settings.welcomeVideo = false;
                    return this.$http.post('settings/dashboard', settings);
                })
                .catch(() => {

                })
                .finally(() => {
                    this.$router.push({name: 'dashboard-home'});
                });
            } else {
                this.$router.push({name: 'dashboard-home'});
            }
        },
        handleResize() {
            this.browserWidth = window.innerWidth;
        },
        loadVideoUrl() {
            this.loading = true;
            this.$http.get('branding/welcome-video').then((resp) => {
                if (resp.body.videoUrl) {
                    this.url = resp.body.videoUrl;
                } else {
                    this.videoStopped();
                }
            }).catch(() => {
                this.videoStopped();
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        if (this.isMobile) {
            this.videoStopped();
        } else {
            this.loadVideoUrl();
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>

<style scoped>
.video-background {
    background-color: #222222;
    height: 100vh;
}

.skip-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: lightgrey;
    background-color: rgba(230, 230, 230, 0.4);
    align-content: center;
    text-align: center;
    padding: 10px;
    padding-right: 100px;

}


</style>
