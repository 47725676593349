<template>
    <div class="statistics">
        <b-row class="mb-4">
            <b-col cols="6" sm="6" xl="3" class="no-select mb-2"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Hours Logged" class="time-logged__tour" icon="clock" :value="displayValue(statistics.hoursLogged)" :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-2"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Total Entries" class="total-entries__tour" icon="entries" :value="displayValue(statistics.totalEntries)"
                        :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-2"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Diary Entries" class="diary-entries__tour" icon="diary-entries" :value="displayValue(statistics.diaryEntries)"
                        :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-2"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Reflections" class="reflections-tour" icon="reflections" :value="displayValue(statistics.reflectiveEntries)"
                        :loading="loading" />
                </a></b-col>
        </b-row>
    </div>
</template>

<script>
import TopStatistic from './TopStatistic'
export default {
    name: "TopStatistics",
    props: ['period'],
    components: {
        TopStatistic
    },
    methods: {
        updateStatistics: function () {
            this.loading = true;
            this.$http.get(`statistics/${this.period}`).then(response => {
                this.statistics = response.data;
                this.failed = false;
            }).catch((resp) => {
                this.$httpError('Failed to load your statistics', resp);
                this.failed = true;
            }).then(() => {
                this.loading = false;
            });
        },
        displayValue: function (val) {
            if (this.failed) {
                return '--';
            }
            return val;
        },
        goToEntries() {
            this.$router.push({ name: 'entries' });
        }
    },
    data: function () {
        return {
            statistics: {
                hoursLogged: 0,
                totalEntries: 0,
                diaryEntries: 0,
                reflectiveEntries: 0
            },
            loading: true,
            failed: false
        }
    },
    watch: {
        period: function (newValue) {
            this.period = newValue;
            this.updateStatistics();
        }
    },
    created: function () {
        this.updateStatistics();
    }
}
</script>

<style scoped>a.stat-link,
a.stat-link:hover {
    text-decoration: none;
}


</style>