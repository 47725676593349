<template>
	<div class="period-selector">
		<b-modal @ok="confirmDateRange" :ok-disabled="!dateRange.from || !dateRange.to" ref="dateRangeModal" size="md" :title="title" header-bg-variant="primary" header-text-variant="light"
			no-close-on-backdrop no-close-on-esc>
			<b-form-group label="From">
					<b-form-datepicker v-model="dateRange.from" />
			</b-form-group>

			<b-form-group label="To">
					<b-form-datepicker v-model="dateRange.to" />
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>

export default {
    name: "DateRangeModal",
    props: {
        title: "",
    },
    data() {
        return {
            dateRange: {
                from: "",
                to: "",
          }
        }
    },
    methods: {
        confirmDateRange() {
            this.$emit("dateRangeConfirmed", this.dateRange);
        },
        show() {
            this.$refs.dateRangeModal.show();
        }
    }
}


</script>