<template>
  <div @click="$emit('eventClicked', event)" role="button" class="calendar-event" :style="{borderLeft: `3px solid ${event.borderColor}`}">
    <div class="calendar-event__left">
      <div class="calendar-event__left-icon">
        <calendar-icon />
      </div>
      <div class="calendar-event__left-text">
        {{ event.allDay ? getAllDayEvent(event) : getDate(event.start) }}
      </div>
    </div>
    <div class="calendar-event__right">
      {{ event.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarEvent',
  props: {
    event: Object
  },
  methods: {
    getAllDayEvent(event) {
      const parsedDate = this.moment(event.start);
      const formatted = parsedDate.format('D. MM. YYYY');

      return `${formatted} all-day`
    },
    getDate(start) {
      const parsedDate = this.moment(start);
      const formatted = parsedDate.format('D. MM. YYYY h:mma');

      return formatted;
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-event {
  height: auto;
  margin: 2px 0;

  display: flex;
  align-items: center;
  padding: 8px;

  z-index: 1000;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #f5f5f5;
    transition: all 200ms ease-in-out;
  }
}

.calendar-event__left {
  width: 40%;

  font-size: 14px;
  color: #8ba7b8;

  display: flex;
  flex-wrap: wrap;
}

.calendar-event__left-icon {
  display: flex;
  align-items: center;
}

.calendar-event__left-text {
  width: 65%;
  margin-left: 8px;
  font-weight: 600;
}

.calendar-event__right {
  width: 100%;
}
</style>
