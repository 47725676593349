<template>
    <div class="clinical-skills-overview">
        <b-card header="Clinical Skills"  class="clinical-skills-card" header-bg-variant="white" header-class="custom-card__header">
            <PulseLoader v-if="this.loading"></PulseLoader>
            <div class="clinical-skills" v-else>
                <div class="not-connected" v-if="!linked">
                    <CloudOffOutline /> Not connected. <a href="#" @click.prevent="linkAccount">Link account</a>
                </div>
                <div class="statistics" v-else>
                    <b-row class="stat-row">
                        <b-col cols="12" class="stat-title">
                            <p>SKILLS</p>
                        </b-col>
                        <b-col cols="6" class="stat">
                            <h3>{{statistics.past7DaysSkills}}</h3>
                            <small>Past 7 days</small>
                        </b-col>
                        <b-col cols="6" class="stat">
                            <h3>{{statistics.past30DaysSkills}}</h3>
                            <small>Past 30 days</small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="stat-title">
                            <p>SHIFTS</p>
                        </b-col>
                        <b-col cols="6" class="stat">
                            <h3>{{statistics.past7DaysShifts}}</h3>
                            <small>Past 7 days</small>
                        </b-col>
                        <b-col cols="6" class="stat">
                            <h3>{{statistics.past30DaysShifts}}</h3>
                            <small>Past 30 days</small>
                        </b-col>
                    </b-row>
                    <b-button class="mt-2" href="https://clinicalskillslog.co.uk/dashboard" target="_blank" variant="outline-info" size="sm">Visit Dashboard</b-button>

                </div>
            </div>
        </b-card>
        <ClinicalSkillsLinkAccountModal ref="modal" @clinical-skills-linked="refreshLinkStatus"/>
    </div>
</template>

<script>

    import PulseLoader from "../../components/loaders/Pulse";
    import CloudOffOutline from "vue-material-design-icons/CloudOffOutline"
    import ClinicalSkillsLinkAccountModal from "./ClinicalSkillsLinkAccountModal";

    export default {
        name: "ClinicalSkillsOverview",
        components: {ClinicalSkillsLinkAccountModal, PulseLoader, CloudOffOutline},
        data() {
            return {
                loading: false,
                statistics: {},
                linked: false
            }
        },
        methods: {
            linkAccount() {
                this.$refs.modal.show();
            },
            getStatistics() {
                this.loading = true;
                this.$http.get('clinical-skills/statistics').then((resp) => {
                    this.statistics = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to get Clinical Skills statistics', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            refreshLinkStatus() {
                this.loading = true;
                this.$http.get('clinical-skills/auth/link').then((resp) => {
                    if (resp.status === 200) {
                        this.linked = true;
                        this.getStatistics();
                    } else {
                        this.linked = false;
                        this.loading = false;
                    }
                }).catch((resp) => {
                    if (resp.status === 404) {
                        this.linked = false;
                        this.loading = false;
                    } else {
                        this.$httpError('Failed to get link status', resp);
                    }
                });
            }
        },
        created() {
            this.refreshLinkStatus();
        }
    }
</script>

<style scoped>
    .stat {
        text-align: center;
        padding-bottom: 20px;
    }

    .stat-title {
        text-align: center;
        font-weight: bold;
    }

    .stat h3 {
        margin-bottom: 0;
    }

    .stat small {
        margin-top: 0;
    }
</style>