<template>
    <div class="annual-reviews">
        <b-overlay :show="loading">
            <b-alert variant="primary" :show="reviews.length === 0">No reviews have been recorded yet</b-alert>
            <PreviousReview v-for="(review, i) in reviews" :review="review" v-bind:key="i" />
        </b-overlay>
    </div>
</template>

<script>
    import PreviousReview from "./PreviousReview";

    export default {
        name: "AnnualReviews",
        components: {PreviousReview},
        data() {
            return {
                reviews: [],
                loading: false,
            }
        },
        methods: {
            loadReviews() {
                this.loading = true;
                this.$http.get('organisations/reviews').then((resp) => {
                    this.reviews = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load Annual Reviews', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadReviews();
        }
    }
</script>

<style scoped>

</style>