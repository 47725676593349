<template>
    <b-card header="ESHI Activity" style="background-color: white;">
        <PulseLoader loading v-if="loading" />
        <div class="generic-graph" v-if="!loading && graphData">
            <PieGraph :height="graphHeight" :chart-data="graphData" v-if="graphData.datasets[0].data.length > 0" />
            <b-alert variant="info" v-else show>
                No entries within the specified period.
            </b-alert>
        </div>
    </b-card>
</template>

<script>
import Providers from '../../providers';
import PieGraph from "@/components/graphs/PieGraph";
import PulseLoader from "@/components/loaders/Pulse";

export default {
    name: "EFIChartESHIDashboardWidget",
    components: {PieGraph, PulseLoader},
    props: {
        period: {
            type: String,
            required: true,
        },
        graphHeight: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            graphData: null,
            loading: false,
            pieColors: [
                '#3D99C2',
                '#e1974c',
                '#84ba5b',
                '#d35e60',
                '#808585',
                '#9067a7',
                '#ab6857',
                '#ccc210'
            ],
        }
    },
    watch: {
        period() {
            this.fetchData();
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            Providers.graphs.customPieChart('diary', 'FieldESHIActivity', this.period).then((resp) => {
                let data = resp.data.data;

                let cpdDataPoints = [];
                let cpdCategories = [];
                for (let i = 0; i < data.length; i++) {
                    cpdDataPoints.push(data[i].value);
                    cpdCategories.push(data[i].category);
                }

                this.graphData = {
                    datasets: [
                        {
                            data: cpdDataPoints,
                            backgroundColor: this.pieColors
                        }
                    ],
                    labels: cpdCategories
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    created() {
        this.fetchData();
    }
}
</script>

<style scoped>

</style>
