<template>
	<div class="calendar">
		<div v-if="!isLoading">
			<div class="d-flex w-100 justify-content-between" v-if="showAddEvent">
				<ShowTourButton class="mr-2" @handleClick="$refs.calendarKey.$refs.calendarTourRef.handleOpen()" />
	
				<b-button :to="{name: 'create/diary'}" class="ovr-button add-new__calendar-event mb-2">
					<span class="mr-1">
						<PlusIcon />
					</span>
	
					Create Diary Entry
				</b-button>
			</div>
			<hr class="horizontal-line mb-4">
	
			<FullCalendar :plugins="calendarPlugins" :header="calendarHeader" :events="calendarEvents" :defaultView="defaultView"
				@eventClick="eventClicked" :aspect-ratio="aspectRatio" :selectable="true" :event-time-format="eventTimeFormat" :now-indicator="true" />
	
			<CalendarDashboard v-if="mini" @eventClicked="eventClicked" :events="calendarEvents" />
	
			<CalendarKey ref="calendarKey" v-if="showKey" :items="key" />
			<CalendarDetailsModal ref="calendarDetailsModal" @event-updated="loadData" />
			<CalendarEventDetailsModal ref="calendarEventDetailsModal" />
		</div>
		<div v-else class="mt-4 mb-4">
			<Pulse loading v-if="isLoading" class="loader" color="#999999"></Pulse>
		</div>
	</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction';
import CalendarDetailsModal from "./CalendarDetailsModal";
import CalendarKey from "./CalendarKey";
import store from "../../store";
import CalendarEventDetailsModal from "./CalendarEventDetailsModal";
import CalendarDashboard from "./CalendarDashboard"
import ShowTourButton from '../common/ShowTourButton';
import Pulse from "../loaders/Pulse"

import PlusIcon from "vue-material-design-icons/Plus";

export default {
	name: "CalendarComponent",
	components: {
		CalendarEventDetailsModal,
		CalendarDetailsModal,
		FullCalendar,
		Pulse,
		CalendarKey,
		CalendarDashboard,
		PlusIcon,
		ShowTourButton
	},
	props: {
		mini: {
			type: Boolean,
			defaultValue: false
		},
		aspectRatio: {
			type: Number,
			defaultValue: 2
		},
		showKey: {
			type: Boolean,
			defaultValue: true
		},
		showAddEvent: {
			type: Boolean,
			defaultValue: false
		}
	},
	data: function () {
		return {
			isLoading: true,
			eventTimeFormat:{
				hour: 'numeric',
				minute: '2-digit',
				meridiem: 'short'
			},
			webinars: [],
			plannedCPD: [],
			entries: [],
			events: [],
			key: [
				{
					title: 'Webinars',
					colour: '#59c31f'
				},
				{
					title: 'Registered Webinars',
					colour: '#c35018'
				},
				{
					title: 'Planned CPD',
					colour: '#1083ca'
				},
				{
					title: 'CPD Entries',
					colour: '#4a1aca'
				},
				{
					title: 'Events',
					colour: '#9f1fc3'
				},
				{
					title: 'Registered Events',
					colour: '#2bc281'
				}
			]
		}
	},
	computed: {
		calendarPlugins() {
			let plugins = [interactionPlugin, listPlugin];
			if (!this.mini) {
				plugins.push(dayGridPlugin, timeGridPlugin);
			}
			return plugins;
		},
		defaultView() {
			return this.mini ? '' : 'dayGridMonth';
		},
		calendarHeader() {
			if (this.mini) {
				return false;
			} else {
				return {
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,listWeek'
				}
			}
		},
		calendarEvents() {
			let userEvents = this.plannedCPDToEvents(this.plannedCPD);
			let webinarEvents = [];
			if (store.getters['features/getFeatures'].includes('Webinars')) {
				webinarEvents = this.webinarsToEvents(this.webinars);
			}
			let registeredWebinarEvents = this.registeredWebinarsToEvents(this.webinars);
			let entriesEvents = this.entries.filter(e => e.startDate != null).filter(e => e.startDate < new Date()).map(e => this.entryToEvent(e));
			let eventEvents = this.eventsToEvents(this.events);
			let registeredEventEvents = this.registeredEventsToEvents(this.events);
			return [].concat(userEvents)
				.concat(webinarEvents)
				.concat(registeredWebinarEvents)
				.concat(entriesEvents)
				.concat(eventEvents)
				.concat(registeredEventEvents);
		}
	},
	methods: {
		eventClicked(data) {
			let event = data.event
			if (this.mini) {
				event = data
			}
			let eventType = event.extendedProps.type;
			if (eventType === 'Webinar') {
				this.$refs.calendarDetailsModal.show(event);
			} else if (eventType === 'Planned CPD' || eventType === 'CPD Entry') {
				let routeName = 'edit/' + event.extendedProps.entryType.toLowerCase();
				this.$router.push({ name: routeName, params: { id: event.extendedProps.entryId } });
			} else if (eventType === 'Event') {
				this.$refs.calendarEventDetailsModal.show(event.extendedProps.event);
			}
		},
		loadWebinars() {
			this.$http.get('webinars').then((webinars) => {
				this.webinars = webinars.body.map(webinar => {
					webinar.startDate = new Date(webinar.startDate);
					webinar.endDate = new Date(webinar.endDate);
					return webinar;
				});
			})
		},
		loadEvents() {
			this.$http.get('calendar/events').then((resp) => {
				this.events = resp.body.map(event => {
					event.startDate = new Date(event.happensAt);
					event.endDate = new Date(event.endsAt);
					return event;
				});
			})
		},
		loadPlannedCPD() {
			this.$http.get('planned-cpd').then((plannedCPD) => {
				this.plannedCPD = plannedCPD.body.map(plannedCPD => {
					plannedCPD.startDate = new Date(plannedCPD.startDate);
					return plannedCPD;
				});
			})
		},
		loadPreviousEntries() {
			this.$http.get('entries/metadata').then((resp) => {
				this.entries = resp.body.map(entry => {
					if (entry.startDate != null) {
						entry.startDate = new Date(entry.startDate);
					}
					return entry;
				});
			})
		},
		webinarsToEvents(webinars) {
			return webinars.filter(w => !w.registered).map(w => this.webinarToEvent(w, '#59c31f', '#4ca61b'));
		},
		registeredWebinarsToEvents(webinars) {
			return webinars.filter(w => w.registered).map(w => this.webinarToEvent(w, '#c35018', '#ab4916'));
		},
		webinarToEvent(webinar, backgroundColour, borderColour) {
			return {
				title: webinar.title,
				start: webinar.startDate,
				end: webinar.endDate,
				backgroundColor: backgroundColour,
				borderColor: borderColour,
				textColor: 'white',
				extendedProps: {
					type: 'Webinar',
					id: webinar.id,
					description: webinar.webinarDescription,
					webinar: webinar
				}
			}
		},
		eventsToEvents(events) {
			return events.filter(e => !e.registered).map(e => this.eventToEvent(e, '#9f1fc3', '#881ba6'));
		},
		registeredEventsToEvents(events) {
			return events.filter(e => e.registered).map(e => this.eventToEvent(e, '#2bc281', '#1ba649'));
		},
		eventToEvent(event, backgroundColour, borderColour) {
			return {
				title: event.title,
				start: event.startDate,
				end: event.endDate,
				backgroundColor: backgroundColour,
				borderColor: borderColour,
				textColor: 'white',
				extendedProps: {
					type: 'Event',
					event: event
				}
			}
		},
		plannedCPDToEvents(plannedCPD) {
			return plannedCPD.map(p => this.plannedCPDToEvent(p));
		},
		plannedCPDToEvent(plannedCPD) {
			return {
				title: `${plannedCPD.entryTitle}`,
				start: plannedCPD.startDate,
				// end: plannedCPD.startDate,
				allDay: true,
				backgroundColor: '#1083ca',
				borderColor: '#0d69a3',
				textColor: 'white',
				extendedProps: {
					type: 'Planned CPD',
					entryId: plannedCPD.entryId,
					entryTitle: plannedCPD.entryTitle,
					entryType: plannedCPD.entryType
				}
			}
		},
		entryToEvent(entry) {
			return {
				title: `${entry.title}`,
				start: entry.startDate,
				allDay: true,
				backgroundColor: '#4a1aca',
				borderColor: '#39149c',
				textColor: 'white',
				extendedProps: {
					type: 'CPD Entry',
					entryId: entry.entryId,
					entryTitle: entry.title,
					entryType: entry.type
				}
			}
		},
		async loadData() {
			try {
				this.loadWebinars();
				this.loadPlannedCPD();
				this.loadPreviousEntries();
				this.loadEvents();
			} catch (e) {
				console.error(e)
			} finally {
				this.isLoading = false
			}
		}
	},
	created() {
		this.loadData();
	}

}
</script>

<style>
.fc-event,
.fc-list-item {
	cursor: pointer;
}
</style>

<style scoped lang="scss">
.fc-event,
.fc-list-item {
	cursor: pointer;
}

.add-new__calendar-event {
	display: flex;
	align-items: center;
	width: fit-content;
}

.horizontal-line {
	background-color: $border-color;
}
</style>
