<template>
    <b-modal ref="verifyContactDetailsModal" :title="title" header-bg-variant="primary" header-text-variant="light" ok-title="Verify" cancel-title="Close"
             :ok-disabled="loading || !isValidCode" :cancel-disabled="loading" size="md" @ok.prevent="verify">
       <p v-if="isMobile">
            Please enter the verification code that has been sent to you by SMS:<br/>
            <small>This code will expire in 15 minutes</small>
        </p> 
       <p v-else>
            Please enter the verification code that has been emailed to the alternative email address provided:<br/>
            <small>This code will expire in 15 minutes</small>
       </p> 
       <div style="width:100%; text-align:center">
        <PincodeInput ref="codeInput" v-model="verificationCode" class="ml-auto mr-auto" style="display: block" :length="6" autofocus/>
       </div>
    </b-modal>
</template>

<script>
import PincodeInput from 'vue-pincode-input';

export default {
    
    name: "VerifyContactDetailsModal",
    components: {
        PincodeInput
    },
    props: {
        verificationType: String
    },
    data() {
        return {
            loading: false,
            verificationCode: ""
        }
    },
    computed: {
        title() {
            return this.isMobile ? "Verify Mobile Number" : "Verify Alternative Email Address"
        },
        isMobile() {
            return this.verificationType === "mobile"
        },
        isValidCode() {
            return this.verificationCode.length === 6;
        }
    },
    methods: {
        show() {
            this.$refs.verifyContactDetailsModal.show();
            this.initiateVerificationRequest();

            // setTimeout(() => {
            //     const wrapper = this.$refs.codeInput.$el;
            //     const cells = wrapper.getElementsByClassName('vue-pincode-input');
            //     cells[0].focus(); 
            // }, 200);
             
        },
        initiateVerificationRequest() {
            this.loading = true;
            this.$hideToast();
            
            const url = this.isMobile ? 'profile/start-mobile-number-verification' : 'profile/start-alternative-email-verification';

            this.$http.post(url).then(() => {
                this.focusOnCodeInput()
            }).catch((resp) => {
                this.$httpError("Failed to start verification process", "Please try again");
                this.close();
            }).finally(() => {
                this.loading = false;
            })
        },
        focusOnCodeInput() {
            const wrapper = this.$refs.codeInput.$el;
            const cells = wrapper.getElementsByClassName('vue-pincode-input');
            cells[0].focus(); 
        },
        verify() {
            this.loading = true;
            this.$hideToast();

            const url = this.isMobile ? 'profile/complete-mobile-number-verification' : 'profile/complete-alternative-email-verification';
            const successTitle = this.isMobile ? 'Mobile Number Verified' : 'Alternative Email Address Verified';
            const successMessage = this.isMobile ? 'Your mobile number has been successfully verified' : 'Your alternative email address has been successfully verified';
            const errorTitle = this.isMobile ? 'Failed to verify mobile number' : 'Failed to verify alternative email address';

            this.$http.post(url, {verificationCode : this.verificationCode}).then(() => {
                this.$success(successTitle, successMessage);
                this.$emit("verified")
                this.close();
            }).catch((resp) => {
                this.$httpError(errorTitle, resp);
            }).finally(() => {
                this.loading = false;
                this.verificationCode = "";
            })
        },
        close() {
            this.$refs.verifyContactDetailsModal.hide();
        }
    }
}
</script>

<style scoped>

</style>
