<template>
    <div class="portal">
        <div class="header mt-4">
            <img src="@/assets/cpdme-logo.png" alt="CPDme" />
            <h2 class="mt-4">HCPC Portal</h2>
        </div>
        <div class="container-fluid mt-5">
            <b-row>
                <b-col cols="12" sm="8" xl="9" class="mb-4">
                    <b-card class="portal-card">
                        <wizard-progress :steps="steps">
                            <router-view></router-view>
                        </wizard-progress>
                    </b-card>
                </b-col>
                <b-col sm="4" xl="3">
                    <b-card bg-variant="info" header-bg-variant="info" header-text-variant="light" text-variant="light" header="Progress" header-class="text-center">
                        <h2 class="text-center">{{progress}}</h2>
                    </b-card>

                    <b-card class="mt-4" bg-variant="info" header-bg-variant="info" header-text-variant="light" text-variant="light" header="This Portal link will expire in" header-class="text-center">
                        <h2 class="text-center">{{remainingTime}}</h2>
                    </b-card>

                    <a href="#" @click.prevent="showGuideVideo" ><img class="mt-4" style="width: 100%;" src="../../assets/hcpc-portal-guide-video.png" alt="Watch guide video" /></a>
                </b-col>
            </b-row>
        </div>
        <Footer />
        <ModalGuideVideo ref="guideVideoModal" />
    </div>
</template>

<script>
    import WizardProgress from "../../components/common/WizardProgress";
    import Footer from "../../components/Footer";
    import Providers from '../../providers';
    import ModalGuideVideo from "@/components/hcpc-portal/ModalGuideVideo";

    export default {
        name: "Portal",
        components: {ModalGuideVideo, Footer, WizardProgress },
        computed: {
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            },
            remainingTime() {
                if (this.info) {
                    return this.moment(this.info.expiresAt).fromNow(true)
                }
                return '--';
            },
            progress() {
                let progress = 0;
                let completed = this.$store.state.hcpcPortal.completed;
                if (completed.statement) progress++;
                if (completed.activities) progress++;
                if (completed.summary) progress++;
                if (completed.evidence) progress++;
                return ((progress / 4) * 100) + "%";
            },
            steps() {
                return [
                    {
                        title: 'Summary',
                        routeName: 'hcpc/summary',
                        route: {name: 'hcpc/summary', params: {accessToken: this.accessToken}},
                        completedKey: 'summary'
                    },
                    {
                        title: 'Activities',
                        routeName: 'hcpc/activities',
                        route: {name: 'hcpc/activities', params: {accessToken: this.accessToken}},
                        completedKey: 'activities'
                    },
                    {
                        title: 'Statement',
                        routeName: 'hcpc/statement',
                        route: {name: 'hcpc/statement', params: {accessToken: this.accessToken}},
                        completedKey: 'statement'
                    },
                    {
                        title: 'Evidence',
                        routeName: 'hcpc/evidence',
                        route: {name: 'hcpc/evidence', params: {accessToken: this.accessToken}},
                        completedKey: 'evidence'
                    }
                ]
            }
        },
        data() {
            return {
                info: null,
            }
        },
        methods: {
            loadInfo() {
                Providers.hcpcPortal.info(this.accessToken).then((resp) => {
                    this.info = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load portal information', resp);
                });
            },
            showGuideVideo() {
                this.$refs.guideVideoModal.show();
            }
        },
        created() {
            this.loadInfo();
        },
    }
</script>

<style scoped>
    .portal-card {
        padding-left: 40px;
        padding-right: 40px;
    }

    .header {
        width: 100%;
        text-align: center;
    }

    .header img {
        max-width: 220px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>
