import Vue from 'vue';

export default {
    findMemberships(identifier, type, email) {
        return Vue.http.get(`admin/membership-management/search?identifier=${identifier}&type=${type}&email=${email}`);
    },
    createMembership(membership) {
        return Vue.http.post(`admin/membership-management/membership`, membership);
    },
    getMembership(membershipId) {
        return Vue.http.get(`admin/membership-management/membership/${membershipId}`)
    },
    updateMembership(membershipId, membership) {
        return Vue.http.put(`admin/membership-management/membership/${membershipId}`, membership);
    },
    importMembership(identifier, type, email) {
        return Vue.http.post('admin/membership-management/import', {type: type, identifier: identifier, email: email});
    },
}
