<script>
    import {Bar, mixins} from "vue-chartjs";

    const { reactiveProp } = mixins;

    export default {
        name: "BarGraph",
        extends: Bar,
        mixins: [reactiveProp],
        props: ['options'],
        methods: {
            render() {
                this.renderChart(this.chartData, this.options)
             }
        },
        mounted() {
            this.render()
        }
    }
</script>