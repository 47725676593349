<template>
	<div class="entries">
		<b-row class="mb-3">
			<b-col cols="12">
				<b-row>
					<b-col sm="12" lg="4">
						<ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
					</b-col>
				</b-row>
				<b-row class="align-items-center">
					<b-col sm="12">
						<h4 class="dashboard-pages__title mb-0">Summary of all your CPD Activities</h4>
					</b-col>
					<b-col sm="12" class="d-flex justify-content-start mt-2 mb-2">
						<b-form :inline="true" class="entries-form" @submit="doSearch">
							<div class="filters-entries d-flex">
								<b-form-group class="mb-0">
									<b-dropdown class="all-years__tour" toggle-class="ovr-button" :text="filterYearText" :disabled="isBusy">
										<b-dropdown-header>Select Year</b-dropdown-header>
										<b-dropdown-item v-for="year in yearValues" v-bind:key="year.value" @click="changeYear(year.value)">{{
											year.title }}</b-dropdown-item>
									</b-dropdown>
								</b-form-group>
	
								<b-form-group class="mb-0">
									<Dropdown toggle-class="ovr-button" tourClass="any-tag__tour" variant="dark" v-model="filter.tag" class="" :options="tagOptions" emptyTitle="Any Tag"
										:disabled="isBusy" />
								</b-form-group>
	
								<b-form-group class="mb-0">
									<Dropdown tourClass="type-entries__tour" toggle-class="ovr-button" variant="dark" v-model="filter.type" class="" :options="typeOptions"
									emptyTitle="Any Type" :disabled="isBusy" />
								</b-form-group>
	
								<b-form-group class="mb-0">
									<Dropdown tourClass="any-gbody__tour" toggle-class="ovr-button" variant="dark" v-model="filter.governingBody" class="" :options="governingBodyOptions"
										emptyTitle="Any Governing Body" :disabled="isBusy" />
								</b-form-group>
	
								<b-form-group class="mb-0">
									<Dropdown tourClass="any-category__tour" toggle-class="ovr-button" variant="dark" v-model="filter.category" class="" :options="categoryOptions"
										emptyTitle="Any Category" :disabled="isBusy" />
								</b-form-group>
	
								<b-form-group class="mb-0">
									<Dropdown tourClass="completeness-entries__tour" toggle-class="ovr-button" variant="dark" v-model="filter.completeness" class="" :options="completenessOptions"
										emptyTitle="CPD Health Check" :disabled="isBusy" />
								</b-form-group>
							</div>

							<div class="search-bar__entries">
								<SearchBar placeholder="Search..." :incomingValue="filter.title" @updateVal="(val) => filter.title = val"
									:readonly="isBusy" :disabled="isBusy" />
							</div>

						</b-form>
					</b-col>
				</b-row>
			</b-col>
		</b-row>

		<b-row v-if="!isLoading">
			<b-col sm="12" class="data-table__parent">
				<b-table :key="tableKey" details-td-class="test" class="data-table" thead-class="table-header" tbody-tr-class="table-body__tr" tbody-class="table-body"
					ref="entriesTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :busy.sync="isBusy" striped
					:items="entryProvider" :fields="fields" :per-page="perPage" :current-page="currentPage" :show-empty="!isBusy">
					<template #empty>
     					 <b-alert show style="text-align:center">No entries found</b-alert>
    				</template>
					<template v-slot:cell(render_completeness)="data">
						<div class="d-flex">
	
								<p v-b-tooltip.hover="completenessTooltip(data.item.completeness)" :class="'completeness-text__border mb-0 ml-2 completeness-text-' + data.item.completeness">
									{{ Math.round(data.item.completenessPercentage * 100) + "%" }}
								</p>
						</div>
					</template>
					<template v-slot:cell(title)="data">
						<p class="mb-0 entries-table__title">
							{{ data.item.title }}<br />
						</p>
						<b-badge variant="primary" pill v-for="tag in data.item.tags" v-bind:key="tag" class="mr-1">{{ tag }}</b-badge>
					</template>
					<template v-slot:cell(type)="data">
						<b-badge class="type-of__data-badge type-of__data-badge-color" variant="secondary">{{ uppercase(data.item.type) }}</b-badge>
					</template>
					<template v-slot:cell(evidence)="data">
						<div class="evidence-item" :key="e.url" v-for="e of data.item.evidence">
							<img v-if="e.thumbnailUrl && e.thumbnailUrl.length" :src="e.thumbnailUrl" alt="" class="evidence-thumbnail">
							<b-dropdown toggle-class="evidence-dropdown__menu-button" menu-class="evidence-dropdown__menu" size="xs"
								variant="outline-dark" class="mx-1 evidence-dropdown" right :text="truncateFilename(e.name)"
								:title="e.name">
								<b-dropdown-item @click="openEvidence(e)" target="_blank">Open</b-dropdown-item>
								<b-dropdown-item @click="openRenameEvidenceModal(e)">Rename</b-dropdown-item>
								<b-dropdown-item @click="deleteEvidence(data.item, e.id)" class="dropdown-danger">Delete</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
					<template v-slot:cell(actions)="data">
						<div class="actions">
							<b-button variant="white" role="button" @click="previewEntry(data.item.id)" class="entry-preview__tour text-secondary data-table__action ovr-button">
								<eye-outline-icon></eye-outline-icon> Preview</b-button>

							<b-button variant="white" role="button" @click="editEntry(data.item)" class="entry-edit__tour text-secondary data-table__action ovr-button"
								v-requires-role="'User-Basic'"><pencil-outline-icon></pencil-outline-icon> Edit</b-button>



							<b-button variant="success" role="button" v-if="data.item.type === 'diary' && shared(data.item)"
								:class="{ 'show-share__action': data.item.type === 'diary' && shared(data.item) }"
								v-b-tooltip.hover="'Click to Stop Sharing'" @click="stopSharing(data.item)"
								style="color: #fff !important"
								class="text-success data-table__action invisible p-0" v-requires-feature="'SharedCPD'">
								<ShareOutline /> Sharing
							</b-button>


							<b-button variant="white" role="button" v-else
								:class="[{ 'show-share__action': data.item.type === 'diary' && !shared(data.item) }, shared(data.item) ? 'text-success' : 'text-secondary', 'entry-share__tour']"
								@click="shareEntry(data.item)" class="data-table__action ovr-button invisible" v-requires-feature="'SharedCPD'">
								<ShareOutline /> Share
							</b-button>




							<b-button variant="danger" role="button" @click="deleteEntry(data.item)" class="text-danger entry-delete__tour ovr-button__delete data-table__action"
								v-requires-role="'User-Basic'"><delete-outline-icon></delete-outline-icon> Delete</b-button>

						</div>
					</template>
				</b-table>
			</b-col>
			<div class="entries-pagination w-100">
				<b-pagination id="v-step-1" pills v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true"
					prevText="Previous" nextText="Next" size="sm" :limit="10" v-if="!isLoading" :total-rows="totalRows"
					:per-page="perPage" v-model="currentPage" />
			</div>

			<b-modal id="entryDeleteModal" ref="entryDeleteModal" title="Delete Entry" :hide-header-close="true"
				:no-close-on-esc="true" :no-close-on-backdrop="true" header-bg-variant="danger" header-text-variant="light"
				size="lg" ok-variant="danger" ok-title="Delete Entry" @ok="doDeleteEntry">
				<p>Are you sure you want to delete the entry with title: </p>
				<p v-if="deleteEntryData.entry">"{{ deleteEntryData.entry.title }}"?</p>
				<small><b>Deletions are irreversible. Please make sure you don't need this entry before deleting.</b></small>
			</b-modal>

			<EvidenceRenameModal ref="EvidenceRenameModal" :evidence="evidence" @renameModal="handleRenameModal" />

			<b-modal id="evidenceDeleteModal" ref="evidenceDeleteModal" title="Delete Evidence" :hide-header-close="true"
				:no-close-on-esc="true" :no-close-on-backdrop="true" header-bg-variant="danger" header-text-variant="light"
				size="lg" ok-variant="danger" ok-title="Delete Evidence" @ok="doDeleteEvidence">
				<p>Are you sure you want to delete the following evidence: </p>
				<p v-if="deleteEvidenceData.evidence">"{{ deleteEvidenceData.evidence.name }}"?</p>
				<small><b>Deletions are irreversible. Please make sure you don't need this evidence before deleting.</b></small>
			</b-modal>

			<ImageEditorModal ref="imageEditorModal" />
			<ShareCPDModal ref="shareCPDModal" :share-callback="shareCPD" />
			<PreviewEntryModal ref="previewModal" />
			<PageTour ref="pageTourRef"
				description="Entries is a bit like the catalogue of all your CPD. The more entries that you create, the more exciting this section will become. From here you can search, filter, preview, edit, share and delete."
				@handleShowTour="handleShowTour"  />
			<v-tour name="entriesTour" :steps="steps" :options="{ highlight: true }"></v-tour>


		</b-row>
		<Pulse loading v-if="isBusy" class="loader" color="#999999"></Pulse>
	</div>
</template>

<script>
import Pulse from "../../components/loaders/Pulse";
import ImageEditorModal from "../../components/ImageEditorModal";
import ShareOutline from 'vue-material-design-icons/ShareOutline';
import ShareCPDModal from "../../components/shared-cpd/ShareCPDModal";
import PreviewEntryModal from "../../components/entries/PreviewEntryModal";
import EventBus from "../../event-bus";
import Dropdown from "@/components/common/Dropdown";
import SearchBar from "@/components/common/SearchBar";
import EvidenceRenameModal from "../../components/entries/EvidenceRenameModal";
import PageTour from "../../components/common/PageTour";
import ShowTourButton from "../../components/common/ShowTourButton";

export default {
	name: "Entries",
	components: { SearchBar, ShowTourButton, Dropdown, PreviewEntryModal, ShareCPDModal, ImageEditorModal, Pulse, ShareOutline, EvidenceRenameModal, PageTour },
	data: function () {
		return {
			isLoading: false,
			isBusy: false,
			perPage: 10,
			currentPage: 1,
			totalRows: 0,
			items: [],
			years: [],
			tags: [],
			governingBodies: [],
			categories: [],
			sortBy: 'date',
			sortDesc: true,
			evidence: null,
			tableKey: 0,
			filter: {
				title: '',
				year: '',
				tag: '',
				category: '',
				governingBody: '',
				type: '',
				completeness: ''
			},
			fields: [
				{
					key: 'render_completeness',
					label: '',
				},
				{
					key: 'date',
					label: 'Date',
					formatter: this.creationDateFormatter,
					sortable: true
				},
				{
					key: 'type',
					label: 'Type',
					sortable: true
				},
				{
					key: 'title',
					sortable: true,
				},
				{
					label: 'Evidence',
					key: 'evidence'
				},
				{
					label: 'Actions',
					key: 'actions'
				}
			],
			deleteEntryData: {
				entry: null
			},
			deleteEvidenceData: {
				entry: null,
				evidenceId: null
			},
		}
	},
	watch: {
		'filter.tag'() {
			this.refresh();
		},
		'filter.completeness'() {
			this.refresh();
		},
		'filter.type'() {
			this.refresh();
		},
		'filter.governingBody'() {
			this.refresh();
		},
		'filter.category'() {
			this.refresh();
		},
		'filter.title'() {
			if (!this.filter.title.length) {
				this.refresh();
			}
		}
	},
	computed: {
		steps() {
			let tourSteps = [];
			tourSteps.push({
				target: '.all-years__tour',
				content: `This allows you to select the years that you would like to filter your CPD entries. Imagine that it’s a bit like a digital A4 lever folder.`,
			});

			if (this.tags.length) {
				tourSteps.push({
					target: '.any-tag__tour',
					content: `This allows you to filter all your CPD by the different categories that you have mapped them across to.`,
				});
			}

			tourSteps.push({
				target: '.type-entries__tour',
				content: `This is where you can filter between diary or reflective entry submissions.`
			});

			if (this.governingBodies.length) {
				tourSteps.push({
					target: '.any-gbody__tour',
					content: `This feature is great if you use this simple platform to record your continuing professional development for more than one professional body or association.`
				});
			}

			if (this.categories.length) {
				tourSteps.push({
					target: '.any-category__tour',
					content: `This allows to filter all your CPD by the different categories that you have
mapped them across to.`
				});
			}

			tourSteps.push({
				target: '.completeness-entries__tour',
				content: `The completeness filter will divide your complete entries from the ones that need to be reviewed.`
			});

			tourSteps.push({
				target: '.search-tour',
				content: `You can search your entries by the title of the diary or reflective entry to help you quickly find previous submissions or planned CPD.`
			});
			tourSteps.push({
				target: '.entry-preview__tour',
				content: `This allows you to see your CPD in a print-ready format that you can print out or share as a PDF. 
				<br><br> It also allows you to preview the supporting evidence attached to the entry.`,
			});
			tourSteps.push({
				target: '.entry-edit__tour',
				content: `You can click here to edit the entry and continue to work on the submission.`,
			});
			tourSteps.push({
				target: '.entry-share__tour',
				content: `This is where you can share this specific entry to the public Shared CPD area.
				<br><br>
				This option may not be enabled for you if you belong to a company or organisation.,
				`
			});
			tourSteps.push({
				target: '.entry-delete__tour',
				content: `This is where you can delete the entry. Please note that this is irreversible and
cannot be recovered due to data guidelines. Consider if you really need to delete this entry.`,
			});

			return tourSteps;
		},
		filterYearText() {
			if (this.filter.year === '') {
				return 'All Years';
			}
			return this.filter.year;
		},
		yearValues() {

			let options = [
				{ title: 'All', value: '' },
			];
			if (this.years) {
				for (let y of this.years) {
					options.push({
						title: '' + y,
						value: '' + y
					})
				}
			}
			return options;
		},
		tagOptions() {
			return [{ value: '', title: 'Any Tag' }, ...this.tags.map(val => ({ value: val, title: val }))];
		},
		governingBodyOptions() {
			return [{ value: '', title: 'Any Governing Body' }, ...this.governingBodies.map(val => ({ value: val, title: val }))];
		},
		categoryOptions() {
			return [{ value: '', title: 'Any Category' }, ...this.categories.map(val => ({ value: val, title: val }))];
		},
		typeOptions() {
			return [{ value: '', title: 'Any Type' }, { value: 'diary', title: 'Diary' }, { value: 'reflection', title: 'Reflection' }];
		},
		completenessOptions() {
			return [{ value: '', title: 'All' }, { value: 'complete', title: 'Complete', color: 'green' }, { value: 'partial', title: 'Partially complete', color: 'amber' }, { value: 'incomplete', title: 'Incomplete', color: 'red' }];
		}
	},
	methods: {
		handleShowTour() {
			this.$tours['entriesTour'].start()
		},
		async handleRenameModal(evidence) {
			const { newFilename } = evidence
			this.$http.put(`file/${evidence.id}`, { newFilename }).then((res) => {
				this.$success('Evidence Renamed', 'The evidence has been renamed successfully')
				this.tableKey++; // For rerendering and showing new evidence name
				return res
			}).catch((resp) => {
				this.$httpError('Failed to load entries', resp);
			})
		},
		openRenameEvidenceModal(e) {
			this.evidence = e
			this.$refs.EvidenceRenameModal.handleOpenModal()
		},
		creationDateFormatter: function (val) {
			if (val != null && val.length === 10) {
				return this.moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
			}
			return val;
		},
		uppercase: function (text) {
			return this.$utilities.capitalise(text);
		},
		entryProvider: function (ctx, callback) {
			const completenessEnum = {
				complete: 1,
				partial: 2,
				incomplete: 3 
			}

			this.isBusy = true;
			let url = `entries/limit/${ctx.perPage}/page/${ctx.currentPage}?filter=${this.filter.title}&year=${this.filter.year}&tag=${encodeURIComponent(this.filter.tag)}&category=${this.filter.category}&governingBody=${this.filter.governingBody}&sortBy=${encodeURIComponent(ctx.sortBy)}&sortDesc=${encodeURIComponent(ctx.sortDesc)}${this.filter.type.length ? '&type=' + this.filter.type : ''}${this.filter.completeness.length ? '&completeness=' + completenessEnum[this.filter.completeness] : ''}`;
			this.$http.get(url).then(response => {
				callback(response.body.items);
				this.years = response.body.years || [];
				this.tags = response.body.tags || [];
				this.categories = response.body.categories || [];
				this.governingBodies = response.body.governingBodies || [];
				this.totalRows = response.body.total;
				this.isBusy = false;
			}).catch((resp) => {
				this.$httpError('Failed to load entries', resp);
			}).then(() => {
				this.isBusy = false;
			});

			// Must return null or undefined to signal b-table that callback is being used
			return null
		},
		previewEntry: function (entryId) {
			// let token = this.$store.state.authentication.loginToken;
			// window.open(this.apiRoot + 'entries/' + entryId + '/pdf?auth_token=' + token);
			this.$refs.previewModal.show(entryId);
		},
		editEntry: function (entry) {
			let routeName = 'edit/' + entry.type.toLowerCase();
			this.$router.push({ name: routeName, params: { id: entry.id } });
		},
		shareEntry: function (entry) {
			this.$refs.shareCPDModal.show(entry);
		},
		deleteEntry: function (entry) {
			this.deleteEntryData.entry = entry;
			this.$refs.entryDeleteModal.show();
		},
		doDeleteEntry: function (bvEvt) {
			bvEvt.preventDefault();
			this.$refs.entryDeleteModal.busy = true;
			this.$http.delete(`entries/${this.deleteEntryData.entry.id}`).then(() => {
				EventBus.$emit('entry-deleted');
				this.$refs.entryDeleteModal.hide();
				this.refresh();
			}).catch((resp) => {
				this.$httpError('Failed to delete entry', resp);
			}).then(() => {
				this.$refs.entryDeleteModal.busy = false;
			});
		},
		deleteEvidence: function (entry, evidenceId) {
			this.deleteEvidenceData = {
				entry: entry,
				evidenceId: evidenceId
			};
			this.$refs.evidenceDeleteModal.show();
		},
		doDeleteEvidence: function (bvEvt) {
			bvEvt.preventDefault();
			this.$refs.evidenceDeleteModal.busy = true;

			this.$http.delete(`entries/${this.deleteEvidenceData.entry.id}/file/${this.deleteEvidenceData.evidenceId}`).then(() => {

			}).catch((resp) => {
				this.$httpError('Failed to delete file', resp);
			}).then(() => {
				this.$refs.evidenceDeleteModal.busy = false;

				this.$refs.evidenceDeleteModal.hide();
				this.refresh();
			})
		},
		doSearch: function (e) {
			e.preventDefault();
			this.refresh();
		},
		refresh: function () {
			this.$refs.entriesTable.refresh();
		},
		truncateFilename: function (filename) {
			if (filename.length <= 30) {
				return filename;
			} else {
				return filename.substr(0, 17) + '...' + filename.substr(filename.length - 17, filename.length);
			}
		},
		changeYear: function (year) {
			this.filter.year = year;
			this.refresh();
		},
		openEvidence: function (e) {
			let fileName = e.name.toLowerCase();
			if (fileName.endsWith('.jpeg') || fileName.endsWith('.jpg') || fileName.endsWith('.png')) {
				this.$refs.imageEditorModal.show(e);
			} else {
				window.open(e.url, '_blank');
			}
		},
		shareCPD: function (sharedCPD, entryId) {
			this.$http.post(`shared-cpd?entryId=${entryId}`, sharedCPD).then((resp) => {
				this.$success('Shared Successfully', resp.body.message);
			}).catch((resp) => {
				this.$httpError('Failed to share CPD', resp);
			}).then(() => {
				this.refresh();
			})
		},
		stopSharing(entry) {
			this.$http.delete(`shared-cpd/${entry.shareId}`).then((resp) => {
				this.$success('Stopped Sharing', resp.body.message);
			}).catch((resp) => {
				this.$httpError('Failed to stop sharing', resp);
			}).then(() => {
				this.refresh();
			})

		},
		shared(entry) {
			return entry.shareId;

		},
		completenessTooltip(completeness) {
			switch (completeness) {
				case 1:
					return 'Entry is fully completed';
				case 2:
					return 'Entry is partially complete';
				case 3:
					return 'Entry is incomplete';
			}
		}
	},
	created: function () {
		this.apiRoot = process.env.VUE_APP_API_ROOT
	}
}
</script>

<style lang="scss">
.btn-xs {
	/*height: 20px;*/
	padding-top: 1px;
	font-size: 12px;
	padding-bottom: 1px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.evidence-dropdown {
	color: red;
}

.evidence-dropdown__menu {
	color: red;
}

.evidence-dropdown__menu-button {
	font-size: 13px !important;
	border-radius: 8px !important;
	padding: 0px 8px !important;
}

.evidence-thumbnail {
	height: 46px;
	width: 46px;

	margin-right: 8px;
	border-radius: 8px;
	border: 1px solid $sidebar-text;
}

.entries-table__title { 
	width: 150px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.data-table__action {
	width: 105px !important; // Preview
	&:nth-child(2) { // Edit
		width: 75px !important;
	}
	&:nth-child(3) { // Share
		width: 90px !important;
	}
	&:nth-child(4) { // Delete
		width: 95px !important;
	}
	span {
		margin-top: -4px;
	}
}


.type-of__data-badge {
	padding: 4px 12px !important;
	border-radius: 8px !important;
}

.type-of__data-badge-color {
	background-color: $sidebar !important;
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1024px) {
	.entries-form {
		justify-content: flex-start !important;
		margin-top: 8px;
	}
}

@media screen and (min-width: 1370px) {
	.entries-form {
		gap: 12px;
	}
}

@media screen and (max-width: 1370px) {
	.filters-entries {
		margin-bottom: 1rem;
	}

	.search-bar__entries {
		// margin-left: 0 !important;
	}
}

.entries-form {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.filters-entries {
	gap: 1rem;
	display: flex;
	flex-wrap: wrap;

	margin-right: 1rem;
}

.completeness {
	height: 25px;
	width: 8px;
	display: inline-block;
}

.completeness-3 {
	/* incomplete */
	background-color: #cc3232;
}

.completeness-2 {
	/* partial */
	background-color: #e7b416;
}

.completeness-1 {
	/* complete */
	background-color: #99c140;
}

.completeness-text__border {
	border: 2px solid transparent;
	padding: 12px;
	font-weight: 600;
	border-radius: 50%;
	width: 38px;
	height: 38px;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.completeness-text-3 {
	/* incomplete */
	border-color: #cc3232;
}

.completeness-text-2 {
	/* partial */
	border-color: #e7b416;
}

.completeness-text-1 {
	/* complete */
	border-color: #99c140;
}

.actions>* {
	margin: 0 4px;
}

.actions {
	width: 410px;
	display: flex;
}

.evidence-item {
	margin-bottom: 4px;
}

.show-share__action {
	visibility: visible !important;
}

.dropdown-danger {
	color: #cc3232;
}</style>
