<template>
    <div class="autosave-modal">
        <b-modal id="leaveWithoutSavingModal" ref="leaveWithoutSavingModal" title="You have outstanding changes"
                 header-bg-variant="light" header-text-variant="dark"
                 size="md" ok-variant="danger" ok-title="Leave without Saving"  cancel-title="Keep Editing" cancel-variant="light">
            <p >Are you sure you want to leave without saving your changes?</p>

            <div slot="modal-footer" class="d-flex">
                <b-button variant="danger" size="md" class="mr-2" @click="leaveWithoutSaving">Leave without Saving</b-button>
                <b-button class="ovr-button mr-2" size="md" @click="keepEditing">Keep Editing</b-button>
                <b-button class="ovr-button__inverted" size="md" @click="saveAndExit">Save and Exit</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "AutosaveModal",
        methods: {
            show() {
                this.$refs.leaveWithoutSavingModal.show();
            },
            hide() {
                this.$refs.leaveWithoutSavingModal.hide();
            },
            leaveWithoutSaving() {
                this.$bvModal.msgBoxConfirm('Please confirm that you want leave without saving.', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Leave without saving',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        this.$emit('leave-without-saving');
                        this.hide();
                    }
                });

            },
            saveAndExit() {
                this.$emit('save-and-exit');
                this.hide();
            },
            keepEditing() {
                this.$emit('keep-editing');
                this.hide();
            }
        }
    }
</script>

<style scoped>

</style>