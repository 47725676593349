<template>
    <div class="modal">
        <b-modal class="file-delete-modal" scrollable title="Delete File" ref="modal" size="md" okTitle="Delete"
                 okVariant="danger" header-bg-variant="danger" header-text-variant="light"
                 @ok="deleteFile">
            <b-alert v-if="attached" variant="warning" show><alert-icon />  This file is attached to an entry. If you delete it, it will be removed from the entry too.</b-alert>
            <p>Are you sure you want to delete the file <b>{{filename}}</b>?</p>
        </b-modal>
    </div>
</template>

<script>
    import AlertIcon from "vue-material-design-icons/Alert";
    import EventBus from "../../event-bus";
    export default {
        name: "DeleteFileModal",
        components: {AlertIcon},
        data: function() {
            return {
                storageId: '',
                filename: '',
                attached: false
            }
        },
        methods: {
            reset() {
                this.storageId = '';
                this.filename = '';
                this.attached = false;
            },
            show(f) {
                this.reset();
                this.storageId = f.id;
                this.filename = f.filename;
                this.attached = f.entryId != null;
                this.$refs.modal.show();
            },
            deleteFile() {
                if (this.storageId && this.storageId !== '') {
                    this.$http.delete(`file/${this.storageId}`).then(() => {
                        this.$success('Deleted Successfully', 'The file was deleted successfully');
                        EventBus.$emit('file-deleted');
                        this.$emit('file-deleted');
                    }).catch((resp) => {
                        this.$httpError('Failed to delete file', resp);
                    }).finally(() => {
                        this.$refs.modal.hide();
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>