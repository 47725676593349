import Vue from 'vue'
import Router from 'vue-router'
import Login from "./views/authentication/Login";
import Dashboard from "./views/Dashboard";
import DashboardHome from "./views/dashboard/DashboardHome";
import Profile from "./views/dashboard/Profile";
import Authentication from "./views/Authentication";
import store from "./store/index";
import NProgress from 'nprogress/nprogress'
import Entries from "./views/dashboard/Entries";
import LoginSSO from "./views/authentication/LoginSSO";
import Diary from './views/dashboard/create/Diary';
import Reflection from "./views/dashboard/create/Reflection";
import VideoLibraries from "./views/dashboard/VideoLibraries";
import VideoLibrary from "./views/dashboard/VideoLibrary";
import Video from "./views/dashboard/Video";
import LoginViaApp from "./views/authentication/LoginViaApp";
import PortfolioBuilder from "./views/dashboard/PortfolioBuilder";
import MyWebinars from "./views/dashboard/MyWebinars";
import MyFiles from "./views/dashboard/MyFiles";
import SharedCPD from "./views/dashboard/SharedCPD";
import Settings from "./views/dashboard/Settings";
import AccountManagement from "./views/dashboard/AccountManagement";
import ForgotPassword from "./views/authentication/ForgotPassword";
import LoginOrganisation from "./views/authentication/LoginOrganisation";
import Portal from "./views/hcpc-portal/Portal";
import Summary from "./views/hcpc-portal/Summary";
import Activities from "./views/hcpc-portal/Activities";
import Statement from "./views/hcpc-portal/Statement";
import Evidence from "./views/hcpc-portal/Evidence";
import Access from "./views/hcpc-portal/Access";
import AuthenticationV2 from "./views/AuthenticationV2";
import LoginV2 from "./views/authentication_V2/LoginV2";
import RegistrationV2 from "./views/authentication_V2/RegistrationV2";
import MobileLoginV2 from "./views/authentication_V2/MobileLoginV2";
import ForgotPasswordV2 from "./views/authentication_V2/ForgotPasswordV2";
import ConfirmEmailChange from "./views/authentication_V2/ConfirmEmailChange"
import LoginUsingOverride from "./views/authentication/LoginUsingOverride";
import OnboardV2 from "@/views/authentication_V2/OnboardV2";
import EmailHistory from "@/views/EmailHistory"
import ForgotPasswordDoV2 from "@/views/authentication_V2/ForgotPasswordDoV2";
import Welcome from "@/views/Welcome";
import PortfolioChoice from "@/views/dashboard/PortfolioChoice";
import VueGtag from "vue-gtag";

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
    },
    {
      path: '/',
      component: Dashboard,
      children: [
        {
          path: '',
          component: DashboardHome,
          name: 'dashboard-home',
        },
        {
          path: 'profile',
          component: Profile,
          name: 'profile',
          meta: {
            breadcrumbTitle: 'Personal Profile'
          }
        },
        {
          path: 'settings',
          component: Settings,
          name: 'settings',
          meta: {
            breadcrumbTitle: 'Settings'
          }
        },
        {
          path: 'account-management',
          component: AccountManagement,
          name: 'account-management',
          meta: {
            breadcrumbTitle: 'Account Management'
          }
        },
        {
          path: '/email-history',
          name: 'email-history',
          component: EmailHistory,
          meta: {
            breadcrumbTitle: 'Email Change History'
          }
        },
        {
          path: 'entries',
          component: Entries,
          name: 'entries',
          meta: {
            breadcrumbTitle: 'My Entries'
          }
        },
        {
          path: 'shared-cpd',
          component: SharedCPD,
          name: 'shared-cpd',
          meta: {
            breadcrumbTitle: 'Shared CPD'
          }
        },
        {
          path: 'webinars',
          component: MyWebinars,
          name: 'my-webinars',
          meta: {
            breadcrumbTitle: 'My Webinars'
          }
        },
        {
          path: 'files',
          component: MyFiles,
          name: 'my-files',
          meta: {
            breadcrumbTitle: 'My Files'
          }
        },
        {
          path: 'portfolio-builder',
          component: PortfolioBuilder,
          name: 'portfolio-builder',
          meta: {
            breadcrumbTitle: 'Portfolio Builder'
          }
        },
        {
          path: 'portfolio-builder/choose',
          component: PortfolioChoice,
          name: 'portfolio-builder-choose',
          meta: {
            breadcrumbTitle: 'Portfolio Builder - Choose Type'
          }
        },
        {
          path: 'create/diary',
          component: Diary,
          name: 'create/diary',
          meta: {
            breadcrumbTitle: 'Add Diary'
          }
        },
        {
          path: 'edit/diary/:id',
          component: Diary,
          name: 'edit/diary',
          meta: {
            breadcrumbTitle: 'Edit Diary'
          }
        },
        {
          path: 'create/reflection',
          component: Reflection,
          name: 'create/reflection',
          meta: {
            breadcrumbTitle: 'Add Reflection'
          }
        },
        {
          path: 'edit/reflection/:id',
          component: Reflection,
          name: 'edit/reflection',
          meta: {
            breadcrumbTitle: 'Edit Reflection'
          }
        },
        {
          path: 'videos',
          component: VideoLibraries,
          name: 'videos',
          meta: {
            breadcrumbTitle: 'CPD Hub Video Libraries'
          }
        },
        {
          path: 'videos/library',
          component: VideoLibrary,
          name: 'videos/library',
          meta: {
            breadcrumbTitle: 'CPD Hub Video Library'
          }
        },
        {
          path: 'video',
          component: Video,
          name: 'video',
          meta: {
            breadcrumbTitle: 'CPD Hub Video'
          }
        },
        {
          path: 'calendar',
          component: () => import('./views/dashboard/Calendar.vue'),
          name: 'calendar',
          meta: {
            breadcrumbTitle: 'Calendar'
          }
        },
        {
          path: 'organisation',
          name: 'organisation',
          component: () => import('./views/organisation/Organisation'),
          meta: {
            requiredRole: 'Organisation-Manager'
          },
          children: [
            {
              path: 'users-report',
              name: 'organisation/users-report',
              component: () => import('./views/organisation/UsersReport'),
              meta: {
                requiredRole: 'Organisation-Manager',
                breadcrumbTitle: 'Users Report'
              }
            },
            {
              path: 'certificates',
              name: 'organisation/certificates',
              component: () => import('./views/admin/SendCertificates'),
              meta: {
                requiredRole: 'Organisation-Manager',
                breadcrumbTitle: 'Certificates'
              }
            },
            {
              path: 'shared-cpd',
              name: 'organisation/shared-cpd',
              component: () => import('./views/admin/AdminSharedCPD'),
              meta: {
                requireRole: 'Organisation-Manager',
                breadcrumbTitle: 'Shared CPD'
              }
            },
            {
              path: 'events',
              name: 'organisation/events',
              component: () => import('./views/organisation/EventsManagement'),
              meta: {
                requireRole: 'Organisation-Manager',
                breadcrumbTitle: 'Events Management'
              }
            }
          ]
        },
        {
          path: 'admin',
          name: 'admin',
          component: () => import('./views/admin/Admin.vue'),
          meta: {
            admin: true
          },
          children: [
            {
              path: 'member-groups',
              name: 'admin/member-groups',
              component: () => import('./views/admin/AdminMemberGroups.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Member Groups'
              },
            },
            {
              path: 'overview',
              name: 'admin/overview',
              component: () => import('./views/admin/AdminOverview.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin Overview'
              },
            },
            {
              path: 'shared-cpd',
              name: 'admin/shared-cpd',
              component: () => import('./views/admin/AdminSharedCPD.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Shared CPD'
              },
            },
            {
              path: 'activity-log',
              name: 'admin/activity-log',
              component: () => import('./views/admin/AdminActivityLog.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Activity Log'
              }
            },
            {
              path: 'organisations',
              name: 'admin/organisations',
              component: () => import('./views/admin/ManageOrganisations.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Manage Organisations'
              }
            },
            {
              path: 'organisations/:organisationId/configure',
              name: 'admin/organisations/configure',
              component: () => import('./views/admin/organisations/ConfigureOrganisation.vue'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Configure Organisation'
              }
            },
            {
              path: 'organisations/create',
              name: 'admin/organisations/create',
              component: () => import('./views/admin/organisations/CreateOrganisation'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Create Organisation'
              }
            },
            {
              path: 'certificates',
              name: 'admin/certificates',
              component: () => import('./views/admin/SendCertificates'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Certificate Wizard'
              }
            },
            {
              path: 'memberships',
              name: 'admin/memberships',
              component: () => import('./views/admin/memberships/Memberships'),
              meta: {
                admin: true,
                breadcrumbTitle: 'Admin - Memberships'
              }
            }
          ]
        },
      ]
    },
    {
      path: '/hcpc-portal/access/:accessToken',
      component: Access,
      meta: {
        guest: true,
        breadcrumbTitle: 'Access',
      }
    },
    {
      path: '/hcpc-portal',
      component: Portal,
      children: [
        {
          path: 'summary',
          name: 'hcpc/summary',
          component: Summary,
          meta: {
            guest: true,
            breadcrumbTitle: 'Summary'
          }
        },
        {
          path: 'activities',
          name: 'hcpc/activities',
          component: Activities,
          meta: {
            guest: true,
            breadcrumbTitle: 'Activities'
          }
        },
        {
          path: 'statement',
          name: 'hcpc/statement',
          component: Statement,
          meta: {
            guest: true,
            breadcrumbTitle: 'Statement'
          }
        },
        {
          path: 'evidence',
          name: 'hcpc/evidence',
          component: Evidence,
          meta: {
            guest: true,
            breadcrumbTitle: 'Evidence'
          }
        }
      ],
      meta: {
        guest: true,
        breadcrumbTitle: 'Start'
      }
    },
    {
      path: '/v2/auth',
      component: AuthenticationV2,
      children: [
        {
          path: 'login',
          name: 'login-v2',
          component: LoginV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Login'
          }
        },
        {
          path: 'register',
          name: 'register-v2',
          component: RegistrationV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Register'
          }
        },
        {
          path: 'mobile-login',
          name: 'mobile-login-v2',
          component: MobileLoginV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Login via App'
          }
        },
        {
          path: 'forgot-password',
          name: 'forgot-password-v2',
          component: ForgotPasswordV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Forgot Password'
          }
        },
        {
          path: 'confirm-email-change/:token',
          name: 'confirm-email-change',
          component: ConfirmEmailChange,
          meta: {
            guest: true,
            breadcrumbTitle: 'Email Change'
          }
        },
        {
          path: 'forgot-password/:token',
          name: 'forgot-password-do-v2',
          component: ForgotPasswordDoV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Forgot Password'
          }
        },
        {
          path: 'onboard/:token',
          name: 'onboard',
          component: OnboardV2,
          meta: {
            guest: true,
            breadcrumbTitle: 'Onboarding'
          }
        }
      ]
    },
    {
      path: '/auth',
      component: Authentication,
      redirect: {name: 'login-v2'},
      children: [
        {
          path: 'login',
          name: 'login',
          redirect: {name: 'login-v2'},
          component: Login,
          meta: {
            guest: true,
            breadcrumbTitle: 'Login'
          }
        },
        {
          path: 'login/sso',
          name: 'login/sso',
          component: LoginSSO,
          meta: {
            guest: true,
            breadcrumbTitle: 'Login SSO'
          }
        },
        {
          path: 'login/app',
          name: 'login/app',
          component: LoginViaApp,
          meta: {
            guest: true,
            breadcrumbTitle: 'Login via App'
          }
        },
        {
          path: 'login/organisation/:organisationKey',
          name: 'login/organisation',
          component: LoginOrganisation,
          meta: {
            guest: true,
            breadcrumbTitle: 'Organisation Login'
          }
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: ForgotPassword,
          meta: {
            guest: true,
            breadcrumbTitle: 'Forgot Password'
          }
        },
        {
          path: 'login-override',
          name: 'login-override',
          component: LoginUsingOverride,
          meta: {
            guest: true,
          }
        }
      ],
      meta: {
        guest: true
      }
    },
    {
      path: '/o/:organisationKey',
      redirect: '/auth/login/organisation/:organisationKey',
      meta: {
        guest: true
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  let isGuestRoute = to.meta.guest;
  let isAdminRoute = to.meta.admin;

  let isLoggedIn = store.state.authentication.loggedIn;
  let isAdmin = store.state.authentication.admin;
  if (isGuestRoute !== true && !isLoggedIn) {
    next({
      name: 'login-v2',
      params: { nextUrl: to.fullPath }
    });
    return;
  // } else if (isGuestRoute && isLoggedIn) {
  //   next({
  //     name: 'dashboard-home'
  //   });
  } else if (isAdminRoute && !isAdmin) {
    next({
      name: 'dashboard-home'
    });
  }
  next();
});

router.afterEach((to) => {
  NProgress.done();
  Vue.nextTick(() => {
    document.title = 'CPDme Dashboard' + (to.meta.breadcrumbTitle ? ' - ' + to.meta.breadcrumbTitle : '');
  });
});

Vue.use(VueGtag, {
  config: {
    id: "G-B2GN9JNHL7"
  },
  appName: "CPDme Dashboard",
  pageTrackerScreenviewEnabled: true
}, router)

export default router;
