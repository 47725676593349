<template>
    <div class="authentication">
        <h4 class="pb-4">Log in to your account</h4>

        <b-alert show v-if="$route.params.successfullyInitiatedEmailChange" variant="success">
            A link has been sent to your new email address. Please follow the instructions in the email to verify your new address and complete the email change process
        </b-alert>

        <b-alert :show="true" variant="warning" v-if="errorMessage">{{errorMessage}}</b-alert>
        <b-alert :show="true" variant="success" v-if="message">{{message}}</b-alert>
        <b-overlay :show="loading">
            <form @submit="login">
                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0 no-background"><img alt="icon user" width="20" src="../../assets/auth/ic-user.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="email" placeholder="EMAIL" v-model="email" aria-label="Email Address" />
                    </b-input-group>
                </b-form-group>
                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0  no-background"><img alt="icon password" width="20" src="../../assets/auth/ic-lock.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="password" placeholder="PASSWORD" v-model="password" aria-label="Password" />
                    </b-input-group>
                </b-form-group>

                <b-row>
                    <b-col md="6" sm="12" class="p-0">
                        <b-form-checkbox class="pb-4">
                            Remember me
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="6" sm="12" class="pb-4 text-right">
                        <a @click="forgotPassword" class="forgot-password text-uppercase">Forgot Password?</a>
                    </b-col>
                    <b-col md="12" class="p-0">
                        <b-button variant="primary" type="submit" class="button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Login</b-button>
                    </b-col>
                </b-row>

            </form>
        </b-overlay>
    </div>
</template>

<script>
    import Managers from '../../managers';
    import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline';

    export default {
        name: "LoginV2",
        components: {InformationOutlineIcon},
        data() {
            return {
                email: '',
                password: '',
                errorMessage: '',
                message: '',
                loading: false
            }
        },
        watch: {
            email() {
                this.errorMessage = '';
            },
            password() {
                this.errorMessage = '';
            }
        },
        methods: {
            login($e) {
                $e.preventDefault();
                this.message = '';
                this.loading = true;
                Managers.authentication.login(this.email, this.password).then(() => {
                    if (this.$route.params.nextUrl != null && this.$route.params.nextUrl !== '/') {
                        this.$router.push(this.$route.params.nextUrl);
                    } else {
                        this.$router.push({name: 'welcome'})
                    }
                }).catch((error) => {
                    this.errorMessage = error;
                }).finally(() => {
                    this.loading = false;
                })
            },
            forgotPassword() {
                this.$router.push({name: 'forgot-password-v2', params: {email: this.email}});
            }
        },
        created() {
            if (this.$route.params.message) {
                this.message = this.$route.params.message;
            }
        }
    }
</script>

<style scoped>
    @import '../../assets/auth/auth.css';
</style>
