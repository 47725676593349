<template>
    <div class="no-select" :class="status">

        <div class="entries-count">
            <div class="entries-count__left">
                <img v-if="title === 'Entries'" class="entries-icon" src="/img/icons/icon-edit.png" alt="">
                <img v-else class="entries-icon" src="/img/icons/icon-monitor-dashboard.png" alt="">
            </div>
            <div class="entries-count__right">
                <span class="gauge-title">{{title}}</span>

                <span class="gauge-value">
                    <Pulse v-if="loading"/>
                    <span v-else>{{value}} / {{limit}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import Pulse from "../loaders/Pulse";
    export default {
        name: "UsageGauge",
        components: {Pulse},
        props: {
            title: String,
            value: String,
            limit: String,
            status: String,
            loading: Boolean
        }
    }
</script>

<style scoped lang="scss">

    @import "../../assets/css/_variables.scss";

    .entries-icon {
        width: 22px;
    }

    .entries-count {
        display: flex;
        align-items: center;
    }

    .entries-count__left {
    }

    .entries-count__right {
        margin-left: 18px;
    }

    span.gauge-value {
        color: $sidebar-text;
        width: 100%;
        font-size: 14px !important;
        display:block;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    span.gauge-title {
        font-size: 14px;
        margin-bottom: 0;
        margin-right: auto;
        color: rgba(218, 219, 211, 0.4);
    }

    @media screen and (max-width: 1400px) {
        span.gauge-value {
            font-size: 17px;
        }
    }

    @media screen and (max-width: 1000px) {
        span.gauge-value {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 800px) {
        span.gauge-value {
            font-size: 24px;
        }
    }


</style>
