<template>
    <div v-if="!noLimit" class="storage">
        <b-row style="padding-left: 10px; padding-right: 10px;" :no-gutters="true">
            <b-col 	cols="12" class="no-padding">
                <UsageGauge title="Entries" :value="entriesUsage + ''" :limit="entriesMaxHumanReadable" :status="entriesStatus" :loading="loading"/>
            </b-col>
            <b-col 	cols="12" class="no-padding">
                <UsageGauge title="Storage" :value="storageUsageHumanReadable" :limit="storageMaxHumanReadable" :status="storageStatus" :loading="loading"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>

    import UsageGauge from "./usage/UsageGauge";

    export default {
        name: "Usage",
        components: {UsageGauge},
        props: {
            noLimit: Boolean,
            loading: Boolean,
            entriesUsage: Number,
            storageUsage: Number
        },
        data: function() {
            return {
            }
        },
        computed: {
            storageStatus: function() {
                console.log("isFullMember", this.noLimit)
                if (!this.noLimit) {
                    let percentage = this.storageUsage / this.storageMax;
                    if (percentage) {
                        if (percentage >= 0.8) {
                            return 'full';
                        }
                        if (percentage >= 0.7) {
                            return 'high';
                        }
                    }
                }
                return 'normal';
            },
            entriesStatus: function() {
                if (!this.noLimit) {
                    let percentage = this.entriesUsage / this.entriesMax;
                    if (percentage >= 0.8) {
                        return 'full';
                    }
                    if (percentage >= 0.6) {
                        return 'high';
                    }

                }
                return 'normal';
            },
            storageUsageHumanReadable: function() {
                if (this.storageUsage === 0) {
                    return 'None';
                }
                return this.formatFileSize(this.storageUsage, 1);
            },
            storageMaxHumanReadable: function() {
                if (this.storageMax === 0) {
                    return 'Unlimited';
                }
                return this.formatFileSize(this.storageMax, 0);
            },
            entriesMaxHumanReadable: function() {
                if (this.entriesMax === 0) {
                    return 'Unlimited';
                }
                return this.entriesMax + '';
            },
            entriesMax: function() {
                if (this.noLimit) {
                    return 0;
                }
                return 5;
            },
            storageMax: function() {
                if (this.noLimit) {
                    return 0;
                }
                return 1e+8;
            }
        },
        methods: {
            loadUsage: function() {
                this.loading = true;
                this.$http.get('usage').then((resp) => {
                    let json = resp.body;
                    this.entriesUsage = json.entries;
                    this.storageUsage = json.storage;
                    this.loading = false;
                })
            },
            formatFileSize: function formatFileSize(bytes,decimalPoint) {
                if(bytes === 0) return '0 Bytes';
                let k = 1000,
                    dm = decimalPoint || 2,
                    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }
        },
        created() {
        }
    }
</script>

<style scoped>
    .storage-progress {
        padding-left: 20px;
        padding-right: 20px;
    }

    div.no-padding {
        padding: 5px;
    }
</style>