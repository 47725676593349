<template>
    <div class="wizard-progress">
        <b-row>
            <b-col cols="12" lg="3" v-for="(stage, i) in parsedSteps" v-bind:key="stage.routeName">
                <b-button squared :variant="stage._btn_state" block disabled ><CheckCircleIcon v-if="stage.completed"/> {{i+1}}. {{stage.title}}</b-button>
            </b-col>
        </b-row>

        <div class="mt-4">
            <b-row>
                <b-col cols="12">
                    <h2>{{activeStepTitle}}</h2>
                </b-col>
                <b-col cols="12">
                    <slot></slot>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="6">
                    <b-button variant="light" size="sm" block v-if="previousStep" @click="previous">Back - {{previousStep.title}}</b-button>
                </b-col>
                <b-col cols="6">
                    <b-button variant="primary" size="sm" block v-if="nextStep" @click="next">Next - {{nextStep.title}}</b-button>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>

    import CheckCircleIcon from 'vue-material-design-icons/CheckCircle';

    export default {
        name: "WizardProgress",
        components: {CheckCircleIcon},
        props: {
            steps: {
                type: Array,
                required: true,
            }
        },
        computed: {
            parsedSteps() {
                let steps = [];
                for (let step of this.steps) {
                    let _step = {...step};
                    _step.active = this.$route.name === _step.route.name;
                    _step.completed = this.$store.state.hcpcPortal.completed[_step.completedKey];
                    _step._btn_state = _step.active ? 'info' : (_step.completed ? 'outline-success' : 'outline-dark');

                    steps.push(_step);
                }
                return steps;
            },
            activeStep() {
                for (let step of this.parsedSteps) {
                    if (step.active) {
                        return step;
                    }
                }
                return null;
            },
            activeStepTitle() {
                this.activeStep ? this.activeStep.title : '';
            },
            previousStep() {
                let previous = null;
                for (let step of this.parsedSteps) {
                    if (step.active) {
                        return previous;
                    }
                    previous = step;
                }
                return previous;
            },
            nextStep() {
                let returnNext = false;
                for (let step of this.parsedSteps) {
                    if (returnNext) {
                        return step;
                    }
                    if (step.active) {
                        returnNext = true;
                    }
                }
                return null;
            },
        },
        methods: {
            next() {
                if (this.nextStep) {
                    this.$router.push(this.nextStep.route);
                }
            },
            previous() {
                if (this.previousStep) {
                    this.$router.push(this.previousStep.route);
                }
            },
            goTo(stage) {
                if (this.activeStep !== stage) {
                    this.$router.push(stage.route);
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
