<template>
	<b-row>
		<b-col sm="12" class="diary-container">
			<div>
				<ShowTourButton @handleClick="handleShowTourButton" />
			</div>
			<Pulse loading v-if="isBusy" class="loader" color="#999999" />
			<b-alert v-if="message != null" :show="true" :dismissible="true" @dismissed="messageDismissed"
				:variant="message.type"> {{ message.message }} </b-alert>

			<div class="add-diary__title-section">
				<h4 class="dashboard-pages__title">Describe your Continuing Professional Development Activity: Past, Present, and Future Planned.</h4>
			</div>
			
			<b-alert v-if="!isFullMember" show :variant="info"> 
				<b-row>
					<b-col cols="12">
						<small>As a basic member, you can record a maximum of 5 diary entries. <br> Upgrade to Premium membership to record an unlimited number of entries.</small>
					</b-col>
					<b-col cols="12" >
						<b-button variant="primary" size="sm" class="mt-3" target="_blank" @click="upgradeMembership"><star-icon></star-icon> Upgrade Now</b-button>
					</b-col>
				</b-row>
			</b-alert>
			
			<b-card>
				<Form @handleTitleInput="messageDismissed" :load-form="loadForm" ref="form" :entry-id="entryId" @show-message="showMessage" @hide-message="messageDismissed"
					@loading-started="isBusy = true" @loading-entry="entrySubmitted" @loading-finished="isBusy = false" :form="form"
					v-show="!isBusy" v-if="form" @entry-submitted="onCompletion()" />
			</b-card>

			<UpgradeModal ref="upgradeModal" />
			<PageTour ref="pageTourRef" description="The CPD Diary is the most important part of this system and is where you capture and record all your continuing professional development. Let’s get you started!" @handleShowTour="handleShowTour"  />
			<v-tour name="diaryTour" :steps="steps" :options="{ highlight: true }"></v-tour>
		</b-col>
	</b-row>
	
</template>

<script>
import { mapGetters } from "vuex";
import Form from '../../../components/form/Form';
import Pulse from '../../../components/loaders/Pulse'
import PageTour from "../../../components/common/PageTour";
import ShowTourButton from '../../../components/common/ShowTourButton';
import StarIcon from "vue-material-design-icons/Star";
import UpgradeModal from '../../../components/UpgradeModal.vue';

export default {
	name: "Diary",
	components: { Form, Pulse, PageTour, ShowTourButton, StarIcon, UpgradeModal },
	data: function () {
		return {
			isBusy: false,
			form: null,
			message: null,

			progressValue: 2,
			maxValue: 6,
			steps: []
		}
	},
	computed: {
		...mapGetters({
			isFullMember: 'authentication/isFullMember',
		}),
	},
	methods: {
		renderSteps() {
			let tourSteps = [];
			tourSteps.push({
					target: '.diary-title__tour',
					content: `This is the description of the event such as “Attended a course on improving my communications skills” or “Took part in a discussion to discuss better customer care”. <br> <br> <b>Top Tip:</b> This is a searchable field and unique to your entry that you can search for later.`
			});
			tourSteps.push({
					target: '.nunber-of__hours-tour',
					content: 'This is where you can document the number of hours and minutes that you took part in the activity. <br> <br> Under the hours box is a link that you can click to log more than 24 hours if you need to.'
			});
			tourSteps.push({
				target: '.start-date__tour',
				content: 'This is the date of the learning that has taken place. It should always default to today’s date. <br> <br> Here you can backdate your CPD previously attended and you can also forward date your (planned) future CPD activities.'
			});
			tourSteps.push({
				target: '.end-date__tour',
				content: 'This field only needs to be completed if your event spanned across a few days or months like a module at university or a two-day training course. You don’t need to repeat the start date if it’s a single 1-day learning event.'
			});
			tourSteps.push({
				target: '.governing-body__tour',
				content: 'Here you can select which governing body you would like to use to record your CPD in the correct format. <br> <br> The reason it is selectable is that you may be registered with several governing bodies so you can use this form to record all of your CPD in one place. <br> <br> The governing body will default based on the initial setup of your dashboard and can be changed in the Profile.'
			});
			
			const arrangedInTour = document.querySelector('.arranged-in__tour') || null
			if (arrangedInTour) {
				tourSteps.push({
					target: '.arranged-in__tour',
					content: 'This is in what capacity you took part in the learning activity. <br> <br> Was this in work time as part of your job, your time as a student, or self-directed study in your own time?'
				});
			}
			
			const relatesToTour = document.querySelector('.relates-to__tour') || null
			if (relatesToTour) {
				tourSteps.push({
					target: '.relates-to__tour',
					content: 'This is the different categories that you can map your learning against like reading or reviewing, supervision, attending a course or online learning. <br> <br> Need a category adding? Just contact our support team.'
				});
			}
			
			tourSteps.push({
				target: '.tag-entry__tour',
				content: 'You can create some personal tags in your Profile so that you can easily search and produce a personalised portfolio at some stage in the future.'
			});

			tourSteps.push({
				target: '.attach-evidence__tour',
				content: 'Here you can attach evidence to your reflection for example perhaps some of the journals or research that you used. This will help substantiate your learning journey.'
			});


			tourSteps.push({
				target: '.footer-buttons__tour',
				content: 'Here you can choose ‘Save and Close’ to save the work that you have done so far and close the page. <br> <br> You can choose ‘Save and Continue’ if you are continuing this piece of work and wish to save your progress so far. <br> <br> You can choose ‘Preview’ to see what your entry would look like in a print-ready format.'
			});

			return tourSteps
		},

		handleShowTourButton() {
			this.steps = this.renderSteps()
			this.$refs.pageTourRef.handleOpen()
		},
		handleShowTour() {
			this.$tours['diaryTour'].start()
		},
		loadForm: function () {
			this.form = null;
			if (this.$route.params && this.$route.params.id !== undefined) {
				this.loadExistingEntry(this.$route.params.id);
			} else {
				this.loadNewForm();
			}
		},
		loadNewForm: function () {
			this.isBusy = true;
			this.$http.get('form/diary').then(response => {
				this.form = response.body;
			}).catch((resp) => {
				this.$httpError('Failed to load Diary form', resp);
			}).finally(() => {
				this.isBusy = false;
			});
		},
		loadExistingEntry: function (id) {
			this.isBusy = true;
			this.entryId = id;
			this.$http.get('form/entry/' + id).then(response => {
				this.form = response.body;
			}).catch((resp) => {
				this.$httpError('Failed to load entry', resp);
			}).finally(() => {
				this.isBusy = false;
			});
		},
		entrySubmitted: function () {
			// alert('Received');
		},
		showMessage: function (event) {
			this.message = event;
		},
		messageDismissed: function () {
			this.message = null;
		},
		onLoad: function () {
			this.loadForm();
		},
		upgradeMembership() {
            this.$refs.upgradeModal.show();
        }
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.form = null;
			vm.message = null;
			vm.entryId = null;
			vm.onLoad();
		});
	},
	beforeRouteLeave(to, from, next) {
		this.$refs.form.leavingRoute(to, from, next);
	}
}
</script>

<style scoped lang="scss">
	.diary-container {
		margin: 0 auto;
		width: 100%;
		max-width: 950px;
	}

.add-diary__title {
	font-weight: 600;
}
</style>