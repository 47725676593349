<template>
    <div class="login-form-containr">
        <Logo />
        <b-form class="form-login" @submit.prevent="login" >
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>

            <p><small >If you have a registered account to the CPDme.com website or are a premium member of CPDme, select the <b>Website User</b> option.</small></p>
            <b-button @click="loginViaCPDme" variant="info" type="button" :block="true"><web-icon class="float-left"></web-icon> Login - Website User</b-button>
            <b-button v-if="!appUserLogin" @click="appUserLogin = true" variant="primary" type="button" :block="true"><cellphone-icon class="float-left"></cellphone-icon> Login - Mobile App User</b-button>
            <div class="app-user-wrapper mt-4" v-if="appUserLogin">
                <p>Used the app before? Login with those details below.<br /></p>
                <b-alert :show="alert != null" dismissible variant="danger">{{ alert }}</b-alert>
                <b-alert :show="message != null" dismissible variant="success">{{ message }}</b-alert>
                <b-form-group label="Email Address">
                    <b-form-input id="emailAddress"
                                  type="email"
                                  v-model="email"
                                  required
                                  autofocus
                                  placeholder="bob@example.com" />
                </b-form-group>

                <b-form-group label="Password">
                    <b-form-input id="password"
                                  type="password"
                                  v-model="password"
                                  required
                                  placeholder="********" />
                </b-form-group>

                <b-button variant="primary" type="submit" :block="true">Login</b-button>
                <router-link :to="{name: 'forgot-password'}"><b-button variant="default" :block="true">Forgot Password</b-button></router-link>
            </div>
            <div class="signup-wrapper mt-4">
                <h3 class="h4 mb-3 font-weight-normal">Not a CPDme member?</h3>
                <b-button size="sm" block variant="outline-dark" href="https://www.cpdme.com/join" target="_blank">Sign-up to CPDme</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>

    import CellphoneIcon from "vue-material-design-icons/Cellphone";
    import WebIcon from "vue-material-design-icons/Web";
    import Logo from "../../components/authentication/Logo";
    import Managers from '../../managers';

    export default {
        name: "Login",
        components: {Logo, WebIcon, CellphoneIcon},
        data: function() {
            return {
                email: '',
                password: '',
                alertMessage: null,
                redirectTo: '',
                hasAuthenticatorFeature: false,
                message: null,
                appUserLogin: false,
            }
        },
        computed: {
          alert: {
              get() {
                  return this.alertMessage;
              }
          }
        },
        methods: {
            loadFeatures() {
                this.$http.get('features').then((resp) => {
                    this.hasAuthenticatorFeature = resp.body.features['DashboardAuthenticator'];
                }).catch(() =>{});
            },
            clearForm: function() {
              this.email = '';
              this.password = '';
            },
            login: function() {
                Managers.authentication.login(this.email, this.password).then(() => {
                    if (this.$route.params.nextUrl != null) {
                        this.$router.push(this.$route.params.nextUrl);
                    } else {
                        this.$router.push({name: 'dashboard-home'})
                    }
                }).catch((error) => {
                    this.alertMessage = error;
                    this.clearForm();
                });
            },
            loginViaCPDme: function() {
                try {
                    let hostname = '';
                    if (document.referrer !== undefined && document.referrer !== '') {
                        let url = new URL(document.referrer);
                        hostname = url.hostname;
                    }
                    if (!['www.cpdme.com', 'cpdme.com'].includes(hostname)) {
                        hostname = 'www.cpdme.com';
                    }
                    this.$http.get(`auth/login/sso?redirectTo=${this.redirectTo}&referrer=${encodeURIComponent(hostname)}`).then(response => {
                        window.location.href = response.body.redirect;
                    }).catch((resp) => {
                        this.$httpError('Failed to sign in', resp);
                        let error = resp.body.message || resp;
                        this.$ga.exception(error);
                    });
                } catch (error) {
                    // catch all error handler
                    const exception = error.message || error;
                    this.$ga.exception(exception);
                }
            },
            loginViaApp: function() {
                this.$router.push({'name': 'login/app'});
            }
        },
        mounted: function() {
            this.redirectTo = encodeURI(process.env.VUE_APP_SSO_REDIRECT);
            if (this.$route.query.sso) {
                this.loginViaCPDme();
            }
            if (this.$route.params && this.$route.params.message !== '') {
                this.message = this.$route.params.message;
            }
        },
        created() {

            this.loadFeatures();
        }
    }
</script>

<style scoped>
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    .form-login .checkbox {
        font-weight: 400;
    }
    .form-login .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-login .form-control:focus {
        z-index: 2;
    }
    .form-login input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-login input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .btn-primary {
        background-color: #2c3e50;
        border-color: #2c3e50;
    }
</style>