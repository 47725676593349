<template>
	<div class="content" v-if="!loading">
		<div class="readonly governing-body__container" v-if="!editing">
			<h6 class="dashboard-pages__title mb-0">Governing Body</h6>
			<p class="w-100 mb-1">You are currently registered with the following governing bodies:</p>
			<div v-for="(gb, idx) in selectedGoverningBodies" :key="idx" class="governing-body">
				{{ gb }}
			</div>

			<p v-requires-role="'User-Basic'" class="edit-governing__body" @click="edit" role="button">Edit</p>
		</div>
		<div class="edit" v-if="editing">
			Governing bodies you're registered for:
			<b-form-checkbox-group class="pt-2 pb-2" size="md" switches id="governing-bodies" stacked name="pages"
				v-model="selectedGoverningBodies" :options="governingBodies"></b-form-checkbox-group>
			<p><small class="warning"><b>Warning: </b> Removing any registered governing bodies may result in the loss of some profile information. </small></p>
			<b-button class="mr-3" @click="save" size="sm" variant="outline-danger">Save</b-button>
			<b-button @click="cancel" size="sm" variant="outline-dark">Cancel</b-button>
		</div>
	</div>
	<div class="loading" v-else>
		<Pulse />
	</div>
</template>

<script>
import Pulse from "../loaders/Pulse";
export default {
	name: "ProfileGoverningBody",
	components: { Pulse },
	data: function () {
		return {
			governingBodies: [],
			selectedGoverningBodies: [],
			editing: false,
			loading: false,
		}
	},
	methods: {
		edit: function () {
			this.editing = true;
		},
		cancel: function () {
			this.editing = false;
			this.loadGoverningBodies();
		},
		save: function () {
			this.editing = false;
			this.loading = true;

			this.$http.put('profile/governing-bodies', this.selectedGoverningBodies).then(() => {
				this.$emit('updated-governing-bodies');
				this.loadGoverningBodies();
			}).catch((err) => {
					this.$httpError('Failed to save governing bodies', err);
			}).finally(() => {
					this.loading = false;
			});

		},
		loadGoverningBodies: function () {
			this.loading = true;
			this.$http.get('profile/governing-bodies').then((resp) => {
				this.selectedGoverningBodies = resp.body;
				this.loading = false;
			}).catch((err) => {
					this.$httpError('Failed to get governing bodies', err);
			}).finally(() => {
					this.loading = false;
			});
		},
		loadGoverningBodyOptions() {
			this.$http.get('profile/governing-bodies/options').then((resp) => {
				this.governingBodies = resp.body;
			}).catch((err) => {
					this.$httpError('Failed to get governing bodies', err);
			}).finally(() => {
					this.loading = false;
			});
		}
	},
	mounted() {
		this.loadGoverningBodies();
		this.loadGoverningBodyOptions();
	}
}
</script>

<style scoped lang="scss">
.warning {
	color: red;
}

.governing-body__container {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.governing-body {
	background-color: $blue-700;

	padding:0px 18px;
	border-radius: 24px;
	font-weight: 500;
	color: $blue-300;
}

.edit-governing__body {
	display: flex;
	align-items: center;
	position: absolute;
	right: 16px;
	top: 16px;

	font-size: 14px;
	font-weight: 500;
	color: $blue-300;

	margin-bottom: 0;
}
</style>