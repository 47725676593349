<template>
    <div class="summary">
        <b-overlay :show="loading">
            <p>
               The HCPC require you to describe your current role and the type of work you have done during the past two years.
               This information is so that they can assess whether your CPD is relevant to your current or future practice
               (Standard 2). This should be around 500 words.
            </p>

            <RichTextEditor v-model="summary" @input="textUpdated" />

            <p>{{$wordCountHtml(summary)}} / 500 words</p>

            <Actions ref="actions" :completed.sync="completed" @mark-complete="markComplete" @save="save" @copy="copy" />
        </b-overlay>
    </div>
</template>

<script>
    import Actions from "../../components/hcpc-portal/Actions";
    import RichTextEditor from "../../components/common/RichTextEditor";
    import Providers from '../../providers';
    import clipboardMixin from "../../utilities/clipboard";


    export default {
        name: "Summary",
        components: {RichTextEditor, Actions},
        mixins: [clipboardMixin],
        data() {
            return {
                summary: '',
                loading: false,
            }
        },
        computed: {
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            },
            completed: {
                get() {
                    return this.$store.state.hcpcPortal.completed.summary;
                },
                set(val) {
                    this.$store.commit('hcpcPortal/markCompleted_Summary', val);
                }
            }
        },
        watch: {
            summary(newVal, oldVal) {
                if (!this.loading) {
                    if (newVal.replace(/\n/g, '') !== oldVal.replace(/\n/g, '')) {
                        this.completed = false;
                    }
                }
            }
        },
        methods: {
            markComplete() {
                this.completed = true;
            },
            loadSummary() {
                this.loading = true;
                Providers.hcpcPortal.summary(this.accessToken).then((resp) => {
                    this.summary = resp.body.summary;
                }).catch((resp) => {
                    this.$httpError('Failed to load Summary', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            textUpdated() {
                // if (!this.loading) {
                //     this.completed = false;
                // }
            },
            save($e) {
                this.loading = true;
                Providers.hcpcPortal.updateSummary(this.accessToken, this.summary).then((resp) => {
                    $e.animate();
                    this.summary = resp.body.summary;
                }).catch((resp) => {
                    this.$httpError('Failed to save summary', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            copy() {
                this.copyRichToClipboard(this.summary);
            }
        },
        created() {
            this.loadSummary();
        }
    }
</script>

<style scoped>


</style>