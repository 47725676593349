<template>
    <div class="authentication">
        <Layout />
    </div>
</template>

<script>
    import Layout from "../components/authentication/Layout";
    export default {
        name: "AuthenticationV2",
        components: {Layout}
    }
</script>

<style scoped>

</style>