<template>
    <div class="loader">
        <Spinner name="three-bounce" :color="color" :noFadeIn="true" />
    </div>
</template>

<script>

    export default {
        name: "Pulse",
        props: {
            color: {
                type: String,
                default: '#e3e3e3'
            }
        }
    }
</script>

<style scoped>
.v-spinner {
    display:inline-block;
}

    .loader {
        text-align:center;
    }

</style>