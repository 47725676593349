<template>
	<b-row>
		<b-col sm="12">
			<div class="form-container">
				<b-form v-if="form.pages && form.pages.length > 0" class="form" @change="formChanged">
					<div class="form-page" v-for="(page, index) in form.pages" :key="index">
						<div v-if="index === form.pages.length -1" class="tags">
							<b-skeleton-wrapper :loading="!tagsLoaded">
								<template #loading>
									<b-skeleton width="100%"></b-skeleton>
								</template>
							</b-skeleton-wrapper>
							<div class="tag-entry__tour">
								<Tags v-model="tags" v-if="tagsLoaded" />
							</div>
						</div>
						<FormPage @handleTitleInput="handleTitleInput" @valueChanged="prePopulateFields"  :page="page" :form-data="formData" v-if="pageActivated(page)" :display-in-card="index === form.pages.length -1" />
					</div>
				</b-form>



				<PreviewEntryModal ref="previewModal" />
				<AutosaveModal ref="autosaveModal" @leave-without-saving="leaveRoute" @save-and-exit="sAndClose" />
			</div>
		</b-col>
	
			<b-col sm="12" class="mt-3 actions-container footer-buttons__tour">
				<div class="preview-button-container" v-b-tooltip.hover :title="previewHoverText">
					<b-button @click="previewEntry" class="mt-2 preview-entry" variant="link" :disabled="!this.isEditing || this.edited">Preview Entry</b-button>
				</div>
				<b-button class="mt-2 mb-0 ovr-button__inverted actions-save " @click="sAndContinue" variant="dark" v-b-tooltip.hover title="Save current work and continue editing">Save and Continue</b-button>
				<b-button @click="sAndClose" class="mt-2 ovr-button__inverted actions-save" variant="dark" v-b-tooltip.hover title="Save current work and exit">Save and Close</b-button>
			</b-col>
	</b-row>
</template>

<script>
import FormPage from './FormPage'
import { EventBus } from "../../event-bus";
import PreviewEntryModal from "../entries/PreviewEntryModal";
import ContentSaveEditIcon from "vue-material-design-icons/ContentSaveEdit";
import ContentSaveIcon from "vue-material-design-icons/ContentSave";
import AutosaveModal from "../entries/AutosaveModal";
import Tags from "@/components/form/Tags";

import Upload from "./Upload";
import TextField from "./TextField";
import Checkbox from "./Checkbox";
import DropdownFormItem from "@/components/form/DropdownFormItem.vue";

export default {
	name: "Form",
	props: { form: Object, loadForm: Function, entryId: [Number, String] },
	components: {DropdownFormItem, Upload, Checkbox, TextField, AutosaveModal, ContentSaveIcon, ContentSaveEditIcon, PreviewEntryModal, FormPage, Tags },
	data: function () {
		return {
      title:'',
			busy: false,
			edited: false,
			continueRoute: null,
			tags: [],
			tagsLoaded: false,
		}
	},
	computed: {
		additionalInformation() {
			return this.form.pages.filter(o => o.title.includes('Additional Information') && o.items.length === 3)[0].items;
		},
		formData: function () {
			return this.$formTools.flatten(this.form);
		},
		isEditing: function () {
			return this.entryId != null
		},
		previewHoverText() {
			if (!this.isEditing || this.edited) {
				return 'You must Save and Continue before you can preview the entry.';
			}
			return '';
		}
	},
	methods: {
    handleTitleInput(event)
    {
      this.$emit('handleTitleInput');
      this.prePopulateFields();
    },
    formattedDate(months)
    {
      let today = new Date();
      today.setMonth(today.getMonth() + months);
      let yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      return yyyy+'-'+mm+'-'+dd;
    },
		formChanged() {
			this.edited = true;
		},
    preSelectFieldsFor(activated_page_item,identifier,select,hide,reminder)
    {
      if(select)
      {
        this.setSelectedFor(activated_page_item,identifier,select);
      }

      if(hide)
      {
        this.setHiddenFor(activated_page_item,identifier,hide);
      }

      if(reminder)
      {
        this.setReminderFor(activated_page_item,identifier,reminder);
      }
    },
    setSelectedFor(activated_page_item,identifier,select)
    {
      if (this.getTitle().includes(identifier) &&
        select.some(element => activated_page_item.title.includes(' '+element+' ')))
      {
        activated_page_item.value = "1";
      }
    },
    setHiddenFor(activated_page_item,identifier,hide)
    {
      if (this.getTitle().includes(identifier) &&
          hide.some(element => activated_page_item.title.includes(' '+element+' ')))
      {
        activated_page_item.visible = false;
      }
    },
    setReminderFor(activated_page_item,identifier,months)
    {
      if(this.getTitle().includes(identifier) && activated_page_item.identifier === "DiaryReminderDate")
      {
        activated_page_item.value = this.formattedDate(months);
      }
    },
    getTitle()
    {
      return this.form.pages[0].items[0].value;
    },
    prePopulateFields: function()
    {
      let governingBody = document.querySelector('.governing-body__tour select').value;
      let excludedTitles = ['EPRR Standards', 'Diary - Additional Information'];

      if(governingBody !== 'EPRR - Humber')
      {
        return;
      }

      for (const page of this.form.pages)
      {

        if (!excludedTitles.includes(page.title))
        {
          continue;
        }

        for (const page_item of page.items)
        {

          if(page_item.identifier === "Field232")
          {
            page_item.value = "1";
          }

          let page_item_activators_keys = Object.keys(page_item.activators);

          for (const page_item_activator_key of page_item_activators_keys)
          {
            let page_item_activator = page_item.activators[page_item_activator_key];

            for(const activated_page_item of page_item_activator)
            {

              activated_page_item.value = "0";
              activated_page_item.visible = true;

              // EPRR01
              this.preSelectFieldsFor(
                 activated_page_item,
                'EPRR01',
                ['D2','H1','H2'],
                ['A2','G2','G3','G4'],
                36);

              // EPRR02
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR02',
                  ['E3'],
                  ['F1','G3','G4'],
                  36);

              // EPRR04
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR04',
                  ['A1', 'A2', 'A3'],
                  ['F1', 'G2', 'G3', 'G4'],
                  36
              );

              // EPRR07
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR07',
                  null,
                  null,
                  36
              );

              // EPRR08
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR08',
                  ['A1'],
                  ['G2', 'G3'],
                  36
              );

              // EPRR09
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR09',
                  null,
                  null,
                  12
              );

              // EPRR10
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR10',
                  null,
                  ['E3'],
                  null
              );

              // EPRR11
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR11',
                  null,
                  null,
                  36
              );

              // EPRR16
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR16',
                  ['G1'],
                  ['B1', 'C1', 'D1'],
                  36
              );

              // EPRR20
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR20',
                  ['H1', 'H2'],
                  null,
                  12
              );

              // EPRR21
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR21',
                  ['C1', 'D1'],
                  ['A3', 'F2', 'G1', 'G2', 'G3', 'G4'],
                  12
              );

              // EPRR22
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR22',
                  ['A3', 'B1', 'H1', 'H2'],
                  ['E3'],
                  12
              );

              // EPRR23
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR23',
                  ['E1', 'E2'],
                  ['A3', 'G4'],
                  12
              );

              // EPRR24
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR24',
                  null,
                  null,
                  12
              );

              // EPRR25
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR25',
                  ['E3', 'F1'],
                  null,
                  12
              );

              // EPRR27
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR27',
                  ['F2', 'G4', 'H2'],
                  ['A1', 'D1', 'E1', 'E2'],
                  12
              );

              // EPRR28
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR28',
                  ['A2', 'C1', 'E3', 'H1'],
                  ['G1'],
                  12
              );

              // EPRR30
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR30',
                  ['A3', 'G2'],
                  ['F1', 'G1', 'G4'],
                  12
              );

              // EPRR31
              this.preSelectFieldsFor(
                  activated_page_item,
                  'EPRR31',
                  ['A1', 'C1', 'E3', 'H1', 'H2'],
                  null,
                  12
              );

              // SHC01
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC01',
                  ['A1', 'A3', 'B1', 'H1', 'H2'],
                  ['G4'],
                  36
              );

              // SHC02
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC02',
                  ['B1'],
                  ['D2', 'F1', 'G4'],
                  36
              );

              // SHC03
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC03',
                  ['A1', 'A2'],
                  ['F1'],
                  36
              );

              // SHC06
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC06',
                  null,
                  null,
                  36
              );

              // SHC07
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC07',
                  ['A1'],
                  null,
                  36
              );

              // SHC08
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC08',
                  ['G1'],
                  ['D1', 'D2'],
                  36
              );

              // SHC09
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC09',
                  ['A2', 'B1', 'E3', 'G1', 'H2'],
                  null,
                  12
              );

              // SHC12
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC12',
                  null,
                  null,
                  12
              );

              // SHC13
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC13',
                  ['A3', 'B1', 'E3', 'G2', 'H1'],
                  null,
                  12
              );

              // SHC14
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC14',
                  ['A2', 'B1', 'C1', 'E3', 'F2', 'G4'],
                  null,
                  12
              );

              // SHC15
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC15',
                  ['B1'],
                  ['G1'],
                  12
              );

              // SHC16
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC16',
                  ['A1', 'A2', 'B1', 'C1', 'F2', 'H2'],
                  null,
                  12
              );

              // SHC18
              this.preSelectFieldsFor(
                  activated_page_item,
                  'SHC18',
                  null,
                  null,
                  12
              );

              // THC01
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC01',
                  ['D2'],
                  ['G4'],
                  36
              );

              // THC02
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC02',
                  ['F1', 'G4', 'H1'],
                  null,
                  36
              );

              // THC04
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC04',
                  ['A1', 'A2', 'A3'],
                  null,
                  36
              );

              // THC05
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC05',
                  ['A2'],
                  null,
                  12
              );

              // THC08
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC08',
                  ['A2', 'A3', 'C1', 'F1'],
                  ['G2'],
                  12
              );

              // THC09
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC09',
                  null,
                  null,
                  12
              );

              // THC10
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC10',
                  null,
                  null,
                  12
              );

              // THC11
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC11',
                  ['B1'],
                  null,
                  12
              );

              // THC12
              this.preSelectFieldsFor(
                  activated_page_item,
                  'THC12',
                  null,
                  null,
                  12
              );
            }
          }
        }
      }
    },
		sAndClose() {
			if (this.isEditing) {
				this.saveAndClose();
			} else {
				this.submitAndClose();
			}
		},
		sAndContinue() {
			if (this.isEditing) {
				this.saveAndContinue();
			} else {
				this.submitAndContinue();
			}
		},
		pageActivated: function (page) {
			if (!page.requiresActivation) return true;
			let data = this.formData;
			for (let key in page.activatedBy) {
				if (page.activatedBy.hasOwnProperty(key)) {
					if (data.has(key)) {
						let formValue = data.get(key);
						if (page.activatedBy[key].includes(formValue)) {
							return true;
						}
					}
				}
			}
			return false;
		},
		submitAndContinue() {
			this.submitEntry((entryId) => {
				let routeName = 'edit/' + this.form.identifier;
				this.$router.push({ name: routeName, params: { id: entryId } });
				this.$success('Entry saved successfully', 'You can continue make changes to it.');
			});
		},
		submitAndClose() {
			this.submitEntry(() => {
				EventBus.$emit('entry-submitted');
				EventBus.$emit('show-toast', { title: 'Entry Submitted', message: 'You can edit it under the \'Entries\' section' });
				this.$router.push({ name: 'entries' });
			});
		},
		submitEntry: function (completionFunction) {
			let data = this.$formTools.flatten(this.form);
			const obj = this.$formTools.mapToJson(data);

			
			let identifier = this.form.identifier === 'diary' ? 'Field222' : 'Field4'
			if (!obj[identifier].trim().length || !obj[identifier].trim().length) {
				this.$emit('show-message', { message: 'The title field is not provided', type: 'danger' });
				document.getElementById('main-header').scrollIntoView(({ behavior: "smooth", block: "start", inline: "nearest" }))
				return
			}
			
			this.busy = true;
			this.$http.post('entries/' + this.form.identifier, this.$formTools.mapToJson(data)).then((resp) => {
				this.$emit('loading-entry');
				EventBus.$emit('entry-submitted');
				this.edited = false;
				let entryId = resp.body.entryId;
				return this.$http.put(`entries/${entryId}/tags`, { tags: this.tags }).then(() => {
					completionFunction(resp.body.entryId);
				});
			}).catch((errResp) => {
				this.$emit('show-message', { message: errResp.body.message, type: 'danger' });
			}).finally(() => {
				this.busy = false;
			});
		},
		saveAndClose() {
			this.saveEntry(() => {
				EventBus.$emit('show-toast', { title: 'Entry saved successfully', message: 'You can edit it under the \'Entries\' section.' });
				this.$router.push({ name: 'entries' });
			});
		},
		saveAndContinue() {
			this.saveEntry(() => {
				this.loadForm();
				this.$success('Entry saved successfully', 'You can continue make changes to it.');
			});
		},
		saveEntry: function (completionFunction) {
			let data = this.$formTools.flatten(this.form);
			const obj = this.$formTools.mapToJson(data)

			let identifier = this.form.identifier === 'diary' ? 'Field222' : 'Field4'
			if (!obj[identifier].trim().length || !obj[identifier].trim().length) {
				this.$emit('show-message', { message: 'The title field is not provided', type: 'danger' });
				document.getElementById('main-header').scrollIntoView(({ behavior: "smooth", block: "start", inline: "nearest" }))
				return
			}

			this.busy = true;
			this.$http.post('entries/' + this.entryId + '/update', this.$formTools.mapToJson(data)).then(() => {
				this.$emit('loading-entry');
				this.edited = false;
				return this.$http.put(`entries/${this.entryId}/tags`, { tags: this.tags });
			}).then(() => {
				completionFunction();
			}).catch((errResp) => {
				this.$emit('show-message', { message: errResp.body.message, type: 'danger' });
				this.loadForm();
			});
		},
		previewEntry() {
			this.$refs.previewModal.show(this.entryId + '');
		},
		leavingRoute(to, from, next) {
			if (this.edited) {
				this.$refs.autosaveModal.show();
				this.continueRoute = next;
			} else {
				next();
			}
		},
		leaveRoute() {
			if (this.continueRoute) {
				this.continueRoute();
			}
		},
		loadEntryTags() {
			if (!this.entryId) {
				this.tagsLoaded = true;
				return;
			}
			this.tagsLoaded = false;
			this.$http.get(`entries/${this.entryId}/tags`).then((resp) => {
				this.tags = resp.body.tags;
			}).catch((err) => {
				this.$httpError('failed to load entry tags', err);
			}).finally(() => {
				this.tagsLoaded = true;
			});
		},

	},
	watch: {
		busy: function (newValue) {
			if (newValue === true) {
				this.$emit('loading-started');
			} else {
				this.$emit('loading-finished');
			}
		}
	},
	created() {
		this.loadEntryTags();
	}

}
</script>

<style>
.form-container label,
.form-container legend {
	font-weight: bold;
}
</style>

<style scoped lang="scss">
.form {
	width: 100%;
	margin-right: auto;
}

.form-container {
	width: 100%;

}

.diary-form__right {
	margin-top: 30px;
}

.actions-container {
	text-align: right;
}

.actions-save {
	background: $blue-300 !important;
    font-weight: 600;
	border-radius: 8px;
    padding: 0.375rem 22px;
	margin-left: 30px;
}

.preview-entry {
	color: $blue-300;
}

.preview-button-container {
	display: inline-block;
}

@media screen and (max-width: 950px) {
	.actions-container {
		text-align: center;
	}

	.actions-container > * {
		display: block;
		margin: 0 auto;
	}
}


</style>
