<template>
  <div>
    <button class="invinsible" @click="handleOpenModal"></button>
      <b-modal v-model="modalShown" id="evidenceRenameModal" ref="evidenceRenameModal" title="Rename evidence" :hide-header-close="true" :no-close-on-backdrop="true"
        ok-title="Rename" @ok="$emit('renameModal', evidence)">
      <b-form-group label="What would you like to rename this evidence to:" label-for="evidence-rename__input">
        <b-form-input v-model="val" id="evidence-rename__input" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'EvidenceRenameModal',
  data() {
    return {
      modalShown: false
    }
  },
  props: ['evidence'],
  methods: {
    handleOpenModal() {
      this.modalShown = true
    }
  },
  computed: {
    val: {
      get() {
        function truncateFileExtension(filename) {
          const lastDotIndex = filename?.lastIndexOf('.');
          if (lastDotIndex !== -1) {
            return filename?.substring(0, lastDotIndex).replaceAll('\+', ' ');
          }
          return filename.replaceAll('\+', ' '); // Return the original filename if no dot is found
        }
        
        if (!this.evidence?.newFilename?.length) {
          return truncateFileExtension(this.evidence?.name || this.evidence?.filename) 
        }

        return truncateFileExtension(this.evidence.newFilename)
      },
      set(v) {
        this.evidence.newFilename = v
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invinsible {
  display: none;
  visibility: hidden;
  opacity: 0;
}
</style>