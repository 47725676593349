import { render, staticRenderFns } from "./LoginOrganisation.vue?vue&type=template&id=066e210c&scoped=true"
import script from "./LoginOrganisation.vue?vue&type=script&lang=js"
export * from "./LoginOrganisation.vue?vue&type=script&lang=js"
import style0 from "./LoginOrganisation.vue?vue&type=style&index=0&id=066e210c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066e210c",
  null
  
)

export default component.exports