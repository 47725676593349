const state = {
    completed: {
        summary: false,
        activities: false,
        statement: false,
        evidence: false
    },
    evidence: [],
    entries: [],
    accessToken: ''
};

const getters = {

};

const mutations = {
    markCompleted_Summary: (state, val) => {
        state.completed.summary = val;
    },
    markCompleted_Activities: (state, val) => {
        state.completed.activities = val;
    },
    markCompleted_Statement: (state, val) => {
        state.completed.statement = val;
    },
    markCompleted_Evidence: (state, val) => {
        state.completed.evidence = val;
    },
    setAccessToken: (state, accessToken) => {
        state.accessToken = accessToken;
    },
    setEntries: (state, entries) => {
        state.entries = entries;
    },
    setEvidence: (state, evidence) => {
        state.evidence = evidence;
    }
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}