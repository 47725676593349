<template>
    <b-form-group  :description="item.helpText" v-if="!('visible' in item) || item.visible">
        <b-form-checkbox v-model="item.value"
                         value="1"
                         unchecked-value="0">
            {{item.title}}
        </b-form-checkbox>
    </b-form-group>
</template>

<script>
    import FormItem from "./FormItem";

    export default {
        name: "CheckboxFormItem",
        extends: FormItem
    }
</script>

<style>
    .custom-checkbox label.custom-control-label {
        font-weight: normal;
    }
</style>