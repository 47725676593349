<script>
    export default {
        name: "FormItem",
        props: ['item'],
        data() {
            return {
                showHelpText: false
            }
        },
        methods: {
            showHelp() {
                this.showHelpText = true;
            },
            hideHelp() {
                this.showHelpText = false;
            }
        },
        computed: {
            helpText() {
                if (this.showHelpText) {
                    if (this.item.helpText != null) {
                        return this.item.helpText.replace(/\n/g, '<br />').replace(/ {4}/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
                    }
                    return this.item.helpText;
                }
                return '';
            }
        }
    }
</script>

<style scoped>
.help-text {
    font-size: 10px;
}
</style>