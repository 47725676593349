<template>
    <div class="text-editor">
        <ckeditor ref="editorInstance" class="textarea-editor" :style="`height: ${multipleRows ? '180px' : 'unset'}`" v-model="editorValue" :editor="editor" :config="editorConfig"></ckeditor>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import InlineEditor from '@ckeditor/ckeditor5-build-inline';

    export default {
        name: "RichTextEditor",
        components: {
            ckeditor: CKEditor.component
        },
        props: {
            value: {
                type: String,
                required: true,
            },
            multipleRows: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        computed: {
            editorValue: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        data() {
            return {
                editor: InlineEditor,
                editorConfig: {
                    toolbar: [ 'bold', 'italic', '|', 'alignment', '|',  'undo', 'redo'],
                    alignment: {
                        options: [ 'left', 'right' ]
                    },
                    heading: {
                        options: [
                            { model: 'heading4', view: 'h4', title: 'Heading', class: 'ck-heading_heading4' },
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
                        ]
                    },
                    placeholder: 'Click here to start typing',
                    title: {
                        placeholder: 'Test'
                    }
                },
            }
        },
        methods: {

        },
        created() {
        }

    }
</script>

<style scoped>
    .textarea-editor {
        border: 1px solid #ced4da !important;
        border-radius: .25rem !important;
        height: 300px;
    }
</style>

