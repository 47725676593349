<template>
    <div class="webinar mb-2">
        <b-card :title="webinar.title" v-if="webinar">
            <b-card-text >
                <clock-icon></clock-icon> {{startDate}}  &nbsp;&nbsp;<b-badge variant="info">{{badgeLabel}}</b-badge>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
    import ClockIcon from "vue-material-design-icons/Clock";
    export default {
        name: "MyWebinarItem",
        components: {ClockIcon},
        props: ['webinar'],
        computed: {
            startDate() {
                return this.webinar.startDate.toLocaleString();
            },
            badgeLabel() {
                let moment = this.moment;
                let startDate = moment(this.webinar.startDate);
                let now = moment();
                let duration = moment.duration(startDate.diff(now));
                let days = Math.floor(duration.asDays());

                if (days === 0) {
                    return 'Today'
                } else {
                    return `In ${days} days`
                }
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>