<template>
    <div class="entry-preview">
        <b-modal title="Preview" header-bg-variant="info" header-text-variant="light" ref="modal" size="lg" scrollable>
            <pdf :resize="true" :src="pdfUrl" :page="currentPage" v-if="pdfUrl" @numpages="updatePageCount">
                <template slot="loading">
                    <Pulse />
                </template>
            </pdf>
            <template slot="modal-footer">
                <b-button variant="info" @click="print"><printer-icon></printer-icon> Print</b-button>
                <b-button :disabled="!canMoveBack" @click="previous"><chevron-left-icon /> Previous Page</b-button>
                <b-button :disabled="!canMoveForward" @click="next">Next Page <chevron-right-icon /></b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import Pulse from '../../components/loaders/Pulse'
    import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
    import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft";
    import PrinterIcon from "vue-material-design-icons/Printer";

    export default {
        name: "PreviewEntryModal",
        components: {
            ChevronLeftIcon,
            ChevronRightIcon,
            PrinterIcon,
            pdf: () => import('pdfvuer'),
            Pulse
        },
        data() {
            return {
                apiRoot: '',
                token: '',
                pdfUrl: '',
                numPages: 0,
                currentPage: 1
            }
        },
        methods: {
            show(entryId) {
                this.currentPage = 1;
                this.pdfUrl = null;
                this.pdfUrl = this.apiRoot + 'entries/' + entryId + '/pdf?auth_token=' + this.token;
                this.$refs.modal.show();
            },
            updatePageCount(pageCount) {
                this.numPages = pageCount;
            },
            next() {
                this.currentPage++;
            },
            previous() {
                this.currentPage--;
            },
            print() {
                this.$printUtils.printPDF(this.pdfUrl);
            }
        },
        computed: {
            canMoveForward() {
                return this.currentPage < this.numPages;
            },
            canMoveBack() {
                return this.currentPage > 1;
            }
        },
        created() {
            if (this.$store.state.session.override) {
              this.token = this.$store.state.authentication.override.loginToken;
            } else {
              this.token = this.$store.state.authentication.loginToken;
            }

            this.apiRoot = process.env.VUE_APP_API_ROOT;

        }
    }
</script>

<style scoped>

</style>
