<template>
    <div class="organisation-login">

        <form class="form-login" @submit.prevent="login" v-if="branding">
            <div class="logo-container" >
                <img class="organisation-logo" v-if="branding" :src="branding.loginLogoUrl" />
            </div>
            <div class="login-local" v-if="!branding.usesOauth">
                <b-form-group label="Email address">
                    <b-input type="text" name="email" v-model="email" />
                </b-form-group>
                <b-form-group label="Password">
                    <b-input type="password" name="password" v-model="password" />
                </b-form-group>
                <b-button type="submit" variant="primary" block :disabled="loading">Login</b-button>
                <router-link :to="{name: 'forgot-password'}"><b-button variant="default" :block="true">Forgot Password</b-button></router-link>
            </div>
            <div class="login-oauth" v-if="branding.usesOauth">
                <b-button type="button" @click="loginOauth()" v-if="!loading" variant="info" class="mt-4" block>Login</b-button>
                <div style="text-align: center" class="mt-4" v-if="loading">
                    <b-spinner />
                </div>
            </div>
        </form>



    </div>
</template>

<script>
    export default {
        name: "LoginOrganisation",
        data() {
            return {
                loading: false,
                branding: null,
                email: '',
                password: '',
                redirectTo: '',
            }
        },
        methods: {
            login: function() {
                this.loading = true;
                this.$http.post('auth/login', {email: this.email, password: this.password, temporary: true}).then(response => {
                    if (response.status === 200) {
                        let token = response.body.token;
                        let admin = response.body.roles.indexOf('Admin') > -1;
                        this.$store.dispatch('authentication/storeLoginDetails', {
                            token: token,
                            isAdmin: admin,
                            roles: response.body.roles
                        }).then(() => {
                            if (this.$route.params.nextUrl != null) {
                                this.$router.push(this.$route.params.nextUrl);
                            } else {
                                this.$router.push({name: 'dashboard-home'})
                            }
                        });
                    }
                }).catch((resp) => {
                    if (resp.status === 401) {
                        this.$error('Failed to login..', resp.body.message);
                    } else {
                        this.$httpError('Failed to login.', resp);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            loginOauth() {

                this.$http.get(`auth/external-login/${this.branding.organisationId}/start?redirect_to=${this.redirectTo}`).then(response => {
                    window.location.href = response.body.redirect;
                }).catch((resp) => {
                    this.$httpError('Failed to sign in', resp);
                    let error = resp.body.message || resp;
                    this.$ga.exception(error);
                });
            }
        },
        created() {
            this.redirectTo = encodeURI(process.env.VUE_APP_OAUTH_REDIRECT.replace('{organisationKey}', this.$route.params.organisationKey));
            let organisationKey = this.$route.params.organisationKey;
            this.$http.get(`branding/${organisationKey}`).then((resp) => {
                this.branding = resp.body;
            }).catch(() => {
                this.$error('Error', 'Your organisation is not enabled. Please contact support');
            }).then(() => {
                let code = this.$route.query.code;
                if (code) {
                    this.loading = true;
                    this.$http.get(`auth/external-login/${this.branding.organisationId}/process?code=${code}&redirect_to=${this.redirectTo}`).then((response) => {
                       //  alert(response.body);
                        if (response.status === 200) {
                            let token = response.body.token;
                            let admin = response.body.roles.indexOf('Admin') > -1;
                            this.$store.dispatch('authentication/storeLoginDetails', {
                                token: token,
                                isAdmin: admin,
                                roles: response.body.roles
                            }).then(() => {
                                if (this.$route.params.nextUrl != null) {
                                    this.$router.push(this.$route.params.nextUrl);
                                } else {
                                    this.$router.push({name: 'dashboard-home'})
                                }
                            });
                        }
                    }).catch((resp) => {
                        this.$httpError('Failed to login!', resp);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            });
        }
    }
</script>

<style scoped>
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
    .organisation-logo {
        max-width: 250px;
    }

    div.logo-container {
        margin-top: 100px;
        text-align: center;
        margin-bottom: 25px;
    }

</style>