<template>
    <b-modal ref="modal" title="Cancel Membership" header-bg-variant="warning" header-text-variant="dark" ok-title="Cancel" cancel-title="Close" ok-variant="warning"
             :ok-disabled="loading" :cancel-disabled="loading" size="md" @ok.prevent="doCancel">
        Are you sure you want to cancel your membership?


    </b-modal>
</template>

<script>
export default {
    name: "CancelMembershipModal",
    data() {
        return {
            membership: null,
            loading: false
        }
    },
    methods: {
        show(membership) {
            this.membership = membership;
            this.$refs.modal.show();
        },
        doCancel() {
            this.loading = true;
            this.$http.post('profile/membership/cancel').then(() => {
                this.$emit('membership-cancelled');
                this.$success('Membership Cancelled', 'Your membership has been cancelled and will no longer auto-renew.');
            }).catch((resp) => {
                this.$httpError('Failed to cancel membership', resp);
            }).finally(() => {
                this.loading = false;
                this.close();
            })
        },
        close() {
            this.$refs.modal.hide();
        }
    }
}
</script>

<style scoped>

</style>
