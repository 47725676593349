import Vue from 'vue';

const state = {
    loggedIn: false,
    admin: false,
    fullMember: false,
    loginToken: null,
    roles: [],
    override: null,
};

// getters
const getters = {
    isLoggedIn: (state) => {
        return state.loggedIn;
    },
    isAdmin: (state) => {
        return state.loggedIn && state.admin
    },
    isFullMember: (state) => {
        return state.fullMember;
    },
    isOrganisationManager: (state) => {
        if (state.roles) {
            return state.roles.indexOf('Organisation-Manager') > -1;
        }
        return false;
    },
    getToken: (state) => {
        return state.loginToken;
    },
    getOverrideToken: (state) => {
        if (state.override !== null) {
            return state.override.loginToken;
        }
        return null;
    },
    getRoles: (state) => {
        return state.roles;
    },
    getOverrideRoles: (state) => {
        if (state.override !== null) {
            return state.override.roles;
        }
        return [];
    }
};

// actions
const actions = {
    storeLoginDetails: ({commit}, {token, isAdmin, roles}) => {
        commit('storeLoginDetails', {token, isAdmin, roles});
        commit('setLoggedIn', true);
        commit('configureHttpClient');
    },
    storeOverrideLoginDetails: ({commit}, {token, roles}) => {
        commit('storeOverrideLoginDetails', {token, roles});
    },
    setFullMembershipStatus: ({commit}, {fullMember}) => {
        commit('setFullMembershipStatus', {fullMember});
    },
    logout: ({commit}) => {
        commit('setLoggedIn', false);
        Vue.http.headers.common['Authorization'] = null;
    },
    configureHttpClient: ({commit}) => {
        commit('configureHttpClient');
    }
};

// mutations
const mutations = {
    storeLoginDetails(state, {token, isAdmin, roles}) {
        state.loginToken = token;
        state.admin = isAdmin;
        state.roles = roles;
        state.loggedIn = true;
    },
    storeOverrideLoginDetails(state, {token, roles}) {
        state.override = {
            loginToken: token,
            roles: roles,
        };
    },
    setFullMembershipStatus(state, {fullMember}) {
        state.fullMember = fullMember;
    },
    setLoggedIn(state, loggedIn) {
        state.loggedIn = loggedIn;
        if (!loggedIn) {
            state.loginToken = null;
            state.admin = false;
        }
    },
    configureHttpClient: (state) => {
        
        if (state.loginToken != null) {
            Vue.http.headers.common['Authorization'] = 'Bearer ' + state.loginToken;
        } else {
            Vue.http.headers.common['Authorization'] = null;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}