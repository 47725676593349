<template>
    <div class="change-password">
        <b-modal ref="changePasswordModal" size="md" title="Change Password" header-bg-variant="primary" header-text-variant="light" no-close-on-backdrop no-close-on-esc>

            <b-alert show v-if="errorMessage" variant="danger">
                {{errorMessage}}
            </b-alert>

            <b-form-group label="Current Password">
                <b-input type="password" v-model="request.oldPassword" />
            </b-form-group>

            <b-form-group label="New Password">
                <b-input type="password" v-model="request.newPassword" @input="resetError"/>
            </b-form-group>

            <b-form-group label="Confirm New Password">
                <b-input type="password" v-model="confirmPassword" @input="resetError"/>
            </b-form-group>

            <div slot="modal-footer">
                <b-button variant="light" size="md" class="mr-2" @click="close" :disabled="loading">Cancel</b-button>
                <b-button variant="primary" size="md" @click="changePassword" :disabled="loading">Change Password</b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "ChangePasswordModal",
        data() {
            return {
                request: {
                    oldPassword: '',
                    newPassword: '',
                },
                errorMessage: '',
                confirmPassword: '',
                loading: false
            }
        },
        methods: {
            close() {
                this.reset();
                this.$refs.changePasswordModal.hide();
            },
            show() {
                this.$refs.changePasswordModal.show();
            },
            changePassword() {

                if (this.request.newPassword !== this.confirmPassword) {
                    this.errorMessage = 'The new passwords do not match. Please try again.';
                    return;
                }

                this.loading = true;
                this.$http.post('auth/change-password', this.request).then(() => {
                    this.$success('Password Changed', 'Your password was changed successfully');
                    this.close();
                }).catch((resp) => {
                    this.$error('Failed to change password', resp.body.message);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.request.oldPassword = '';
                this.request.newPassword = '';
                this.confirmPassword = '';
                this.loading = false;
                this.resetError();
            },
            resetError() {
                this.errorMessage = '';
            }
        }
    }
</script>

<style scoped>

</style>
