<template>
    <div>
        <Pulse v-if="loading" color="#777777" />
        <ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
        <h4 class="dashboard-pages__title">Portfolio Builder - Choose Type</h4>
        <b-card-group deck>
            <b-card>
                <b-card-title>
                    <h4 class="dashboard-pages__title">Portfolio</h4>
                </b-card-title>
                <b-card-text>Create a print-ready portfolio that you can submit to your employer or governing
                    organisation.</b-card-text>
                <template v-slot:footer>
                    <b-button class="ovr-button" variant="info" @click="standardPortfolio">Create Portfolio</b-button>
                </template>
            </b-card>
            <b-card>
                <b-card-title>
                    <h4 class="dashboard-pages__title">HCPC Audit</h4>
                </b-card-title>
                <b-card-text>
                    Access your personalised HCPC Portal to be guided through the process of a HCPC audit.
                </b-card-text>
                <p></p>
                <p>We'll email you a link with access to the portal</p>
                <template v-slot:footer>
                    <b-button class="ovr-button" variant="info" @click="hcpcPortal">Request Access</b-button>
                </template>
            </b-card>
        </b-card-group>
        <HCPCPortalCreateModal ref="hcpcPortalModal" />
        <PageTour :isTour="false" title="How it works" ref="pageTourRef" description="Once you have submitted and collated all of your continuing professional development, you are probably going to want to print or share this digitally. This really is your time to show off all your learning! <br><br> Depending on your regulatory or governing body, you may also have an additional portal here, for example the HCPC Audit Portal. This will format all of your CPD in the specific format required for your governing body in order to reduce the pressure of submitting your CPD profile. <br> <br> Remember that as a premium member, you have unlimited support and access to our amazing team of professionals. <br><br> Click the Create Portfolio section to get started: From here you can select what you wish to include or exclude in your CPD Portfolio." @handleShowTour="handleShowTour"  />
    </div>
</template>

<script>
    import Pulse from "@/components/loaders/Pulse";
    import HCPCPortalCreateModal from "@/components/portfolio/HCPCPortalCreateModal";
    import PageTour from "@/components/common/PageTour";
    import ShowTourButton from "../../components/common/ShowTourButton";

    export default {
        name: "PortfolioChoice",
        components: {HCPCPortalCreateModal, Pulse, PageTour, ShowTourButton},
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            handleShowTour() {
                this.$tours['portfolioChoiceTour'].start()
            },
            loadProfileInfo() {
                this.loading = true;
                this.$http.get('profile/governing-bodies').then((resp) => {
                    if (!resp.body.includes('HCPC')) {
                        this.standardPortfolio();
                    }
                }).catch((resp) => {
                    this.$httpError('Failed to load profile', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            standardPortfolio() {
                this.$router.push({name: 'portfolio-builder'});
            },
            hcpcPortal() {
                this.$refs.hcpcPortalModal.show();
            }
        },
        created() {
            this.loadProfileInfo();
        }
}
</script>

<style scoped></style>
