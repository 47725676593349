<template>
    <div class="login-form-container">
        <Logo />
        <b-form class="form-forgot-password" @submit.prevent="submit" >
            <h1 class="h3 mb-3 font-weight-normal">Forgot Password</h1>
            <p>Forgotten your password? Enter your email address below and we'll send you a link to set a new one.</p>
            <b-alert :show="alert != null" dismissible variant="danger">{{ alert }}</b-alert>

            <b-form-group label="Email Address">
                <b-form-input v-model="emailAddress" type="email" />
            </b-form-group>

            <b-button type="submit" variant="info" block>Reset Password</b-button>
            <a @click.prevent="goBack" href="#"><b-button variant="default" block>Back to login</b-button></a>
        </b-form>
    </div>
</template>

<script>
    import Logo from "../../components/authentication/Logo";
    export default {
        name: "ForgotPassword",
        components: {Logo},
        data() {
            return {
                emailAddress: '',
                alert: null,
                prevRoute: null
            }
        },
        methods: {
            submit() {
                this.$http.post('auth/reset', {
                    email: this.emailAddress
                }).then(() => {
                    this.$success('Password Reset', 'Password Reset Requested. Please check your emails and follow the instructions.')
                    this.goBack()
                }).catch((resp) => {
                    this.alert = resp.body.message;
                });
            },
            goBack() {
                if (this.prevRoute != null) {
                    this.$router.push(this.prevRoute);
                } else {
                    this.$router.push({name: 'login'})
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            });
        },
    }
</script>

<style scoped>
    .form-forgot-password {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>
