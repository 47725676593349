<template>
    <div class="previous-review">
        <b-card>
            <template v-slot:header>
                <div class="text-left" style="display:inline">
                    {{title}}
                </div>
                <div class="text-right ml-4" style="display:inline">
                    <b-badge variant="success" pill v-if="review.metRequirements">Met Requirements</b-badge>
                    <b-badge variant="warning" pill v-if="!review.metRequirements">Not Met Requirements</b-badge>
                </div>
            </template>
            <p>{{review.comments}}</p>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "PreviousReview",
        props: {
            review: {
                type: Object,
                required: true,
            }
        },
        computed: {
            title() {
                // if (this.review.type === 'Annual') {
                    return 'Annual Review - ' + this.friendlyDate;
                // } else {
                //     return 'Interim Review - ' + this.friendlyDate;
                // }
            },
            friendlyDate() {
                return this.moment(this.review.date).format('DD/MM/YYYY');
            }
        }
    }
</script>

<style scoped>

</style>