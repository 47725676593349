<template>
    <div>
        <b-modal ref="modal" :title="modalTitle" header-bg-variant="primary" header-text-variant="light" :ok-title="okTitle" @ok="ok">
            {{ message }}
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ConfirmModal",
    props: {
        okTitle: {
            type: String,
            required: false,
            default: () => 'Yes'
        },
        message: {
            type: String,
            required: false,
            default: () => 'Are you sure you wish to do this?'
        }, 
        modalTitle: {
            type: String,
            required: false,
            default: () => 'Confirm'
        }
    },
    methods: {
        ok() {
            this.$emit('ok');
        },
        show() {
            this.$refs.modal.show();
        }
    }
}
</script>

<style scoped>

</style>
