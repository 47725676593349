<template>
  <div class="h-100">
    <div v-if="loading">
      <Pulse loading v-if="loading" class="loader" color="#999999"></Pulse>
    </div>

    <div v-else class="email-change__container">
      <div v-if="tokenVerifiedSuccessfully" class="redirect-section">
        <b-alert show v-if="tokenVerifiedSuccessfully" variant="success">
          Email changed successfully
        </b-alert>
      </div>


      <div>
        <b-alert show v-if="errorMessage" variant="danger">
          {{ this.errorMessage }}
        </b-alert>

        <div v-if="tokenVerifiedSuccessfully">
          <div class="mb-2">
            <Pulse loading v-if="redirecting" class="loader" color="#999999"></Pulse>
          </div>
          <p class="mb-4 mt-4 text-center">
            You will shortly be directed to the login page...
          </p>
        </div>

        
  
        <b-row>
          <b-col md="12" class="p-0">
            <b-button @click="goToLogin" variant="primary" type="submit" class="button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Login</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Pulse from '../../components/loaders/Pulse'
import settings from '../../store/modules/settings'

export default {
  name: "ForgotPasswordV2",
  components: { Pulse },
  data() {
    return {
      token: '',
      loading: false,
      redirecting: false,
      tokenVerifiedSuccessfully: false,
      errorMessage: '',
    }
  },
  methods: {
    handleRedirect() {
      this.logout()
    },
    logout() {
      this.$store.dispatch('authentication/logout').then(() => {
        this.goToLogin()
			});
		},
    goToLogin() {
      this.$router.push({ name: 'login-v2' });
    },
    getToken() {
      if (this.$route.params && this.$route.params.token) {
        this.token = this.$route.params.token;
      } else {
        this.errorMessage = "A verification code has not been provided in the url"
        this.loading = false
        return
      }
    },
    verifyToken() {
      this.$http.post(`profile/change-email/${this.token}`).then((resp) => {
        this.tokenVerifiedSuccessfully = true
        setTimeout(() => {
          this.redirecting = true
        }, 200);
        setTimeout(() => {
          this.logout()
        }, 3000);
      }).catch((resp) => {
        this.errorMessage = resp.body.message;
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.loading = true
    this.getToken()
    this.verifyToken()
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/auth/auth.css';

.email-change__redirect {
  font-weight: bold;
  text-decoration: underline;
}

.email-change__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
</style>
