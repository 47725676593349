<template>
  <b-form-group class="search-form__group search-tour">
    <b-input-group>
      <b-input type="text" :placeholder="placeholder" class="search-input" v-model="val" :disabled="disabled" :readonly="readonly" />
      <b-input-group-append class="append-input__icon">
        <b-input-group-text>
          <span class="d-flex" :role="handleClick ? 'button' : 'main'" @click="handleClick">
            <search-icon></search-icon>
          </span>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    incomingValue: {
      required: true,
      type: String
    },
    handleClick: {
      required: false,
      type: Function,
      default: () => {}
    },
    placeholder: {
      required: false,
      type: String
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    val: {
      get: function() {
          return this.incomingValue
      },
      set: function(value) {
        this.$emit('updateVal', value)
      }
    }
  }
}
</script>