<template>
    <div class="shared-cpd-item">
        <b-card class="cpd-card" body-class="cpd-card__body">
            <span class="attribute date desktop-date">{{date}}</span>

            <b-row>
                <b-col sm="4" class="user-section">
                    <ProfileImage :width="40" :profile-image-url="avatarUrl"/>
                    <span class="attribute name">{{nickname}}</span>
                </b-col>
                <b-col sm="8" class="cpd-card__right">
                    <b-card-title class="sharedcpd-card__title" :style="`font-size: ${titleFontSize}px`">{{title}}</b-card-title>
                    <b-card-text class="sharedcpd-card__description" :style="`font-size: ${descriptionFontSize}px`">
                        <ReadMore v-if="descriptionAsReadmore" :max-chars="200" :text="description" />
                        <p v-else>{{description}}</p>
                    </b-card-text>
                </b-col>
            </b-row>

            <div class="cpd-card__category d-flex justify-content-end">
                <b-badge class="mt-2 category-badge">{{category}}</b-badge>
            </div>
            <hr class="mt-4 mb-2">
            <div class="cpd-card__footer d-flex justify-content-between align-items-center mb-2">
                <div class="helpful-container">
                    <a class="helpful" v-bind:class="{'is-helpful': internalHasUpvoted}" v-if="showHelpful" href="#" @click.prevent="toggleHelpful" v-requires-role="'User-Basic'"> <thumb-up-outline v-if="!internalHasUpvoted"></thumb-up-outline> <thumb-up v-if="internalHasUpvoted"></thumb-up> Helpful </a>
                    <b-badge :variant="helpfulCountVariant" class="mr-2 helpful-count" pill >{{internalUpvotes}}</b-badge>
                </div>
                <b-link v-if="websiteUrl" :href="websiteUrl" class="web-link" target="_blank"><open-in-new></open-in-new> Visit Website</b-link>
            </div>
        </b-card>
    </div>
</template>

<script>
    import ProfileImage from "../profile/ProfileImage";
    import OpenInNew from 'vue-material-design-icons/OpenInNew';
    import ReadMore from "../ReadMore";
    import ThumbUp from 'vue-material-design-icons/ThumbUp';
    import ThumbUpOutline from 'vue-material-design-icons/ThumbUpOutline';

    export default {
        name: "SharedCPDListItem",
        components: {ReadMore, ProfileImage, OpenInNew, ThumbUpOutline, ThumbUp},
        props: {
            sharedCpdId: {
                type: Number,
                required: false
            },
            titleFontSize: {
                type: Number,
                required: false
            },
            descriptionFontSize: {
                type: Number,
                required: false
            },
            avatarUrl: {
                type: String,
                required: true
            },
            nickname: {
                type: String,
                required: true
            },
            date: {
                type: String,
                required: true
            },
            category: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            websiteUrl: {
                type: String,
                required: false
            },
            descriptionAsReadmore: {
                type: Boolean,
                defaultValue: true
            },
            helpful: {
                type: Boolean,
                defaultValue: false
            },
            hasUpvoted: {
                type: Boolean,
                defaultValue: false
            },
            upvotes: {
                type: Number,
                defaultValue: 0,
                required: false
            },
            showHelpful: {
                type: Boolean,
                defaultValue: false
            }

        },
        data: function() {
            return {
                internalUpvotes: 0,
                internalHasUpvoted: false
            }
        },
        methods: {
            toggleHelpful() {
                if (this.internalHasUpvoted) {
                    this.$http.delete(`shared-cpd/${this.sharedCpdId}/upvote`).then((resp) => {
                        this.internalUpvotes = resp.body.currentUpvotes;
                        this.internalHasUpvoted = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to remove upvote', resp);
                    });
                } else {
                    this.$http.post(`shared-cpd/${this.sharedCpdId}/upvote`).then((resp) => {
                        this.internalUpvotes = resp.body.currentUpvotes;
                        this.internalHasUpvoted = true;
                    }).catch((resp) => {
                        this.$httpError('Failed to upvote', resp);
                    })
                }
            }
        },
        computed: {
            helpfulCountVariant() {
                return this.internalHasUpvoted ? 'success' : 'light';
            },
        },
        created() {
            this.internalUpvotes = this.upvotes;
            this.internalHasUpvoted = this.hasUpvoted;
        }
    }
</script>

<style scoped lang="scss">
    .user-section {
        text-align: center;
        display: flex;
    flex-direction: column;
    justify-content: center;
    }

    .attribute {
        display:block;
    }

    .name {
        margin-top: 5px;
        font-weight: bold;
        font-size: 14px;
    }

    .date {
        font-weight: lighter;
        font-size: 14px;
        font-weight: 500;
        color: $blue-500;
    }

    .cpd-card__body {
        padding-top: 0;
        padding-bottom: 0;
    }
    
    .cpd-card__right {
        padding: 40px 8px 4px 8px;
    }

    .cpd-card {
        border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }

    a.web-link {
        color: $blue-300;
        font-size: 14px;
        font-weight: 500;
    }

    .category-badge {
        display:block;
        word-wrap: normal;
        white-space: normal;
        background-color: $blue-300 !important;
        padding: 4px 12px;
        border-radius: 6px;
    }

    .helpful {
        font-size: 14px;
        color: #555555;
        margin-right: 8px;
    }

    .helpful.is-helpful {
        color: $green;
    }

    .helpful-count {
        font-size: 12px;
    }

.sharedcpd-card__title {
    color: $blue-900;
    line-height: 1;
    letter-spacing: -1px;
}

.sharedcpd-card__description {
    line-height: 1;
}

.helpful-container {
    display: flex;
    align-items: center;
}

.desktop-date {
    position: absolute;
    right: 8px;
    top: 8px;
}

@media (max-width: 1024px) {
    .user-section {
        margin-top: 16px;
    }
	}
</style>