<template>
    <div class="page no-select" :style="pageStyle">
        <img v-if="isCpdLogoWhiteText" class="header-logo" src="../../assets/logo-white-text.png">
        <img v-else class="header-logo" src="../../assets/logo-transparent.png">
        <h2 style="margin-top: 43px;">{{name}}</h2>
        <h3>{{jobTitle}}</h3>

        <h2 style="margin-top: 53px;" >{{firstLine}}</h2>
        <h2 style="margin-top: 5px;">{{secondLine}}</h2>
    </div>
</template>

<script>
    export default {
        name: "PortfolioFrontPagePreview",
        props: {
            title: {
                type: Object
            },
            selectedPortfolioBackground: {
                type: Number,
                required: false
            }
        },
        data: function() {
            return {
                width: 200,
                name: '',
                jobTitle: '',
            }
        },
        methods: {
            loadProfileInfo() {
                this.$http.get('profile').then((resp) => {
                    this.name = resp.body.firstName + ' ' + resp.body.lastName;
                    this.jobTitle = resp.body.jobTitle || '\xa0';
                })
            }
        },
        computed: {
            pageStyle() {
                let backgroundImage ='none';
                let textColor = '#000000';

                if(this.selectedPortfolioBackground) {
                        backgroundImage =`url('/img/portfolio/portfolioBackground${this.selectedPortfolioBackground}.jpg')`

                        if (this.selectedPortfolioBackground === 9 || this.selectedPortfolioBackground === 10) {
                            textColor = '#ffffff'
                        }
                }

                return {
                    width: this.width + 'px',
                    height: (this.width * 1.414) + 'px',
                    boxShadow: '0px 5px 8px -6px rgba(0,0,0,0.75)',
                    backgroundImage: backgroundImage,
                    backgroundSize: 'contain', 
                    color: textColor,
                }
            },
            firstLine() {
                if (this.title.line1) {
                    return this.title.line1;
                }
                return '\xa0'
            },
            secondLine() {
                if (this.title.line2) {
                    return this.title.line2;
                }
                return '\xa0'
            },
            isCpdLogoWhiteText() {
                if (this.selectedPortfolioBackground === 4 || this.selectedPortfolioBackground === 9 || this.selectedPortfolioBackground === 10) {
                            return true;
                }
            }

        },
        created() {
            this.loadProfileInfo();
        }
    }
</script>

<style scoped>

    .page {
        background-color: white;
        text-align: center;
        font-family: Arial,serif;
    }

    .page h2 {
        font-size: 10px;
        margin-bottom: 0;
        font-weight: normal;
    }

    .page h3 {
        padding-top: 0;
        margin-top: 0;
        font-size: 7px;
    }

    .header-logo {
        width: 40px;
        margin-top: 8px;
    }

</style>