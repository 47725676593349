<template>
    <img alt="Profile Picture" :src="profileImageUrl" class="profile-image" :style="imgStyle"/>
</template>

<script>
    export default {
        name: "ProfileImage",
        props: {
            profileImageUrl: {
                required: false,
                type: String
            },
            width: {
                required: false,
                type: Number,
                default: 120
            }
        },
        computed: {
            imgStyle() {
                return {
                    'width': this.width + 'px',
                    'height': this.width + 'px',
                    '-webkit-border-radius': Math.floor(this.width / 2) + 'px',
                    '-moz-border-radius': Math.floor(this.width / 2) + 'px',
                    'border-radius': Math.floor(this.width / 2) + 'px',
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .profile-image {
        border-color: $blue-300;
        border-width: 1px;
        border-style: solid;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>