<template>
    <b-card  header-bg-variant="white" header-class="custom-card__header" :header="title" style="background-color: white;">
        <Pulse v-if="loading" />
        <div ref="barChartCard" class="entryfrequency-graph" v-else>
            <BarGraph ref="barChart" :height="graphHeight" :chart-data="chartData" :options="options"/>
        </div>
    </b-card>
</template>

<script>

    import BarGraph from "./graphs/BarGraph";
    import Pulse from "./loaders/Pulse";
    export default {
        name: "BarChartWidget",
        components: {Pulse, BarGraph},
        props: {
            title: {
                required: true, 
                type: String, 
            },
            showLegendOnMobile: {
                required: true, 
                type: Boolean, 
            },
            graphData: {
                required: true, 
                type: Array,
            },
            browserWidth: {
                required: true,
                type: Number, 
            },
            graphHeight: {
                required: true,
                type: Number, 
            }
        },
        data() {
            return {
                colours: Array(3).fill(['#1B96C6', '#EF767A', '#456990', '#19BCBD', '#49DCB1', '#EEB868']).flat(),
                loading: false,
            }
        },
        methods: {
            renderTooltipTitle(tooltipItem) {
                const itemIndex = tooltipItem[0].index;
                const tooltipText = this.graphData[itemIndex].tooltipText;
                return this.$utilities.convertTextToLines(tooltipText, 5) 
            },
            generateLegendLabels() {
                let labels = []
                const categories = this.chartData.labels
                const colours = this.colours

                categories.forEach((category, index) => {
                    labels.push({text: category.join(" "), fillStyle: colours[index]})
                })
                return labels;
            }
        },
        watch: {
            browserWidth() {
                this.$refs.barChart.render()
            },
            graphHeight() {
                this.$refs.barChart.render()
            }
        },
        computed: {
            chartData() {
                let dataPoints = [];
                let categories = [];

                for (let i = 0; i < this.graphData.length; i++) {
                    dataPoints.push(this.graphData[i].value);

                    let label = this.graphData[i].key.split(" "); 
                    categories.push(label);
                }

               return {
                        datasets: [
                            {
                                data: dataPoints,
                                backgroundColor: this.colours,
                            }
                        ],
                        labels: categories
                    };
            },
            showLegend() {
                return this.showLegendOnMobile && this.browserWidth < 510;
            },
            options() {
                return {
                    animation: {
                        duration: 0,
                    },
                 
                    responsive: true,
                    legend: {
                        display: this.showLegend,
                        labels: {
                            generateLabels: this.generateLegendLabels,
                        }
                    },
                    tooltips: {
                        callbacks: {
                            title: this.renderTooltipTitle
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            }, 
                            ticks: {
                                autoSkip: false,
                                display: !this.showLegend
                            }
                        }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1
                                },
                                gridLines: {
                                    display:false
                                }
                            },
                        ]
                    }, 
                }
            }
        },
    }
</script>

<style scoped>

</style>