const PrintUtils = {};
import printJS from "print-js/src/index.js";

PrintUtils.install = function (Vue, _) {

    let printUtils = {};

    printUtils.printPDF = function(url) {
        printJS({printable: url, type: 'pdf', showModal: true});
    }

    Vue.prototype.$printUtils = printUtils;

};

export default PrintUtils;
