<template>
    <transition name="fade" mode="out-in">
        <div v-if="showing" :class="{[overlayClass]: overlay}">
            <slot name="loading-container">

                <div :class="loaderClass">
                    <slot name="loading-spinner">
                        <div class="wrapper" >
                            <img class="logo" src="../assets/cpdme-logo-white.png" />
                            <!--<moon-loader :loading="true" color="#FFFFFF" size="100px"></moon-loader>-->
                            <Spinner name="three-bounce" color="white"/>
                        </div>
                    </slot>
                    <slot name="loading-text">
                        <p class="loading-text">{{ label }}</p>
                    </slot>
                </div>
            </slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "LoadingOverlay",
        props: {
            label: {
                default: 'Loading...'
            },
            show: {
                default: false
            },
            overlay: {
                default: true
            },
            loaderClass: {
                default: 'loader-wrapper'
            },
            overlayClass: {
                default: 'blue-overlay'
            },
            // central event bus
            eventBus: {
                default: null
            },
            eventShow: {
                default: 'show-full-loading'
            },
            eventHide: {
                default: 'hide-full-loading'
            }
        },
        data() {
            return {
                showing: false
            }
        },
        watch: {
            show: {
                handler(val) {
                    this.showing = val;
                }
            }
        },
        methods: {
            showMe() {
                this.showing = true
            },
            hideMe() {
                this.showing = false
            },
            // Register eventBus methods.
            registerBusMethods()
            {
                this.eventBus.$on(this.eventShow, this.showMe);
                this.eventBus.$on(this.eventHide, this.hideMe);
            }
        },
        mounted() {
            // If event bus, register methods.
            if (this.eventBus)
                this.registerBusMethods();
            this.showing = this.show;

        }
    }
</script>
<style scoped>
    .white-overlay {
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 9999;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
    }

    .blue-overlay {
        /*background-color: #2c3e50;*/
        background-color: #5f7da6;
        z-index: 9999;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
        color: white;
    }

    .loader-wrapper {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        width: 300px;
        height: 200px;
        margin: -100px 0 0 -150px;
        text-align: center;
        align-items: center;
    }

    .pulled-left {
        float: left;
        padding: 10px 20px
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    .loading-text {
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        color: white;
    }

    .wrapper {
        margin-left: auto;
        margin-right: auto;
        /*width: 100px;*/
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s
    }


    .fade-leave-to {
        opacity: 0
    }

    .fade-enter {
        opacity: 1;
    }

    img.logo {
        width: 175px;
        margin-bottom: 20px;
    }



</style>
