<template>
	<b-form-group class="bold-label" :label="title">
		<b-input :type="type" v-model="item.value" :state="fieldState()" autocomplete="off" @focus="showHelp()"
			@blur="hideHelp()" />
		<i v-if="this.isHoursEntry">{{ this.hoursDisplay }}</i>
		<b-form-invalid-feedback v-if="item.required">
			Required field
		</b-form-invalid-feedback>
		<p class="help-text mt-2" v-html="helpText"></p>
	</b-form-group>
</template>

<script>
import FormItem from './FormItem.vue'

export default {
	name: "FreeTextFormItem",
	props: {
		type: {
			required: false,
			default: 'text'
		}
	},
	methods: {
		fieldState: function () {
			if (!this.item.required) {
				return undefined;
			}
			return this.item.value !== '';
		}
	},
	computed: {
		title: function () {
			if (this.item.required) {
				return this.item.title + ' *';
			}
			return this.item.title;
		},
		isHoursEntry: function () {
			if (this.item.title.includes('Hours')) {
				return true;
			}
			return false;
		},
		hoursDisplay: function () {
			if (this.isHoursEntry) {
				return this.$utilities.hoursToString(this.item.value);
			}
			return '';
		},
	},
	extends: FormItem,

}
</script>

<style scoped lang="scss">
.bold-label {
	font-weight: 600;
}

.help-text {
	font-size: 12px;
}
</style>