<template>
	<div class="files">

		<b-row class="mb-4" v-requires-role="'User-Basic'">
			<b-col sm="12">
				<ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
			</b-col>
			<b-col cols="12">
				<b-row class="m-0 align-items-start">
					<b-col class="pl-0" sm="12" md="5" xl="9">
						<h4 class="dashboard-pages__title mb-0">This is where all your uploaded evidence is stored</h4>
					</b-col>

					<b-col class="pr-0 pl-0 mt-2 search-bar__col" sm="12" md="7" xl="3">
						<b-form :inline="true" @submit="doSearch">
							<SearchBar placeholder="Search..." @updateVal="(val) => filter = val" :incomingValue="filter" :readonly="isBusy" :handleClick="doSearch" />
						</b-form>
					</b-col>
					
				</b-row>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" class="mt-2">
				<b-card>
					<b-row>
						<b-col class="data-table__parent mb-2" sm="12" xl="8">
							<b-table :key="tableKey" class="data-table" thead-class="table-header" tbody-tr-class="table-body__tr"
								tbody-class="table-body" ref="entriesTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:busy.sync="isBusy" striped :items="filesProvider" :fields="fields" :per-page="perPage"
								:current-page="currentPage" :show-empty="!isBusy">
								<template #empty>
     								 <b-alert show style="text-align:center">No files uploaded</b-alert>
    							</template>
								<template v-slot:cell(filename)="data">
									<div class="myfiles-table__title">
										<p class="mb-0" v-b-tooltip.hover="data.item.filename.replaceAll('\+', ' ')">{{ data.item.filename.replaceAll('\+', ' ') }}</p>
									</div>
								</template>
								<template v-slot:cell(url)="data">
									<div :class="itemBeingHovered.id === data.item.id ? 'preview-being__hovered' : 'image-section__table'">
										<b-img v-if="isFileImage(data.item.filename)" class="evidence-url" :src="data.item.url"
											@mouseenter="handleMouseEnter(data.item)" @mouseleave="handleMouseLeave"></b-img>
										<div v-else-if="isFilePDF (data.item.filename)" class="grey-box" @mouseenter="handleMouseEnter(data.item)" @mouseleave="handleMouseLeave">
											<pdf style="width: 100%;" :resize="true" :src="data.item.url"></pdf>
										</div>
										<b-img v-else class="evidence-url" src="/img/no-image-available.jpeg"
											@mouseenter="handleMouseEnter(data.item)" @mouseleave="handleMouseLeave"></b-img>
									</div>
								</template>

								<template v-slot:cell(dateUploaded)="data">
									<div class="date-uploaded__tour">
										{{ data.item.dateUploaded }}
									</div>
								</template>

								<template v-slot:cell(actions)="data">
									<div class="actions justify-content-between d-flex" style="width: 320px;">
										<b-button @click="openRenameEvidenceModal(data.item)"
											class="mb-0 text-secondary ovr-button rename-files__tour my-files__table-preview">
											 <pencil-outline-icon></pencil-outline-icon> Rename
										</b-button>
										<b-button @click="openEvidence(data.item)"
											class="mb-0 text-secondary ovr-button preview-files__tour my-files__table-preview">
											<eye-outline-icon></eye-outline-icon> Preview
										</b-button>
										<b-button @click="deleteFile(data.item)" class="delete-files__tour mb-0 ovr-button__delete my-files__table-preview"
											v-requires-role="'User-Basic'"><delete-outline-icon></delete-outline-icon> Delete</b-button>
									</div>
								</template>
								<template v-slot:cell(attached-to)="data">
									<a v-if="data.item.entryId != null" href="#" @click.prevent="editAttachedEntry(data.item)"><b-badge
											class="type-of__data-badge type-of__data-badge-color attached-to__tour" v-b-tooltip.hover.top="data.item.entryTitle">{{
												$utilities.capitalise(data.item.entryType) }}
											Entry</b-badge></a>
									<b-badge variant="danger" class="type-of__data-badge" v-else>None</b-badge>
								</template>
							</b-table>
						</b-col>
						<b-col sm="12" xl="4" class="mb-2">
							<b-row>
								<b-col sm="12" class="mb-2">
									<b-card body-class="preview-card__body">
										<h5 class="card-title__bold">Preview</h5>
										<small class="file-name" v-if="!!itemBeingHovered">{{ itemBeingHovered.filename.replaceAll('\+', ' ')
										}}</small>
										<div v-if="!!itemBeingHovered" ref="previewBox" class="previewing-image__container preview-being__hovered">
											<pdf style="width: 100%;" :key="itemBeingHovered.url" v-if="isFilePDF(itemBeingHovered.filename)" :resize="false" :src="itemBeingHovered.url"
												class="previewing-image"></pdf>
											<b-img v-else-if="isFileImage(itemBeingHovered.filename)" :src="itemBeingHovered.url" class="w-100 previewing-image"></b-img>
											<b-img v-else src="/img/no-image-available.jpeg" class="w-100 previewing-image"></b-img>
										</div>

									</b-card>
								</b-col>
								<b-col sm="12" class="mb-2">
									<b-card body-class="preview-card__body upload-file__tour">
										<h5 class="card-title__bold">Upload File</h5>
										<b-form-file class="mb-2" ref="fileInput" v-model="selectedFile" />
										<p class="disclaimer">By uploading this file, you confirm that the information provided above is a
											true reflection of your personal development and does not contain any person, patient, client or
											service user identifiable data that could breach data protection laws or legislation relating to
											your employer, governing bodies, professional organisations, code of conduct and associated
											revalidation guidance.</p>
										<b-progress v-if="uploading" :value="uploadPercentage" :max="100" variant="info" class="mb-2"
											show-progress animated />
										<b-button type="submit" variant="outline-info" @click="uploadFile"
											:disabled="uploading || !selectedFile">{{ uploadButtonText }}</b-button>
									</b-card>
								</b-col>
							</b-row>
						</b-col>
					</b-row>


					<b-pagination v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true" prevText="Previous"
						nextText="Next" size="sm" :limit="10" v-if="!isLoading" :total-rows="totalRows" :per-page="perPage"
						v-model="currentPage" />
				</b-card>

			</b-col>
		</b-row>
		<Pulse loading v-if="isBusy" class="loader" color="#999999"></Pulse>

		<EvidenceRenameModal ref="EvidenceRenameModal" :evidence="evidence" @renameModal="handleRenameModal" />
		<ImageEditorModal ref="imageEditorModal" />
		<DeleteFileModal ref="deleteFileModal" @file-deleted="refresh" />
		<PageTour ref="pageTourRef" description="This section is where all your uploaded evidence is stored and organised. <br> <br> From here you can rename, preview, or delete the evidence along with quickly seeing which entry the evidence is attached to.<br> <br> When you capture CPD evidence quickly using our mobile app, this is where its stored for you to use it later." @handleShowTour="handleShowTour"  />
		<v-tour name="myFilesTour" :steps="steps" :options="{ highlight: true }"></v-tour>

	</div>
</template>

<script>
import ImageEditorModal from "../../components/ImageEditorModal";
import Pulse from "../../components/loaders/Pulse";
import EventBus from "../../event-bus";
import DeleteFileModal from "../../components/my-files/DeleteFileModal";
import EvidenceRenameModal from "../../components/entries/EvidenceRenameModal";
import SearchBar from "../../components/common/SearchBar";
import PageTour from "../../components/common/PageTour";
import ShowTourButton from "../../components/common/ShowTourButton";

export default {
	name: "MyFiles",
	components: { ShowTourButton, DeleteFileModal, ImageEditorModal, Pulse, pdf: () => import('pdfvuer'), SearchBar, EvidenceRenameModal, PageTour },
	data: function () {
		return {
			itemBeingHovered: null,
			items: [],
			interval: null,
			tableKey: 0,

			evidence: null,
			selectedFile: null,
			uploading: false,
			uploadPercentage: 0,
			isLoading: false,
			isBusy: false,
			filter: '',
			perPage: 10,
			currentPage: 1,
			totalRows: 0,
			sortBy: 'dateUploaded',
			sortDesc: true,
			fields: [
				{
					key: 'url',
					label: '',
				},
				{
					key: 'filename',
					label: 'Filename',
					sortable: true
				},
				{
					key: 'dateUploaded',
					label: 'Date Uploaded',
					sortable: true
				},
				{
					key: 'attached-to',
					label: 'Attached To',
				},
				{
					key: 'actions',
					label: 'Actions',
				}
			],

			steps: [
				{
					target: '.search-tour',
					content: `Here you can search the evidence. <br> <br> This searches by the filename of the evidence and not by the title of the diary or reflective entry. <br> <br> You can rename the evidence here to keep things organised.`
				},
				{
					target: '.date-uploaded__tour',
					content: `This is the date when the evidence was uploaded.`
				},
				{
					target: '.attached-to__tour',
					content: `This is a great feature because you can see if its tagged green that it’s attached to a CPD Diary Entry or Reflection. <br><br> Simply hover over the tag and it will tell you where this file is attached to.<br><br>The red tags are supporting evidence that you have not yet attached to an entry and need organising. This could be evidence that you have quickly captured in the app and have not yet created a diary entry for.`
				},
				{
					target: '.rename-files__tour',
					content: `This will allow you to change the name of the CPD evidence. <br><br> A great example of this is if you need to refer to specific evidence, for example in an audit or review where you might want to change the name to Evidence 1, Evidence 2 and so forth.`
				},
				{
					target: '.preview-files__tour',
					content: `This allows you to preview this supporting evidence and what it would look like in
a print-ready format.`
				},
				{
					target: '.delete-files__tour',
					content: `This will delete the evidence from your files permanently and remove the evidence from an attached entry.<br><br> <b>Warning:</b> if there is a green tag, this indicates that it is attached to a diary or reflection entry. <br><br> Delete with caution knowing that this delete of the evidence is irreversible!`
				},
				{
					target: '.upload-file__tour',
					content: `Here you can quickly upload a file or document that you can use later to attach to your CPD Diary or Reflective Practice. <br> <br> Simply click Browse > select your file > then click Upload. <br> <br> You will then see this appear in the filename list with a red tag.`
				},
			]
		}
	},
	watch: {
		items: {
			deep: true,
			handler() {
				this.handleRandomPreview()
			}
		},
		currentPage() {
			// this.handleRandomPreview()
		},
		filter() {
			if (!this.filter.length) {
				this.refresh();
			}
		}
	},
	mounted() {
		window.addEventListener('click', (e) => {
			if (!e.target.classList.contains('data-table__preview-action')) {
				this.handleMouseLeave()
			}
		})
	},
	destroyed() {
		clearInterval(this.interval)
		window.removeEventListener('click', () => {
			this.handleMouseLeave()
		})
	},
	methods: {
		handleShowTour() {
			this.$tours['myFilesTour'].start()
		},
		async handleRenameModal(evidence) {
			const { newFilename } = evidence
			this.$http.put(`file/${evidence.id}`, { newFilename }).then((res) => {
				this.$success('Evidence Renamed', 'The evidence has been renamed successfully')
				this.tableKey++; // For rerendering and showing new evidence name
				return res
			}).catch((resp) => {
				this.$httpError('Failed to load entries', resp);
			})
		},
		openRenameEvidenceModal(e) {
			this.evidence = e
			this.$refs.EvidenceRenameModal.handleOpenModal()
		},
		handleRandomPreview() {
			if (this.interval) {
				clearInterval(this.interval)
				this.interval = null
			}
			this.interval = setInterval(() => {
				const randomNumber = Math.floor(Math.random() * (this.items.length - 0 + 1) + 0)
				const item = this.items[randomNumber]

				const images = ["jpg", "jpeg", "png", "gif"]
				const extension = item?.filename?.split(".").pop().toLowerCase();

				if (images.includes(extension) || extension === 'pdf') {
					this.itemBeingHovered = item
				} else {
					clearInterval(this.interval)
					this.interval = null
      		this.handleRandomPreview()
				}
			}, 5000);
		},
		handleMouseEnter(item) {
			this.itemBeingHovered = item

			clearInterval(this.interval)
			this.interval = null
		},
		handleMouseLeave() {
			this.handleRandomPreview()
		},
		editAttachedEntry: function (item) {
			let entryId = item.entryId;
			let entryType = item.entryType;
			if (!entryId || !entryType) {
				return;
			}
			let routeName = 'edit/' + entryType.toLowerCase();
			this.$router.push({ name: routeName, params: { id: entryId } });
		},
		isFileImage(file) {
			const lowercaseFile = file?.toLowerCase();
			return lowercaseFile?.includes('.jpg') || lowercaseFile?.includes('.jpeg') || lowercaseFile?.includes('.png');
		},
		isFilePDF(file) {
			const lowercaseFile = file?.toLowerCase();
			return lowercaseFile?.includes('.pdf');
		},
		uploadFile: function () {
			if (this.selectedFile.size > 10 * 1024 * 1024) {
				this.$error('File Size', 'The file size cannot exceed 10MB');
				return;
			}
			let fileName = this.selectedFile.name;
			this.uploading = true;
			this.uploadPercentage = 0;

			let reader = new FileReader();
			let vueThis = this;
			reader.onload = (e) => {
				let contentType = vueThis.selectedFile.type;
				let data = e.target.result;
				window.data = data;
				this.$http.post(`file/upload?filename=${encodeURIComponent(fileName)}`, data, {
					progress(e) {
						if (e.lengthComputable) {
							vueThis.uploadPercentage = e.loaded / e.total * 100;
						}
					},
					headers: {
						'Content-Type': contentType
					}
				}).then(() => {
					EventBus.$emit('file-uploaded');
					this.refresh();
					this.$refs.fileInput.reset();
				}).finally(() => {
					this.uploading = false;
				}).catch((resp) => {
					this.$httpError('Failed to upload file', resp);
					this.uploading = false;
				});
			};

			reader.readAsArrayBuffer(this.selectedFile);


		},
		filesProvider: function (ctx, callback) {
			this.isBusy = true;
			let url = `file/limit/${ctx.perPage}/page/${ctx.currentPage}?filter=${encodeURIComponent(this.filter)}&sortBy=${encodeURIComponent(ctx.sortBy)}&sortDesc=${encodeURIComponent(ctx.sortDesc)}`;
			this.$http.get(url).then(response => {
				let items = response.body.items;
				this.items = items
				this.itemBeingHovered = items[0]

				items.map(item => {
					if (item.dateUploaded) {
						item.dateUploaded = this.moment(item.dateUploaded, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
					}
				});
				callback(items);
				this.totalRows = response.body.total;
				this.isBusy = false;
			}).catch((resp) => {
				this.$httpError('Failed to load files', resp);
			}).then(() => {
				this.isBusy = false;
			});

			// Must return null or undefined to signal b-table that callback is being used
			return null
		},
		refresh: function () {
			this.$refs.entriesTable.refresh();
		},
		openEvidence: function (e) {
			if (this.isMobile) {
				this.handleMouseEnter(e)
				this.$refs.previewBox.scrollIntoView(({ behavior: "smooth", block: "start", inline: "nearest" }))
			} else {
				let fileName = e.filename.toLowerCase();
				if (fileName.endsWith('.jpg') || fileName.endsWith('.png')) {
					this.$refs.imageEditorModal.show(e);
				} else {
					window.open(e.url, '_blank');
				}
			}
		},
		deleteFile(e) {
			this.$refs.deleteFileModal.show(e);
		},
		doSearch: function (e) {
			e.preventDefault();
			this.refresh();
		},
	},
	computed: {
		uploadButtonText: function () {
			if (this.uploading) {
				return 'Please wait...';
			}
			return 'Upload';
		},
		isMobile() {
			return window.innerWidth <= 1024;
		},
	}
}
</script>

<style lang="scss">
.previewing-image {
	canvas {
		border-radius: 8px;
	}
}
</style>

<style scoped lang="scss">
$grey: rgb(212, 212, 212);
$border-width: 3px;
.preview-being__hovered {
	border: $border-width solid $border-color;
	border-radius: 8px;
	padding: 4px;
}

p.disclaimer {
	font-size: 11px;
}

.type-of__data-badge {
	padding: 4px 12px !important;
	border-radius: 8px !important;
}

.type-of__data-badge-color {
	background-color: $sidebar !important;
}

.preview-card__body {
	background-color: $background;
}

.evidence-url {
	height: 32px;
	width: 32px;

	max-width: 32px;
	max-height: 32px;

	border-radius: 8px;
	border: 1px solid $grey;
}

.grey-box {
	border-radius: 8px;

	max-height: 42px;
	max-width: 32px;
	.page {
		height: 100%;
		width: 100%;
		.canvasWrapper {
			height: 100%;
			width: 100%;
		}
	}
	#viewerContainer {
		height: 100%;
	}
}

.myfiles-table__title { 
	width: 150px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-name {
	display: block;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.grey-box__pdf {
	height: 42px;
}

.card-title__bold {
	font-size: 14px;
	font-weight: 600;
}

.previewing-image__container {
	max-height: 100%;
	max-width: 100%;
}

.previewing-image {
	border-radius: 8px;
	border: 1px solid $grey;
}

.search-bar__col {
	display: flex;
	justify-content: flex-end;

	@media (max-width: 768px) {
		display: flex;
		justify-content: flex-start;
	}
}

.image-section__table {
	border: $border-width solid transparent;
	padding: 4px;
}

.my-files__table-preview {
	width: 105px;
	&:nth-child(3) { // Delete button
		width: 95px;
	}

	span {
		margin-top: -4px;
	}
}
</style>