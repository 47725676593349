<template>
    <b-form-group :label="title" label-class="mb-0 label-title" label-for="free-text__form-item">
        <b-input :type="type" id="free-text__form-item" @input="() => $emit('handleTitleInput')" v-model="item.value" :state="fieldState()" autocomplete="off" @focus="showHelp()" @blur="hideHelp()"/>
        <i v-if="this.isHoursEntry">{{this.hoursDisplay}}</i>
        <b-form-invalid-feedback v-if="item.required">
            Required field
        </b-form-invalid-feedback>
        <p class="help-text mt-2 mb-1" v-html="helpText"></p>
        <button v-if="this.isHoursEntry" class="activity-button" @click="item.type = 5" variant="default" size="sm">This activity is less than 24 hours</button>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'

    export default {
        name: "FreeTextFormItem",
        props: {
            type: {
                required: false,
                default: 'text'
            }
        },
        methods: {
          fieldState: function() {
              if (!this.item.required) {
                  return undefined;
              }
              return this.item.value !== '';
          }
        },
        computed: {
            title: function() {
                if (this.item.required) {
                    return this.item.title + ' *';
                }
                return this.item.title;
            },
            isHoursEntry: function() {
                if (this.item.title.includes('Hours')) {
                    return true;
                }
                return false;
            },
            hoursDisplay: function() {
                if (this.isHoursEntry) {
                    return this.$utilities.hoursToString(this.item.value);
                }
                return '';
            },
        },
        extends: FormItem,

    }
</script>

<style scoped>
    .help-text {
        font-size: 12px;
    }
    .activity-button {

    outline: none;
    border: none;

    background-color: #fff;

    border-radius: 25px;
    box-shadow: 0px 1px 4px rgba(128, 128, 128, 0.4);
    border: 1px solid rgba(128, 128, 128, 0.4);

    padding: 0px 8px;
    font-size: 14px;
    font-weight: 500;
}
</style>