<template>
    <b-card text-variant="white" class="text-center" body-class="statistic-card">
        <div class="statistic-card__left">
            <clock-outline-icon v-if="icon === 'clock'"/>
            <pencil-outline-icon v-if="icon === 'diary-entries'" />
            <eye-outline-icon v-if="icon === 'reflections'" />
            <account-star-outline-icon v-if="icon === 'account'" />
            <account-outline-icon v-if="icon === 'basic-account'" />
            <account-tie-outline-icon v-if="icon === 'corporate-account'" />
            <account-group-icon v-if="icon === 'account-group'" />
            <note-edit-outline-icon v-if="icon === 'entries' " />
        </div>
        <div class="statistic-card__right">
            <div class="statistic-card__right-title">
                <h3 class="mb-0">{{ header }}</h3>
            </div>
            <div class="statistic-card__right-value">
                <Pulse v-if="this.loading" class="loader" />
                <h3 class="mb-0" v-else>{{ value }}</h3>
            </div>
        </div>
    </b-card>
</template>

<script>
import Pulse from './loaders/Pulse'

export default {
    name: "TopStatistic",
    props: ['header', 'value', 'loading', 'icon'],
    components: { Pulse },
}
</script>

<style scoped lang="scss">

.statistic-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px 8px !important;

    color: black;

    border: 1px solid #8ba7b8;
    border-radius: 4px;
}

.card-text {
    font-size: 30px;
}

.loader {
    margin-top: 10px;
    margin-bottom: 10px;
}

.statistic-card__right {
    text-align: left;
}

.statistic-card__right-title {
    h3 {
        font-size: 14px;
        color:#8ba7b8;
    }
}

.statistic-card__right-value {
    h3 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 4px;
        color: black;
    }
}

.statistic-card__left {
    display: flex;
    align-content: center;
    height: 100%;

    .material-design-icon {
        width: 32px;
        height: 32px;
        svg {
            width: 32px;
            height: 32px;
            bottom: 0;
        }
    }
}
</style>
