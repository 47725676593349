<template>
    <div class="authentication">
        <router-view></router-view>
        <Footer :show-divider="false" />
    </div>
</template>

<script>
    import Footer from '../components/Footer'

    export default {
        name: "Authentication",
        components: {Footer}
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/_variables";

    body, html {
        background-color: $background;
    }

    .authentication {
        min-height: 100%;
        height: 100%;
        background-color: $background;
    }


</style>
