<template>
    <div class="clinical-skills-account-modal">
        <b-modal class="cs-modal" scrollable title="Link your Clinical Skills Account" ref="modal" size="md"
                 header-bg-variant="info" header-text-variant="light"
                 no-close-on-backdrop no-close-on-esc  hide-footer>

            <div class="logo-container">
                <img src="../../assets/clinical-skills.png" />
            </div>
            <div class="description mt-2">
                <small>The Clinical Skills app allows you to accurately record your skills and clinical interventions in a simple, customisable and intuative mobile app. <a href="https://clinicalskillslog.co.uk/" target="_blank">Learn more</a></small>
            </div>

            <div class="loading-container" v-if="loading">
                <PulseLoader />
            </div>

            <div class="form mt-2" v-else>
                <b-alert v-if="message != null" :show="true" :dismissible="true" @dismissed="messageDismissed" :variant="message.type" > {{message.message}} </b-alert>
                <b-form-group label="Email Address" label-for="title" required>
                    <b-form-input id="email" v-model="loginDetails.emailAddress" type="email"  trim autocomplete="off"/>
                </b-form-group>

                <b-form-group label="Password" label-for="title" required>
                    <b-form-input id="password" v-model="loginDetails.password" type="password" autocomplete="off"/>
                    <small><a href="https://clinicalskills.cpdcloud.co.uk/" target="_blank">Forgot your Password?</a></small>
                </b-form-group>

                <b-button variant="info" @click="login" block>Login</b-button>
                <b-button variant="default" @click="$refs.modal.hide()" block>Cancel</b-button>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import PulseLoader from '../../components/loaders/Pulse'

    export default {
        name: "ClinicalSkillsLinkAccountModal",
        components: {PulseLoader},
        data() {
            return {
                loading: false,
                loginDetails: {
                    emailAddress: '',
                    password: ''
                },
                message: null
            }
        },
        methods: {
            show() {
                this.message = null;
                this.loginDetails = {emailAddres: '', password: ''};
                this.$refs.modal.show();
            },
            login() {
                this.loading = true;
                this.message = null;
                this.$http.post('clinical-skills/auth/link', this.loginDetails).then(() => {
                    this.$emit('clinical-skills-linked');
                    this.$refs.modal.hide();
                }).finally(() => {
                    this.loading = false;
                }).catch((resp) => {
                    if (resp.status === 400) {
                        this.error('Please enter a valid email address and password.');
                    } else if (resp.status === 403) {
                        this.error('Your email and password combination was not recognised.');
                    } else {
                        this.$httpError('Failed to link account', resp);
                    }
                });
            },
            error(message) {
                this.message = {
                    type: 'danger', message
                };
            },
            messageDismissed() {
                this.message = null;
            }
        }
    }
</script>

<style scoped>
    .logo-container {
        width: 100%;
        text-align: center;
        height: 100px;
    }

    .logo-container img {
        height: 100%;
    }

    .loading-container {
        height: 200px;
        padding-top: 80px;
    }

</style>