<template>
    <div class="access">

    </div>
</template>

<script>
    export default {
        name: "Access",
        created() {
            this.accessToken = this.$route.params.accessToken;
            this.$store.commit('hcpcPortal/setAccessToken', this.accessToken);
            this.$router.push({name: 'hcpc/summary'});
        }
    }
</script>

<style scoped>

</style>
