<template>
	<div class="library" v-if="library">
		<b-row class="mb-4">
			<b-col sm="12" xl="9" class="d-flex align-items-center mb-1">
				<h2 class="dashboard-pages__title mb-0">{{ library.name }}</h2>
			</b-col>
			<b-col sm="12" xl="3">
				<SearchBar placeholder="Filter videos..." :incomingValue="filterQuery" @updateVal="(val) => filterQuery = val"/>
			</b-col>
		</b-row>

		<b-card>
			<b-card-group v-if="library.videos.length > 0" class="justify-content-between video-card__container">
				<div v-for="(video, idx) in filteredVideos" v-bind:key="idx" no-body class="overflow-hidden video-card"
					@click="openVideo(video)">
						<b-col md="12" class="video-thumbnail__container">
							<div class="video-thumbnail__curtain">
								<img width="60" height="60" src="../../assets/auth/ic-play.svg" alt="">
							</div>
							<b-card-img :src="video.thumbnail" class="rounded-0 video-thumbnail" />
						</b-col>
						<b-col md="12" class="p-0">
								<p class="video-title">
									{{ video.name }}
								</p>
						</b-col>
				</div>
			</b-card-group>
	
			<b-alert show variant="warning" v-else>
				There are currently no videos in this library. Please check back later.
			</b-alert>
		</b-card>



	</div>
</template>

<script>
import SearchBar from '../../components/common/SearchBar';
export default {
	name: "VideoLibrary",
	components: {
		SearchBar
	},
	data: function () {
		return {
			library: null,
			filterQuery: ''
		}
	},
	computed: {
		filteredVideos() {
			let filtered = [];
			if (this.library) {
				filtered = this.library.videos.filter(v => this.$utilities.stringContains(this.filterQuery.toLowerCase(), v.name.toLowerCase()));
			}
			return filtered;
		}
	},
	methods: {
		openVideo: function (video) {
			this.$router.push({ name: 'video', params: { video: video } });
		}
	},
	created() {
		if (!this.$route.params.library) {
			this.$router.push({ name: 'videos' });
		} else {
			this.library = this.$route.params.library;
		}
	}
}
</script>

<style scoped lang="scss">
.card h4 {
	font-size: 16px;
}

.card {
	cursor: pointer;
}

.video-title {
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 16px;
	line-height: 1.2;
}

.video-card__container {
	flex: 25%;
	@media (max-width: 768px) {
		flex: 100%;
	}
	@media (max-width: 1024px) {
		flex: 48%;
	}
}

.video-card {
	max-width: 23%;
	margin-bottom: 40px;

	@media (max-width: 768px) {
		max-width: 100%;
	}
	@media (max-width: 1024px) {
		max-width: 48%;
	}

.video-thumbnail__container {
	position: relative;
	padding: 0;
}
	.video-thumbnail__curtain {
		position: absolute;
		top: 0;
		left: 0;
	
		opacity: 0;
		transition: opacity 200ms ease-out;

		display: flex;
		align-items: center;
		justify-content: center;
	
		width: 100%;
		height: 100%;
		border-radius: 12px !important;
		background-color: rgba(0, 0, 0, .5);
	}
	&:hover {
		.video-thumbnail__curtain {
			opacity: 1;
			transition: opacity 200ms ease-out;
		}
	}
}

.video-thumbnail {
	height: 100%;

	border-radius: 12px !important;
}
</style>