<template>
	<div>
		<li class="nav-item d-flex w-100">
			<router-link :to="destination" class="nav-link d-flex" :class="{ locked: locked, 'show-active': !locked, 'sidebar-active__link': this.isActive(this.destination) }"
				@click.native="clicked">
				<div class="nav-icon">
					<div class="nav-icon-icon">
						<img :src="iconImage" class="nav-icon__image" :alt="title" />
					</div>
				</div>

				<p class="nav-item__title">
					{{ this.title }}
				</p>

				<div class="nav-item__right">
					<chevron-right-icon />
				</div>
			</router-link>
		</li>
		<UpgradeModal v-if="locked" ref="upgradeModal" :reached-limits="locked && title === 'Diary'" is-locked-page />
	</div>
</template>

<script>
import EventBus from "../../event-bus";
import UpgradeModal from "../UpgradeModal";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight";

export default {
	name: "SidebarMenuItem",
	components: { UpgradeModal, ChevronRightIcon },
	props: {
		routerLink: {
			type: String,
			required: false
		},
		url: {
			type: String,
			required: false,
			defaultValue: null
		},
		title: {
			type: String,
			required: true
		},
		locked: {
			type: Boolean,
			required: false,
			defaultValue: false
		},
		icon: {
			type: String,
			required: true
		},
		badge: {
			type: String,
			required: false,
			defaultValue: null
		},
		hideTitles: {
			type: Boolean,
			required: false,
			defaultValue: true
		}
	},
	computed: {
		popupTitle() {
			if (this.locked) {
				return 'Upgrade to unlock this feature!';
			}
			return this.hideTitles ? this.title : '';
		},
		iconImage() {
			return `/img/icons/icon-${this.icon}.png`;
		},
		destination() {
			if (this.locked) {
				return {};
			}
			return { name: this.routerLink }
		}
	},
	methods: {
		clicked() {
			if (this.locked) {
				this.$refs.upgradeModal.show();
			} else {
				EventBus.$emit('sidebar-item-clicked');
			}
		},
		isActive(route) {
			if (this.$route.name === 'portfolio-builder' && route.name === 'portfolio-builder-choose') {
				return true
			}
			
      return this.$route.name === route.name;
    },
	}
}
</script>

<style lang="scss">
.nav-item {
	&:hover {
		background-color: rgba(255, 255, 255, 0.05);
	}
}

.sidebar-active__link {
	background-color: rgba(255, 255, 255, .12);
	.nav-item__title {
		color: #fff;
	}
}

.nav-item__title {
	color: #fff;
	font-weight: 600;
	font-size: 14px;

	margin-bottom: 0;
	margin-right: auto;
	margin-left: 18px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.nav-item__right {
	.material-design-icon__svg {
		fill: #fff;
	}
}

.nav-link {
	display: flex;
	align-items: center;
	width: 100%;
}

.nav-icon__image {
	width: 22px;
}

.nav-icon {
	width: fit-content;
}

@media screen and (max-width: 1000px) {
	div.nav-icon-content.hide-content {
		display: none;
		margin-top: 0px;
	}
}
</style>