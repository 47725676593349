<template>
    <div class="authentication">
        <h4 class="pb-4">Create your account</h4>

        <b-alert :show="true" v-if="message" variant="success">{{message}}</b-alert>
        <b-alert :show="true" v-if="errorMessage" variant="danger">{{errorMessage}}</b-alert>

        <b-overlay :show="loading">
            <form @submit="register">

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input type="text" class="pl-0" v-model="request.firstName" placeholder="FIRST NAME" aria-label="First Name" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input type="text" class="pl-0" v-model="request.lastName" placeholder="LAST NAME" aria-label="Last Name" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2" :invalid-feedback="emailInvalidMessage" :state="emailValid">
                    <b-input-group class="mb-3">
                        <b-input type="email" class="pl-0" :state="emailValid" v-model="request.email" placeholder="EMAIL" aria-label="Email" trim @change="validateEmail" @input="clearEmailValidation"/>
                    </b-input-group>
                    <template v-slot:invalid-feedback>
                        <span v-if="emailInvalidAlreadyRegistered">It looks like you're already registered. Would you like to <router-link :to="{name: 'forgot-password-v2', params: {email: request.email}}">reset your password</router-link>?</span>
                        <span v-else>{{emailInvalidMessage}}</span>
                    </template>
                </b-form-group>


                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input type="password" class="pl-0" v-model="request.password" placeholder="PASSWORD" aria-label="Password" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input type="password" class="pl-0" v-model="passwordConfirmation" placeholder="CONFIRM PASSWORD" aria-label="Password" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <VueTelInput v-model="request.phoneNumber" class="pl-0 tel-input" mode="international" aria-label="Mobile" placeholder="MOBILE NUMBER"  />
                    </b-input-group>
                </b-form-group>

                <div class="form-group pb-2">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control pl-0" v-model="request.jobTitle" placeholder="PROFESSION" aria-label="Profession">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 p-0">
                        <b-button type="submit" variant="primary" class="cpd-dark-blue button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Sign Up</b-button>
                    </div>
                </div>
            </form>
        </b-overlay>
    </div>
</template>

<script>

    import {VueTelInput} from 'vue-tel-input';

    const BLANK_REQUEST = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        jobTitle: ''
    }

    export default {
        name: "RegistrationV2",
        components: {VueTelInput},
        data() {
            return {
                request: {...BLANK_REQUEST},
                message: '',
                errorMessage: '',
                loading: false,
                emailValid: null,
                emailInvalidMessage: '',
                emailInvalidAlreadyRegistered: false,
                passwordConfirmation: '',
            }
        },
        watch: {
            request: {
                deep: true,
                handler() {
                    this.errorMessage = '';
                }
            },
            passwordConfirmation() {
                this.errorMessage = '';
            }
        },
        methods: {
            register($e) {
                $e.preventDefault();

                if (this.passwordConfirmation !== this.request.password) {
                    this.errorMessage = 'Please make sure both passwords match and try again';
                    return;
                }

                this.message = '';
                this.loading = true;
                this.$http.post('auth/register', this.request).then(() => {
                    this.message = 'Thank you for registering. Please check your email to confirm your account.';
                    this.reset();
                }).catch((resp) => {
                    this.errorMessage = resp.body.message;
                }).finally(() => {
                    this.loading = false;
                });
            },
            reset() {
                this.request = {...BLANK_REQUEST};
            },
            validateEmail() {
                this.clearEmailValidation();
                if (this.request.email !== '') {
                    this.$http.post(`auth/validate-email`, {email: this.request.email}).then((resp) => {
                        this.emailValid = resp.body.valid && !resp.body.registered;
                        this.emailInvalidMessage = resp.body.message;
                        this.emailInvalidAlreadyRegistered = resp.body.registered;
                    }).catch((resp) => {
                        this.$httpError('Failed to validate email', resp);
                    });
                }
            },
            clearEmailValidation() {
                this.emailValid = null;
                this.emailInvalidMessage = '';
                this.emailInvalidAlreadyRegistered = false;
            }
        }
    }
</script>

<style scoped>
@import '../../assets/auth/auth.css';

.tel-input {
    width: 100%;

    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius:0 !important;


}

.tel-input:focus-within {
    outline:0 !important;
    -webkit-appearance:none;
    box-shadow: none !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    border-color: #426284;
}

</style>
