<template>
    <div class="evidence">
        <p>
            Select the evidence that you would like to include in support of the CPD Diary entries you listed during
            Step 2 - "Activities".
        </p>
        <p>
            For example, if you select a diary entry titles "CPD Portfolio Building Webinar" you may wish to upload the
            CPD Certificate as supporting evidence. If you have told the HCPC about five activities in your statement,
            they will expect to see five supporting pieces of evidence. Examples of CPD evidence can be found
            <a href="#">here</a>.
        </p>
        <EvidenceList v-model="selectedEvidence" />

        <div class="actions">
            <AnimatedButton variant="light" :alt="completed" alt-variant="success" title="Mark Complete" alt-title="Completed" @click="markCompleted"><CheckCircleOutlineIcon /></AnimatedButton>
        </div>
    </div>
</template>

<script>
    import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline';
    import EvidenceList from "../../components/hcpc-portal/EvidenceList";
    import AnimatedButton from "../../components/common/AnimatedButton";
    export default {
        name: "Evidence",
        components: {AnimatedButton, EvidenceList, CheckCircleOutlineIcon},
        computed: {
            selectedEvidence: {
                get() {
                    return this.$store.state.hcpcPortal.evidence;
                },
                set(newVal) {
                    return this.$store.commit('hcpcPortal/setEvidence', newVal);
                }
            },
            completed: {
                get() {
                    return this.$store.state.hcpcPortal.completed.evidence;
                },
                set(val) {
                    this.$store.commit('hcpcPortal/markCompleted_Evidence', val);
                }
            }
        },
        watch: {
            selectedEvidence(newVal, oldVal) {
                if (!this.$utilities.arraysEqual(newVal, oldVal)) {
                    this.completed = false;
                }
            }
        },
        methods: {
            markCompleted($e) {
                $e.update();
                this.$store.commit('hcpcPortal/markCompleted_Evidence', true);
            }
        }
    }
</script>

<style scoped>
    div.actions {
        margin-top: 20px;
        width: 100%;
        text-align: right;
    }

    div.actions > * {
        margin-right: 5px;
    }
</style>