<template>
	<div class="key-container">
		<b-badge v-for="(key, index) in items" v-bind:key="key.title" :id="`v-step-${index}`"
			:style="{ 'background-color': key.colour }" :class="[`color-${index}__tour`, 'key-item', 'no-select']" pill
			variant="info">{{ key.title }}</b-badge>

		<PageTour ref="calendarTourRef"
			description="This is your unique and personal CPD calender that highlights in colour codes your entries, webinars and CPD activity including planned CPD."
			@handleShowTour="handleShowTour"  />
		<v-tour name="calendarTour" :steps="steps" :options="{ highlight: true }"></v-tour>
	</div>
</template>

<script>
import PageTour from "@/components/common/PageTour";

export default {
	name: "CalendarKey",
	components: { PageTour },
	props: {
		items: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			steps: [
				{
					target: '.color-0__tour',
					content: `Webinars available for you to register and attend.`
				},
				{
					target: '.color-1__tour',
					content: `Webinars that you have registered for.`
				},
				{
					target: '.color-2__tour',
					content: `CPD that you have planned to attend or participate in the future.`
				},
				{
					target: '.color-3__tour',
					content: `CPD Entries that you have documented in your CPD Diary`
				},
				{
					target: '.color-4__tour',
					content: `These are public or private CPD events that are listed
for you to reserve onto.`
				},
				{
					target: '.color-5__tour',
					content: `Registered CPD Events that you have booked onto.`
				},
			]
		}
	},
	methods: {
		handleShowTour() {
			this.$tours['calendarTour'].start()
		},
	}
}
</script>

<style scoped>
.key-container {
	margin-top: 20px;
	margin-bottom: 20px;
	width: 100%;
}

.key-item {
	margin-right: 10px;
}</style>