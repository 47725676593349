<template>
  <b-form-group class="checkbox-form__group" :description="item.helpText">
    <b-form-checkbox v-model="item.value" value="1" unchecked-value="0">
      {{ item.title }}
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import FormItem from "./FormItem.vue";

export default {
  name: "Checkbox",
  extends: FormItem
}
</script>

<style lang="scss" scoped>
.checkbox-form__group {
  margin-bottom: 40px;
}

.custom-checkbox label.custom-control-label {
  font-weight: 600;
}
</style>