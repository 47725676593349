<template>
    <b-form class="form-login" @submit.prevent="login" >
        <Logo />
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        Via the CPDme app:
        <ul>
            <li>Go to the Account section</li>
            <li>Select 'Dashboard Authenticator'</li>
            <li>Scan the barcode below</li>
        </ul>

        <div class="message" v-if="message">
            <b-alert :dismissible="true" :show="true" :variant="message.type" @dismissed="clearMessage">
                {{message.text}}
            </b-alert>
        </div>

        <div class="qr-container mb-4">
            <qr-code-vue background="#dee2e6" foreground="#666" v-if="token" :value="token" :size="200" level="Q"></qr-code-vue>
            <Pulse loading v-if="loading" class="loader" color="#999999"></Pulse>
        </div>
        <router-link :to="{name: 'auth/login'}" class="text-decoration-none"><b-button variant="outline-dark" block>Try another method</b-button></router-link>
    </b-form>
</template>

<script>
    import QrCodeVue from 'qrcode.vue'
    import Pulse from "../../components/loaders/Pulse";
    import Logo from "../../components/authentication/Logo";

    export default {
        name: "LoginViaApp",
        components: {Logo, Pulse, QrCodeVue},
        data: function() {
            return {
                loading: false,
                token: null,
                passcode: '',
                message: null,
                timer: null
            }
        },
        methods: {
            login(token, roles) {
                let admin = roles.indexOf('Admin') > -1;
                this.$store.dispatch('authentication/storeLoginDetails', {
                    token: token,
                    isAdmin: admin,
                    roles: roles
                }).then(() => {
                    if (this.$route.params.nextUrl != null) {
                        this.$router.push(this.$route.params.nextUrl);
                    } else {
                        this.$router.push({name: 'dashboard-home'})
                    }
                });
            },
            loginViaApp: function() {
                this.clearMessage();
                this.$http.post('auth/otp/login', { token: this.token}).then((response) => {
                    this.login(response.body.token, response.body.roles);
                }).catch((resp) => {
                    if (resp.status !== 403) {
                        this.stopTimer();
                        this.message = {
                            text: resp.body.message,
                            type: 'danger'
                        };
                    }
                }).finally(() => {
                })
            },
            loadNewToken: function() {
                this.loading = true;
                this.$http.post('auth/otp').then((resp) => {
                    this.token = resp.body.token;
                }).catch((resp) => {
                    this.loading = false;
                    this.$httpError('Failed to get session', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            clearMessage: function() {
                this.message = null;
            },
            stopTimer() {
                if (this.timer) {
                    window.clearInterval(this.timer);
                }
            }
        },
        created() {
            this.loadNewToken();
            this.timer = window.setInterval(() => {
                this.loginViaApp();
            }, 2000);
        },
        beforeDestroy() {
            this.stopTimer();
        }
    }
</script>

<style scoped>
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }

    .qr-container {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .passcode input {
        text-align: center;
        font-size: 25px;
    }

    a {
        color: #2c3e50;
    }

</style>