<template>
    <div class="entry-list">
        <b-overlay :show="loading">

            <b-alert :show="true" v-if="this.value.length !== 0 && this.value.length < 12" variant="warning">
                You have currently selected less than 12 entries. When submitting to the HCPC you should choose between 12 to 50 entries.
            </b-alert>

            <b-alert :show="true" v-if="this.value.length > 50" variant="warning">
                You have currently selected more than 50 entries. When submitting to the HCPC you should choose between 12 to 50 entries.
            </b-alert>

            <b-alert :show="true" v-if="this.value.length >= 12 && this.value.length <= 50" variant="success">
                You have chosen {{this.value.length}} entries!
            </b-alert>

            <b-table ref="table" :fields="fields" :items="loadEntries" table-class="light" show-empty selectable
                     selected-variant="success" select-mode="multi" @row-selected="rowSelected" @refreshed="preselectRowsAfterLoading">
                <template slot="empty">
                    <b-alert :show="true" variant="warning">No entries found in the range given</b-alert>
                </template>
                <template v-slot:cell(selected)="{ rowSelected }" style="max-width: 50px">
                    <CheckIcon v-if="rowSelected" />
                </template>
            </b-table>
        </b-overlay>
    </div>
</template>

<script>
    import Providers from '../../providers';
    import CheckIcon from 'vue-material-design-icons/Check';

    export default {
        name: "EntryList",
        components: {CheckIcon},
        props: {
            accessToken: {
                type: String,
                required: true
            },
            value: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                loading: false,
                entries: [],
                fields: [
                    {
                        key: 'selected',
                        label: ''
                    },
                    {
                        key: 'date',
                        label: 'Date',
                        formatter: (val) => this.moment(val).format('DD/MM/YYYY')
                    },
                    {
                        key: 'title',
                        label: 'Title'
                    }
                ]
            }
        },
        methods: {
            loadEntries(ctx, callback) {
                this.loading = true;
                Providers.hcpcPortal.entries(this.accessToken).then((resp) => {
                    this.entries = resp.body;
                    callback(this.entries);
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.loading = false;
                });
                return null;
            },
            preselectRowsAfterLoading() {
                setTimeout(() => {
                    let idx = 0;
                    for (let entry of this.entries) {
                        if (this.value.includes(entry.id)) {
                            this.$refs.table.selectRow(idx);
                        }
                        idx++;
                    }
                }, 100);

            },
            rowSelected(val) {
                this.$emit('input', val.map(entry => entry.id));
            }
        },
        created() {

        }
    }
</script>

<style scoped>

</style>