<template>
    <div class="social-media p-4 w-100 d-sm-none d-md-block">
        <a class="social facebook" target="_blank" href="https://www.facebook.com/cpdme"></a>
        <a class="ml-3 mr-3 social instagram" target="_blank" href="https://www.instagram.com/cpdmadesimple"></a>
        <a class="social twitter" target="_blank" href="https://twitter.com/cpdme"></a>
        <a class="float-left pt-2 pl-3 hashtag" target="_blank" href="https://twitter.com/search?q=%23CPDMADESIMPLE&src=typed_query" >#CPDMADESIMPLE</a>
    </div>
</template>

<script>
    export default {
        name: "SocialMedia"
    }
</script>

<style scoped>
    .social-media{
        position: absolute;
        bottom: 0;
    }
    .social {
        width:40px;
        height:40px;
        float: left;
    }
    .social:hover,
    .social:focus {
        filter: invert(61%) sepia(67%) saturate(655%) hue-rotate(191deg) brightness(96%) contrast(88%);
    }
    .facebook {
        background-image: url(../../assets/auth/ic-facebook.svg);
    }
    .instagram {
        background-image: url(../../assets/auth/ic-instagram.svg);
    }
    .twitter {
        background-image: url(../../assets/auth/ic-twitter.svg);
    }

    .hashtag {
        color:white;
    }

    @media (max-width: 768px) {
        .social-media {
            position: unset !important;
        }
        .social-media {
            display:none;
        }
    }

</style>