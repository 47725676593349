<template>
    <b-card header-bg-variant="white" style="background-color: white;">
        <div slot="header" style="width: 100%; color: #8ba7b8; font-weight: 500;">
            Recent Shared CPD
            <router-link :to="{name: 'shared-cpd'}"><b-button variant="outline-dark" size="xs" style="float:right;"><share-outline-icon /> More Shared CPD</b-button></router-link>
        </div>
        <div class="widget-loading" v-if="loading">
            <Pulse />
        </div>
        <div class="widget-content" v-if="!loading">
            <SharedCPDListItem class="mb-2" v-for="sharedCpd in items" v-bind:key="sharedCpd.id" :title="sharedCpd.title"
                               :description="sharedCpd.description"
                               :avatar-url="sharedCpd.avatarUrl"
                               :nickname="sharedCpd.nickname"
                               :category="sharedCpd.category" :date="date(sharedCpd)" :descriptionFontSize="14" :titleFontSize="20" />
            <div class="no-results" v-if="items.length === 0">
                <b-alert show variant="info">There's no recently shared CPD at the moment. Check back later.</b-alert>
            </div>
        </div>
    </b-card>
</template>

<script>
    import ShareOutlineIcon from "vue-material-design-icons/ShareOutline";
    import SharedCPDListItem from "./SharedCPDListItem";
    import Pulse from "../loaders/Pulse";
    export default {
        name: "SharedCPDDashboardWidget",
        components: { Pulse, SharedCPDListItem, ShareOutlineIcon},
        data() {
            return {
                numberOfItems: 6,
                loading: false,
                items: []
            }
        },
        methods: {
            loadSharedCPD() {
                this.loading = true;
                this.$http.get(`shared-cpd/limit/${this.numberOfItems}/page/1`).then((resp) => {
                    this.items = resp.body.items;
                }).finally(() => {
                    this.loading = false;
                });
            },
            date(sharedCpd) {
                return this.moment(sharedCpd.date).format('DD/MM/YYYY');
            }
        },
        created() {
            this.loadSharedCPD();
        }

    }
</script>

<style scoped>
.widget-content {
    max-height: 300px;
    overflow: scroll;
}
</style>