<template>
	<div>
		<div class="video" v-if="video">
			<h2>{{ video.name }}</h2>

			<div v-if="isLoading" class="mt-5 mb-5">
				<Pulse />
			</div>
			<div v-else>
				<div class="video-container mt-4">
					<iframe :src="playerSrc" width="600" height="390" frameborder="0" :title="video.name" allow="autoplay; fullscreen"
						allowfullscreen></iframe>
				</div>
		
				<div v-if="showRequestCertificate">
					<b-form-checkbox   class="d-flex justify-content-center video-disclaimer" v-model="hasWatchedVideo">
						As a professional it is your responsibility to give an accurate and truthful account of your learning activity. <br><br>

						By selecting this box, you  confirm that you have viewed the entire recording of the CPD webinar and have assimilated some key learning points that you intend to evidence and contemplate upon using the CPDme Diary System.  <br><br>

						Certification of attendance on its own does not fully evidence your learning and development and should be complimented by a CPDme diary entry or reflective entry.
					</b-form-checkbox>
		
					<div class="video-page__footer mt-2">
						<b-button class="ovr-button__inverted" @click="requestCPDCertificate" :disabled="!hasWatchedVideo">Request a CPD
							Certificate</b-button>
					</div>
				</div>

				<p v-if="isTypeformCertificate && !video.name.toLowerCase().includes('introduction to the premium cpd')" class="mt-4"><InfoIcon/> If you would like a certificate for watching this CPD webinar, there will be an option at the end of the video to request this.</p>
				
			</div>

		</div>
	</div>
</template>

<script>

import EventBus from "@/event-bus";
import Pulse from "../../components/loaders/Pulse";
import InfoIcon from 'vue-material-design-icons/InformationOutline'

export default {
	name: "Video",
	components: { Pulse, InfoIcon },
	computed: {
		playerSrc() {
			return `https://player.vimeo.com/video/${this.video.videoId}?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=114888`
		}, 
		showRequestCertificate() {
			const lowercaseLibraryName = this.libraryName.toLowerCase();
			return !lowercaseLibraryName.includes("top tips") && !lowercaseLibraryName.includes("guides") && !lowercaseLibraryName.includes("private");
		},
		isTypeformCertificate() {
			const lowercaseLibraryName = this.libraryName.toLowerCase();
			return lowercaseLibraryName.includes("private");
		}
	},
	methods: {
		requestCPDCertificate() {
			this.isLoading = true
			this.$http.post(`videos/${this.video.videoId}/certificate`).then((resp) => {
				this.$success('Certificate successfully generated', 'The certificate has been emailed to you and a copy can be found in your Entries section')
			})
			.catch((resp) => {
				this.$httpError('Failed to generate CPD Certificate', resp);
			})
			.finally(() => {
				this.hasWatchedVideo = false;
				this.isLoading = false
			})
		}
	},
	data: function () {
		return {
			video: null,
			libraryName: null,
			hasWatchedVideo: false,
			isLoading: false
		}
	},
	created() {
		if (!this.$route.params.video) {
			this.$router.push({ name: 'videos' });
		} else {
			this.video = this.$route.params.video;
		}
		if (this.$route.params.libraryName) {
			this.libraryName = this.$route.params.libraryName;
		}
	},
	beforeRouteEnter(to, from, next) {
		EventBus.$emit('logout-timer/pause');
		next();
	},
	beforeRouteLeave(to, from, next) {
		EventBus.$emit('logout-timer/resume');
		next();
	}
}
</script>

<style scoped>
.video {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.video-container {
	width: 100%;
	text-align: center;
}

.video-page__footer {
	display: flex;
	justify-content: center;
}

.video-disclaimer {
	font-size: 14px !important;

	margin-bottom: 32px;
}
</style>
