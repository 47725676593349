<template>
    <main role="main" class="pb-4 dark" :class="{'full-width__content': sidebarToggled}">
        <TopBannerNotice />
        <router-view></router-view>
        <Footer />
    </main>
</template>

<script>

    import Footer from "./Footer";
    import TopBannerNotice from "./organisations/TopBannerNotice";
    import Header from "../components/Header.vue";

    export default {
        name: "MainContent",
        props: ['sidebarToggled', 'profile'],
        components: {TopBannerNotice, Footer, Header},
        computed: {

        }
    }
</script>

<style lang="scss" scoped>
main {
    transition: all 200ms ease-out;

    width: 100%;
    padding-left: calc(75px + 230px);
    padding-right: 55px;
}

/* for phones */
@media screen and (max-width: 768px) {
    main {
        max-width:100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem !important;
    }
}
/* for ipads */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    main {
        padding-left: calc(75px + 220px);
        padding-right: 55px;
    }

    .full-width__content {
        padding-left: calc(75px + 140px + 1.5rem) !important;
    }
}
</style>