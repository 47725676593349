const toastMixin = {

    methods: {
        $success: function(title, message) {
            this.$toast('success', title, message, 5000);
        },
        $httpError: function(title, response) {
            if (response.hasOwnProperty('status')) {
                if (response.status === 0) {
                    this.$error(title, 'Please make sure you have an active internet connection.');
                } else if (response.status === 401) {
                    // this.$toast('warning', 'Session Expired', 'Your session has expired. Please login again.', 5000);
                } else {
                    this.$error(title, response.body.message);
                }
            } else {
                this.$error(title, 'Unknown error.');
            }
        },
        $error: function(title, message) {
            this.$toast('danger', title, message, null);
        },
        $toast(type, title, message, autoHide) {
            this.$root.$bvToast.toast(message, {
                title: title,
                noAutoHide: autoHide === null,
                autoHideDelay: autoHide,
                appendToast: true,
                solid: true,
                variant: type,
            })
        },
        $hideToast() {
            this.$root.$bvToast.hide()
        }
    },


};

export default toastMixin;