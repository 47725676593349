<template>

    <b-row class="login-page m-0">

        <b-col md="8" sm="12" class="p-0 left-col">
            <LeftColumn />
        </b-col>

        <b-col md="4" sm="12" class="right-col">
            <RightColumn />
        </b-col>

    </b-row>

</template>

<script>
    import LeftColumn from "./LeftColumn";
    import RightColumn from "./RightColumn";
    export default {
        name: "Layout",
        components: {RightColumn, LeftColumn}
    }
</script>

<style scoped>

    .login-page {
        font-family: 'Roboto', sans-serif;
        height:100vh;
        width: 100%;
        background: -moz-linear-gradient(20deg, rgba(27,49,78,1) 0%, rgba(84,122,174,1) 100%); /* ff3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(27,49,78,1)), color-stop(100%, rgba(84,122,174,1))); /* safari4+,chrome */
        background: -webkit-linear-gradient(20deg, rgba(27,49,78,1) 0%, rgba(84,122,174,1) 100%); /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(20deg, rgba(27,49,78,1) 0%, rgba(84,122,174,1) 100%); /* opera 11.10+ */
        background: -ms-linear-gradient(20deg, rgba(27,49,78,1) 0%, rgba(84,122,174,1) 100%); /* ie10+ */
        background: linear-gradient(70deg, rgba(27,49,78,1) 0%, rgba(84,122,174,1) 100%); /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b314e', endColorstr='#547aae',GradientType=1 ); /* ie6-9 */
    }

    .left-col {
        background-image: url(../../assets/auth/login-abstract-waves.svg);
        background-position: bottom;
        background-repeat: no-repeat;
    }

    .right-col{
        background-color: white;
    }

    img{
        max-width:100%;
    }
    label{
        font-size:14px;
    }



</style>