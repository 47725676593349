import hcpcPortal from './hcpc-portal';
import organisationManagement from './organisation-management';
import membershipManagement from './membership-management';
import authentication from './authentication';
import graphs from './graphs';
import profile from './profile';

export default {
    authentication: authentication,
    hcpcPortal: hcpcPortal,
    organisationManagement: organisationManagement,
    membershipManagement: membershipManagement,
    graphs: graphs,
    profile: profile,
}
