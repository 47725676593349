import { render, staticRenderFns } from "./Statement.vue?vue&type=template&id=37fd1cc2&scoped=true"
import script from "./Statement.vue?vue&type=script&lang=js"
export * from "./Statement.vue?vue&type=script&lang=js"
import style0 from "./Statement.vue?vue&type=style&index=0&id=37fd1cc2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37fd1cc2",
  null
  
)

export default component.exports