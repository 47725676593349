<template>
    <b-card header-bg-variant="white" header-class="custom-card__header" header="Entries over the Last 12 Months" style="background-color: white;">
        <Pulse v-if="loading" />
        <div class="entryfrequency-graph" v-else>
            <BarGraph :height="200" :chart-data="chartData" :options="options"/>
        </div>
    </b-card>
</template>

<script>

    import BarGraph from "./graphs/BarGraph";
    import Pulse from "./loaders/Pulse";
    export default {
        name: "EntryFrequencyGraph",
        components: {Pulse, BarGraph},
        data() {
            return {
                colours: ['#3D99C2', '#399cc1', '#35a0c0', '#31a4c0', '#2da8bf', '#29acbf', '#2580be', '#31b4be', '#1db8bd', '#19bcbd', '#15c0bc', '#12c4bc'],
                loading: false,
                options: {
                    responsive: true,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 1
                                },
                                gridLines: {
                                    display:false
                                }
                            },
                        ]
                    }
                },
                chartData: {
                    datasets: [

                    ],
                    labels: []
                }
            }
        },
        methods: {
            loadData() {
                this.loading = true;
                this.$http.get('statistics/monthly').then((resp) => {
                    let graph = resp.body;

                    let dataPoints = [];
                    let categories = [];
                    for (let i = 0; i < graph.length; i++) {
                        dataPoints.push(graph[i].value);
                        categories.push(graph[i].key);
                    }
                    this.chartData = {
                        datasets: [
                            {
                                data: dataPoints,
                                backgroundColor: this.colours
                            }
                        ],
                        labels: categories
                    };
                }).catch((resp) => {
                    this.$httpError('Failed to load graph', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>