<template>
    <div>
        <b-modal ref="modal" title="HCPC Portal" size="lg" header-bg-variant="primary" header-text-variant="light"
                 ok-title="Request Access" @ok.prevent="requestAccess" :ok-disabled="isRequestAccessDisabled" :cancel-disabled="loading"
                 no-close-on-backdrop no-close-on-esc>

            <b-form-group label="From">
                <b-form-datepicker v-model="dateFrom" />
            </b-form-group>

            <b-form-group label="To">
                <b-form-datepicker v-model="dateTo" />
            </b-form-group>

        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "HCPCPortalCreateModal",
        data() {
            return {
                dateFrom: null,
                dateTo: null,
                loading: false,
            }
        },
        computed: {
            isRequestAccessDisabled() {
                return this.loading || !this.dateFrom || !this.dateTo;
            }
        },
        methods: {
            show() {
                this.dateFrom = null;
                this.dateTo = null;
                this.$refs.modal.show();
            },
            close() {
                this.$refs.modal.hide();
            },
            requestAccess() {
                this.loading = true;
                this.$http.post('hcpc-portal/request-access', {
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo
                }).then(() => {
                    this.$success('Access Requested', 'Please check your email for a link to your personalised portal');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to request access', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
