const state = {
    features: []
};

// getters
const getters = {
    getFeatures: (state) => {
        return state.features;
    },
};

// mutations
const mutations = {
    storeFeatures(state, features) {
        state.features = features;
    },
};

// actions
const actions = {
    storeFeatures: ({commit}, settings) => {
        commit('storeFeatures', settings);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}