import Vue from 'vue';
import DateHelper from '@/utilities/date'


export default {
    organisations() {
        return Vue.http.get(`organisations`);
    },
    usersReport(organisationId, dateRange, limit = 10, page = 0, filter = '', sortBy = 'firstName', desc = true) {

        let sortDir = desc ? 'desc' : 'asc';
        sortBy = sortBy || 'firstName';
        let url = `organisations/${organisationId}/users/limit/${limit}/page/${page}?sortBy=${sortBy}&sortDir=${sortDir}&filter=${filter}`;

        if (dateRange) {
            url += `&from=${dateRange.from}&to=${dateRange.to}`;
        }

        return Vue.http.get(url);
    },
    addUser(organisationId, request) {
        return Vue.http.post(`organisations/${organisationId}/users`, request);
    },
    usersReportCsv(organisationId, dateRange) {

        let url = `organisations/${organisationId}/users/csv`;
        let defaultFilename = 'users.csv'
        
        if (dateRange) {
            url += `?from=${dateRange.from}&to=${dateRange.to}`;
            defaultFilename = `users ${DateHelper.formatDateToDDMMYYYY(dateRange.from)} to ${DateHelper.formatDateToDDMMYYYY(dateRange.to)}.csv`;
        }
        
        return Vue.http.get(url, {responseType: 'blob'}).then((resp) => {
            let result = document.createElement('a');
            let contentDisposition = resp.headers.get('Content-Disposition') || '';
            let filename;
            if (contentDisposition) {
                filename = contentDisposition.split('filename=')[1];
                filename = filename.replace(/"/g, "")
            } else {
                filename = defaultFilename;
            }

            return resp.blob()
                .then(function(data) {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(data, filename);
                        return () => {};
                    } else {
                        result.href = window.URL.createObjectURL(data);
                        result.target = '_self';
                        result.download = filename;
                        return () => result.click();
                    }
                });
        });
    }
}
