<script>
import {Doughnut, mixins} from "vue-chartjs";

const {reactiveProp} = mixins;

const USE_ANIMATIONS = false;

export default {
    name: "PieGraph",
    extends: Doughnut,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.renderChart(this.chartData, this.options);

        if (USE_ANIMATIONS) {
            this.startRotation();

            const canvas = this.$data._chart.canvas;
            canvas.addEventListener('mouseenter', this.handleMouseEnter);
            canvas.addEventListener('mouseleave', this.handleMouseLeave);
        }
    },
    data() {
        return {
            isHovered: false,
            rotationRequestId: null, // Track the requestAnimationFrame ID
            isCanvasBeingHovered: false,
            interval: null,
            element: null,

            lastDatasetIndex: -1,
            lastDataIndex: -1,
        };
    },
    beforeDestroy() {
        if (USE_ANIMATIONS) {
            const canvas = this.$data._chart.canvas;
            if (canvas) {
                canvas.removeEventListener('mouseenter', this.handleMouseEnter);
                canvas.removeEventListener('mouseleave', this.handleMouseLeave);
            }
            this.stopRotation();
        }
    },
    destroyed() {
        const chart = this.$data._chart;

        if (chart) {
            // Clear the interval
            clearInterval(this.interval);

            // Destroy the chart instance
            chart.destroy();
        }
    },
    methods: {
        handleMouseEnter() {
            this.isCanvasBeingHovered = true
            this.stopRotation();
        },
        handleMouseLeave() {
            this.isCanvasBeingHovered = false
            this.startRotation(true)

            if (this.isHovered) {
                this.hoverOnRandomSection(this.element);
            }
        },
        stopRotation() {
            if (this.rotationRequestId) {
                cancelAnimationFrame(this.rotationRequestId);
                this.rotationRequestId = null;
            }
        },
        startRotation(bool) {
            if (this.rotationRequestId === null) {
                const chart = this.$data._chart;
                const rotationSpeed = 0.002; // Adjust the rotation speed as desired
                let pauseCount = 0;

                const rotate = () => {
                    if (!this.isHovered) {
                        chart.options.rotation += rotationSpeed;
                        chart.update();
                    }

                    this.rotationRequestId = requestAnimationFrame(rotate);
                };

                rotate();

                this.interval = setInterval(() => {
                    pauseCount++;

                    if (pauseCount >= 3 && pauseCount <= 8 && !this.isHovered) {
                        this.isHovered = true;
                        this.hoverOnRandomSection();
                    }

                    if (pauseCount === 8) {
                        this.isHovered = false;
                        this.element = null
                        pauseCount = 0;
                        // Hide tooltip
                        chart.tooltip._active = [];
                        chart.tooltip.update();
                        chart.draw();
                    }
                }, 1000); // Pause every 3 seconds (3000 milliseconds) and resume after 5 seconds (8000 milliseconds)

                if (bool) {
                    clearInterval(this.interval)
                }
            }
        },


        hoverOnRandomSection(el) {
            if (!this.isCanvasBeingHovered) {
                let datasetIndex;
                let dataIndex;
                let meta;
                let element;

                do {
                    datasetIndex = Math.floor(Math.random() * this.$data._chart.data.datasets.length);
                    dataIndex = Math.floor(Math.random() * this.$data._chart.data.datasets[datasetIndex].data.length);
                    meta = this.$data._chart.getDatasetMeta(datasetIndex);
                    element = el || meta.data[dataIndex];
                    this.element = element;
                } while ((element && element.hidden) || (datasetIndex === this.lastDatasetIndex && dataIndex === this.lastDataIndex));

                // Update last indices
                this.lastDatasetIndex = datasetIndex;
                this.lastDataIndex = dataIndex;

                if (element && !el) {
                    const startRadius = el ? element._model.outerRadius : element._model.outerRadius + 0;
                    const targetRadius = startRadius + 6; // Adjust the target radius as desired
                    const duration = 200; // Adjust the animation duration (in milliseconds) as desired
                    const startTime = performance.now();

                    this.$data._chart.tooltip._active = [element]; // Show tooltip for the hovered section
                    this.$data._chart.tooltip.update();
                    this.$data._chart.draw();

                    const animateRadius = (timestamp) => {
                        const progress = timestamp - startTime;
                        const percentComplete = progress / duration;
                        const currentRadius = startRadius + (targetRadius - startRadius) * percentComplete;

                        element._model.outerRadius = currentRadius;
                        this.$data._chart.draw();

                        if (progress < duration) {
                            requestAnimationFrame(animateRadius);
                        } else {
                            // Animation complete, reset radius to original value
                            element._model.outerRadius = targetRadius;
                            this.$data._chart.draw();
                        }
                    };

                    requestAnimationFrame(animateRadius);
                }
            }
        }
    },
};
</script>

<style scoped></style>
