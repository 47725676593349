const WordCounter = {};

WordCounter.install = function (Vue) {

    Vue.prototype.$wordCount = (value) => {
        if (value === null || value === undefined) return 0;
        return value.trim()
                .split(/\s+/)
                .filter(function(n) {return n !== ''}).length;
    };

    Vue.prototype.$wordCountHtml = (value) => {
        if (value === null || value === undefined) return 0;
        return Vue.prototype.$wordCount(value.replace(/(<([^>]+)>)/ig, ' '))
    }

    Vue.prototype.$charCount = (value) => {
        if (value === null || value === undefined) return 0;
        return value.length;
    }

};

export default WordCounter;