<template>
    <div class="authentication">
        <h4 class="pb-4">Forgot Password</h4>
        <p>Please choose your new password.</p>

        <b-alert :show="true" variant="warning" v-if="errorMessage">{{errorMessage}}</b-alert>
        <b-overlay :show="loading">
            <form @submit.prevent="changePassword" @change="errorMessage = null">
                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0 no-background"><img alt="icon user" width="20" src="../../assets/auth/ic-lock.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="password" placeholder="PASSWORD" v-model="password" aria-label="Password" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0 no-background"><img alt="icon user" width="20" src="../../assets/auth/ic-lock.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="password" placeholder="CONFIRM PASSWORD" v-model="password2" aria-label="Confirm Password" />
                    </b-input-group>
                </b-form-group>

                <b-row>
                    <b-col md="12" class="p-0">
                        <b-button variant="primary" type="submit" class="button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Reset Password</b-button>
                    </b-col>
                    <b-col md="12">
                        <router-link :to="{name: 'login-v2'}"><b-button variant="default" block>Back to login</b-button></router-link>
                    </b-col>
                </b-row>

            </form>
        </b-overlay>
    </div>
</template>

<script>

import Providers from '../../providers';

export default {
    name: "ForgotPasswordDoV2",
    data() {
        return {
            token: '',
            password: '',
            password2: '',
            loading: false,
            errorMessage: '',
        }
    },
    methods: {
        changePassword() {

            if (this.password !== this.password2) {
                this.errorMessage = 'The two passwords do not match. Please try again.';
                return;
            }

            this.loading = true;
            Providers.authentication.forgotPasswordComplete(this.token, this.password).then(() => {
                this.errorMessage = null;
                this.$success('Password Changed', 'Your password has been changed successfully. Please login.');
                this.$router.push({name: 'login-v2'});
            }).catch((resp) => {
                this.errorMessage = resp.body.message;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    created() {
        this.token = this.$route.params.token;
    }
}
</script>

<style scoped>
@import '../../assets/auth/auth.css';
</style>
