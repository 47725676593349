<template>
    <div class="event-details-modal">
        <b-modal class="cpd-modal" scrollable :title="title" ref="modal" size="lg"
                 centered header-bg-variant="default" header-text-variant="dark" hide-footer >
            <template slot="modal-header">
                <h5 style="margin-bottom: 0px;"><b-badge>Event</b-badge> {{title}}</h5>
                <button type="button" aria-label="Close" class="close text-dark" @click="$refs.modal.hide()">×</button>
            </template>

            <p v-if="event">
                <span>
                    <ClockIcon /> 
                    {{startDate}} to {{endDate}}
                </span>
                <span v-if="event.location">
                    <br />
                    <MapMarkerIcon /> 
                    {{ event.location }}
                </span>
                <span v-if="event.contactName">
                    <br/>
                    <EmailIcon /> 
                    <a :href="contactEmailLink"> {{event.contactName}}</a>
                </span>
            </p>

            <ReadMore v-if="event" class="webinar-description" :action-disabled="registrationDisabled" :action-callback="registerForEvent"
                      :action-title="actionTitle" more-str="See more..." less-str="Show less" :text="event.description" :max-chars="400" :button-variant="canUnregister ? 'primary' : 'info'">
                <template v-slot:before-buttons>
                    <a v-if="event.website" :href="event.website" target="_blank"><OpenInNewIcon /> Open Website</a><br />
                    <a v-if="event.joinEventLink && event.registered" :href="event.joinEventLink" target="_blank"><video-account-icon /> Join Event</a><br />
                </template>
            </ReadMore>
            <ConfirmModal ref="confirmUnregisterModal" :message="`Are you sure you wish to unregister your attendence from this event?`" @ok="unregisterAttendance"/>

            <template slot="modal-footer">
                <b-button @click="$refs.modal.hide()" variant="dark">Close</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import ReadMore from "../ReadMore";
    import ClockIcon from 'vue-material-design-icons/Clock'
    import OpenInNewIcon from 'vue-material-design-icons/OpenInNew';
    import EmailIcon from 'vue-material-design-icons/Email';
    import MapMarkerIcon from 'vue-material-design-icons/MapMarker';
    import ConfirmModal from '@/components/common/ConfirmModal';

    export default {
        name: "CalendarEventDetailsModal",
        components: {ReadMore, ClockIcon, OpenInNewIcon, EmailIcon, ConfirmModal, MapMarkerIcon},
        data() {
            return {
                event: null,
                loading: false,
            }
        },
        computed: {
            actionTitle() {
                if (this.event && this.event.registered) {
                    return 'Unregister from Event';
                } else if (this.event && this.event.eventFull) {
                    return 'Event Full'; 
                } else { 
                    return 'Register for Event';
                }
            },
            canUnregister() {
                if (this.event && this.event.registered) {
                    return true;
                } else {
                    return false;
                }
            },
            title() {
                if (this.event) {
                    return this.event.title;
                }
                return '';
            },
            startDate() {
                if (!this.event) {
                    return '';
                }
                return this.moment(this.event.startDate).format('Do MMMM YYYY h:mm a');
            },
            endDate() {
                if (!this.event) {
                    return '';
                }
                return this.moment(this.event.endDate).format('Do MMMM YYYY h:mm a');
            },
            contactEmailLink() {
                return 'mailto:' + this.event.contactEmail;
            },
            registrationDisabled() {
                return (this.event.eventFull && !this.event.registered) || this.loading || this.moment(this.event.endDate).isBefore(this.moment());
            }
        },
        methods: {
            show(event) {

                this.event = event;
                this.$refs.modal.show();
            },
            registerForEvent() {
                if (this.canUnregister) {
                    this.$refs.confirmUnregisterModal.show();
                } else {
                    this.$hideToast();
                    this.loading = true;
                    this.$http.post(`calendar/events/${this.event.id}/register`).then(() => {
                        this.$success('Registered for Event', 'You have been registered for this event.');
                        this.event.registered = true;
                    }).catch((resp) => {
                        this.$httpError('Failed to register', resp);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            }, 
            unregisterAttendance() {
                this.$hideToast();
                this.loading = true; 
                this.$http.post(`calendar/events/${this.event.id}/unregister`).then(() => {
                        this.$success('Unregistered From Event', 'You have been unregistered from this event.');
                        this.event.registered = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to unregister from event', resp);
                    }).finally(() => {
                        this.loading = false;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
