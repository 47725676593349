<template>
    <b-modal ref="modal" hide-header size="lg" body-bg-variant="dark" hide-footer>
        <b-embed
            type="iframe"
            aspect="16by9"
            src="https://player.vimeo.com/video/470464170"
        ></b-embed>
        <template v-slot:modal-footer>
            <div></div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalGuideVideo",
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
}
</script>

<style scoped>

</style>
