<template>
    <b-card body-class="p-0" header-bg-variant="white" header-class="view-all__events-card-header" style="background-color: white;">
        <div slot="header" class="view-all__events-header">
            Upcoming Events
            <router-link class="view-all__events" :to="{name: 'calendar'}"><calendar-icon /></router-link>
        </div>
        <CalendarComponent :mini="true" :show-key="false" />
    </b-card>
</template>

<script>
    import CalendarComponent from "./CalendarComponent";
    export default {
        name: "CalendarDashboardWidget",
        components: {CalendarComponent}
    }
</script>

<style scoped lang="scss">
.view-all__events-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-all__events {
    .material-design-icon {
        width: 1.5rem;
        height: 1.5rem;
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.view-all__events-card-header {
    color: #8ba7b8;
    font-weight: 500;
}
</style>