<template>
	<div v-if="!isMobile()" class="page-tour__modal">
		<b-modal @shown="handleShown" id="page-tour__modal" @hidden="handleHidden" ref="pageTourRef" size="md" :title="title" header-text-variant="light" header-bg-variant="primary"
			no-close-on-backdrop>

			<p v-html="description" />

			<div slot="modal-footer">
				<b-button @click="handleNo" variant="light" size="md" class="mr-2">{{ isTour ? 'Skip' : 'Got it' }}</b-button>
				<b-button v-if="isTour" @click="handleYes" variant="primary" size="md" class="mr-2">Show tour</b-button>
			</div>

		</b-modal>
	</div>
</template>

<script>
export default {
	name: "PageTour",
	props: {
		isTour: {
			type: Boolean,
			default: true
		},
		title: {
			type: String,
			default: 'Would you like a tour of how this page works?'
		},
		description: {
			type: String,
			default: 'We would love to show you a short tour of how this page works'
		}
	},
	methods: {
		isMobile() {
			return (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) // If true, means its a mobile device
    },


		handleShown() {
			document.addEventListener('keypress', this.keyPress)
		},
		handleHidden() {
			document.removeEventListener('keypress', this.keyPress)
		},
		keyPress(event) {
			if (event.key === "Enter") {
				this.handleYes()
			}
		},
    handleNo() {
      this.handleClose();
    },
		handleClose() {
			this.$refs.pageTourRef.hide();
		},
		handleYes() {
      this.handleClose();
      this.$emit('handleShowTour')
		},
    handleOpen() {
      this.$refs.pageTourRef.show();
    }
	}
}
</script>

<style lang="scss">

#page-tour__modal___BV_modal_outer_ {
	z-index: 99999999 !important;
}
</style>