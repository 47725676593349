<template>
    <div class="auth-content">
        <img alt="small logo" class="float-right m-2 logo-s" width="100" src="../../assets/auth/cpdme-small-logo.png">

        <div class="tab-content p-5">

            <router-view />
        </div>

        <Footer />

    </div>
</template>

<script>
    import Footer from "./Footer";
    export default {
        name: "RightColumn",
        components: {Footer}
    }
</script>

<style scoped>
    a:hover, h4{
        color:#6a87d3 !important;
        text-decoration: none !important;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #c3c3c3 !important;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #c3c3c3 !important;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #c3c3c3 !important;
    }

    .tab-content {
        position: absolute;
        margin: auto;
        max-height: 80vh;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: scroll;
    }

    @media (max-width: 768px) {
        .logo-s {
            display: none !important;
        }
        .tab-content {
            position: unset !important;
            overflow: unset !important;
        }
        .tab-content {
            max-height: 100% !important;
        }
    }

    @media (max-height: 768px){
        .tab-content {
            max-height: 90vh;
            overflow: scroll;
        }
    }
</style>
