<template>
    <div class="actions">
        <AnimatedButton variant="light" alt-variant="success" title="Copy" alt-title="Copied!" @click="copy"><ContentCopyIcon /></AnimatedButton>
        <AnimatedButton variant="light" alt-variant="success" title="Save" alt-title="Saved!" @click="save"><ContentSaveIcon /></AnimatedButton>
        <AnimatedButton ref="markComplete" :alt="completed" variant="light" alt-variant="success" title="Mark Complete" alt-title="Completed" @click="markComplete"><CheckCircleOutlineIcon /></AnimatedButton>
        <ConfirmModal ref="saveModal" ok-title="Save" message="This will override your existing CPDme profile information. Are you sure you want to save?" @ok="confirmSave" />
    </div>
</template>

<script>
    import ContentCopyIcon from 'vue-material-design-icons/ContentCopy';
    import ContentSaveIcon from 'vue-material-design-icons/ContentSave';
    import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline';
    import AnimatedButton from "../common/AnimatedButton";
    import ConfirmModal from "@/components/common/ConfirmModal";

    export default {
        name: "Actions",
        components: {ConfirmModal, AnimatedButton, ContentCopyIcon, ContentSaveIcon, CheckCircleOutlineIcon},
        props: {
            completed: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                lastSaveEvent: null,
            }
        },
        methods: {
            copy($e) {
                $e.animate();
                this.$success('Copied', 'You can now paste this into the correct section of the HCPC website')
                this.$emit('copy', $e);
            },
            save($e) {
                this.lastSaveEvent = $e;
                this.$refs.saveModal.show();
            },
            confirmSave() {
                this.$emit('save', this.lastSaveEvent);
            },
            markComplete($e) {
                $e.update();
                this.$emit('mark-complete', $e);
            },
            resetMarkComplete() {
                this.$refs.markComplete.reset();
            },
            setMarkComplete() {
                this.$refs.markComplete.setAlt();
            }
        }
    }
</script>

<style scoped>
    div.actions {
        margin-top: 20px;
        width: 100%;
        text-align: right;
    }

    div.actions > * {
        margin-right: 5px;
    }
</style>
