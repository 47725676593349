<template>
    <div class="authentication">
        <h4 class="pb-4">Complete Your Account</h4>
        <b-alert :show="true" variant="warning" v-if="errorMessage">{{errorMessage}}</b-alert>
        <b-overlay :show="loading">
            <form @submit.prevent="setPassword" @change="resetError">
                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0 no-background"><img alt="icon user" width="20" src="../../assets/auth/ic-user.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="email" placeholder="EMAIL" disabled v-model="email" aria-label="Email Address" />
                    </b-input-group>
                </b-form-group>
                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0  no-background"><img alt="icon password" width="20" src="../../assets/auth/ic-lock.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="password" placeholder="PASSWORD" v-model="password" aria-label="Password" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-input-group-prepend>
                            <b-input-group-text class="p-0  no-background"><img alt="icon password" width="20" src="../../assets/auth/ic-lock.svg"></b-input-group-text>
                        </b-input-group-prepend>
                        <b-input type="password" placeholder="CONFIRM PASSWORD" v-model="password2" aria-label="Confirm Password" />
                    </b-input-group>
                </b-form-group>

                <b-form-group class="pb-2">
                    <b-input-group class="mb-3">
                        <b-select v-model="governingBody" aria-label="Governing Body" :options="governingBodies">
                        </b-select>
                    </b-input-group>
                </b-form-group>

                <b-row>
                    <b-col md="12" class="p-0">
                        <b-button variant="primary" type="submit" class="button-pill text-uppercase pl-4 pr-4 pt-2 pb-2" block>Complete Account</b-button>
                    </b-col>
                </b-row>
            </form>


        </b-overlay>

    </div>
</template>

<script>
import Providers from '../../providers';

export default {
    name: "OnboardV2",
    data() {
        return {
            loading: false,
            errorMessage: null,
            email: '',
            password: '',
            password2: '',
            token: '',
            governingBody: '',
            governingBodies: [
                {text: '-- Choose Governing Body --', value: ''},
                {text: 'Health and Care Professionals Council', value: 'HCPC'},
                {text: 'Nursing and Midwifery Council', value: 'NMC'},
                {text: 'NRCPD', value: 'NRCPD'},
                {text: 'Other / None', value: 'None'},
            ]
        }
    },
    methods: {
        resetError() {
            this.errorMessage = '';
        },
        setPassword() {
            this.resetError();
            if (this.password !== this.password2) {
                this.errorMessage = 'The passwords do not match';
            } else {
                this.loading = true;
                Providers.authentication.completeOnboarding(this.token, this.password, this.governingBody).then(() => {
                    this.$success('Password Set', 'Your password has been set successfully. Please log in.');
                    this.$router.push({name: 'login-v2'})
                }).catch((resp) => {
                    this.errorMessage = resp.body.message;
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        loadSession() {
            this.loading = true;
            Providers.authentication.onboardingSession(this.token).then((resp) => {
                this.email = resp.body.email;
            }).catch(() => {
                this.$error('Session Invalid', 'That link is no longer valid. Please log in or reset your password.');
                this.$router.push({name: 'login-v2'});
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    created() {
        this.token = this.$route.params.token;
        this.loadSession();
    }
}
</script>

<style scoped>

</style>
