<template>
	<div class="sidebar-content">
		<div class="scrollable-nav">
			<ul class="sidebar-nav">
				<b-navbar-brand class="sidebar-img__logo-link" :style="logoBackgroundStyle" to="/">
					<img  v-if="!branding || !branding.logoUrl" class="sidebar-img__logo"  src="../../assets/cpdme-logo-white.png"
					alt="CPDme">
					<img  v-else class="sidebar-img__logo" :src="branding.logoUrl" alt="CPD Cloud">
				</b-navbar-brand>

				<SidebarMenuItem router-link="dashboard-home" :hide-titles="!mobile" title="Dashboard" icon="home" />

				<SidebarMenuItem router-link="create/diary" :hide-titles="!mobile" title="Diary" icon="edit"
					:locked="!isFullMember && entriesUsage >= 5" v-requires-role="'User-Basic'" />

				<SidebarMenuItem router-link="create/reflection" :hide-titles="!mobile" title="Reflection" :locked="!isFullMember"
					icon="edit" v-requires-feature="'Reflection'" v-if="roles.indexOf('User-Basic') > -1" />

				<SidebarMenuItem router-link="entries" :hide-titles="!mobile" title="Entries" icon="list" />

				<SidebarMenuItem router-link="profile" :hide-titles="!mobile" title="Profile"
					icon="account" />

				<SidebarMenuItem router-link="portfolio-builder-choose" :hide-titles="!mobile" title="Portfolio"
					:locked="!isFullMember" icon="folder-account-outline" />

				<SidebarMenuItem v-if="settings.allFeatures" router-link="my-webinars" :hide-titles="!mobile" title="My Webinars"
					icon="application" v-requires-feature="'Webinars'" />

				<SidebarMenuItem v-if="settings.allFeatures" router-link="my-files" :hide-titles="!mobile" title="My Files"
					icon="file-cabinet" />

				<SidebarMenuItem v-if="settings.allFeatures" router-link="shared-cpd" :hide-titles="!mobile" title="Shared CPD"
					badge="3" icon="forum-outline" v-requires-feature="'SharedCPD'" />

				<SidebarMenuItem v-if="settings.allFeatures" router-link="videos" :hide-titles="!mobile" title="CPD Hub"
					icon="video-outline" />

				<SidebarMenuItem v-if="settings.allFeatures" router-link="calendar" :hide-titles="!mobile" title="Calendar" icon="calendar" />


			</ul>

			<div class="organisation" v-if="isOrganisationManager">
				<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
					<span >Organisation Management</span>
					<a class="d-flex align-items-center text-muted" href="#">
						<span data-feather="plus-circle"></span>
					</a>
				</h6>
				<ul class="sidebar-nav mb-2">

						<SidebarMenuItem router-link="organisation/users-report" :hide-titles="!mobile" title="Users Report"
							icon="monitor-dashboard" v-requires-feature="'OrganisationUserReview'" />

						<SidebarMenuItem router-link="organisation/certificates" :hide-titles="!mobile" title="Certificates"
							icon="certificate" v-requires-feature="'OrganisationCertificates'" />

						<SidebarMenuItem router-link="organisation/shared-cpd" :hide-titles="!mobile" title="Shared CPD" icon="forum"
							v-requires-feature="'OrganisationSharedCPD'" />

						<SidebarMenuItem router-link="organisation/events" :hide-titles="!mobile" title="Events" icon="events"
							v-requires-feature="'OrganisationEvents'" />

				</ul>
			</div>

			<div class="admin" v-requires-role="'Admin'">
				<h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
					<span >Administration</span>
					<a class="d-flex align-items-center text-muted" href="#">
						<span data-feather="plus-circle"></span>
					</a>
				</h6>
				<ul class="sidebar-nav">
						<SidebarMenuItem router-link="admin/overview" :hide-titles="!mobile" title="Overview" icon="monitor-dashboard"
							v-requires-role="'Admin-Overview'" />

						<SidebarMenuItem router-link="admin/shared-cpd" :hide-titles="!mobile" title="Shared CPD" icon="forum"
							v-requires-role="'Admin-Shared-CPD'" />

						<SidebarMenuItem router-link="admin/activity-log" :hide-titles="!mobile" title="Activity Log" icon="history"
							v-requires-role="'Admin-Activity-Log'" />

						<SidebarMenuItem router-link="admin/organisations" :hide-titles="!mobile" title="Organisations"
							icon="organisation" v-requires-role="'Admin-Organisations'" />

						<SidebarMenuItem router-link="admin/certificates" :hide-titles="!mobile" title="Certificates"
							icon="certificate" v-requires-role="'Admin-Certificates'" />

						<SidebarMenuItem router-link="admin/memberships" :hide-titles="!mobile" title="Memberships" icon="memberships"
							v-requires-role="'Admin-Memberships'" />
				</ul>
			</div>
		</div>

		<div class="storage" :class="{ 'fixed': false }">
			<Usage :no-limit="isFullMember" :entries-usage="entriesUsage" :storage-usage="storageUsage" :loading="usageLoading" />
		</div>
	</div>
</template>

<script>
import SidebarMenuItem from "./SidebarMenuItem";
import Usage from "../Usage";
import { mapGetters } from "vuex";
import EventBus from "../../event-bus";
import Managers from '../../managers';

export default {
	name: "SidebarContent",
	props: {
		profile: Object,
		mobile: {
			type: Boolean,
			required: false,
			defaultValue: false,
		},
		branding: {
			type: Object,
			required: false, 
			defaultValue: null,
		}
	},
	components: {
		SidebarMenuItem,
		Usage
	},
	data: function () {
		return {
			admin: false,
			entriesUsage: null,
			storageUsage: null,
			usageLoading: '',
			roles: [],

			
		}
	},
	computed: {
		...mapGetters({
			isFullMember: 'authentication/isFullMember',
		}),
		isTablet() {
			return window.innerWidth >= 768 && window.innerWidth < 1024
		},
		...mapGetters({
			settings: 'settings/getSettings',
		}),
		isOrganisationManager() {
			return this.roles.indexOf('Organisation-Manager') > -1;
		}, 
		logoBackgroundStyle() {
			if (!this.branding) {
				return; 
			}

			return {
				"background-image": `linear-gradient(0deg, transparent,  #${this.branding.colourHex}, #${this.branding.colourHex}, #${this.branding.colourHex})`,
				"margin-right": 0,
			}
		},

	},
	methods: {
		logout: function () {
			this.$store.dispatch('authentication/logout').then(() => {
				this.$router.push({ name: 'login' });
			});
		},
		checkAdmin: function () {
			this.admin = this.roles.indexOf('Admin') > -1
		},
		loadUsage: function () {
			this.usageLoading = true;
			this.$http.get('usage').then((resp) => {
				let json = resp.body;
				this.entriesUsage = json.entries;
				this.storageUsage = json.storage;
				this.usageLoading = false;
			});
		}
	},
	created: function () {
		this.roles = Managers.authentication.roles();
		this.checkAdmin();
		this.loadUsage();
		EventBus.$on('user-changed', this.checkAdmin);
		EventBus.$on(['file-uploaded', 'entry-submitted', 'entry-deleted', 'user-changed', 'file-deleted'], () => {
			this.loadUsage();
		});
	}
}
</script>

<style lang="scss" scoped>
.sidebar-img__logo-link {
	display: flex;
	justify-content: center;
}

.sidebar-nav {
	list-style-type: none;
	padding-left: 0;

	padding-bottom: 12px;

	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: rgba(218, 219, 211, 0.1);
}

ul.nav .nav-link {
	font-weight: 500;
	color: #333;
	cursor: pointer;
}

ul.nav .nav-link .icon {
	margin-right: 4px;
	color: #999;
}

ul.nav .nav-link.active {
	color: #3374a3;
}

ul.nav .collapse {
	margin-top: 0;
}

ul.nav .collapse ul {
	list-style-type: none;
	margin-top: 0;
}

ul.sub-menu {
	list-style-type: none;
}

ul.nav .nav-link:hover .icon,
ul.nav .nav-link.active .icon {
	color: inherit;
}

.sidebar-heading {
	font-size: .75rem;
	text-transform: uppercase;

	>span {
		color: #fff;
		font-weight: 600;
		white-space: nowrap;
		transition: opacity 200ms ease-in-out;
	}
}

.full-opacity {
	opacity: 1;
	transition: opacity 200ms ease-in-out;
}

.no-opacity {
	opacity: 0;
	transition: opacity 200ms ease-in-out;
}

.storage {
	margin-bottom: 20px;
	width: 100%;
	height: 100px;
}

.storage.fixed {
	position: absolute;
	bottom: 0;
}

ul.nav .nav-link.disabled {
	color: gray;
}

.menu-separator {
	height: 20px;
	width: 100%;
}

.sidebar-img__logo {
	height: 42px;
	margin: 24px auto 48px auto;
}
</style>
