<template>
    <div class="statement">
        <p>
            Here is your opportunity to demonstrate to the HCPC how you have met each of the standards. The HCPC
            suggests that you select between 4-6 CPD activities that you have undertaken and then describe:
        </p>
        <ul>
            <li>What the activity was;</li>
            <li>What you learnt and;</li>
            <li>How you think the activity improved the quality of your work and benefited your service users.</li>
        </ul>
        <p>
            Writing the statement in this way can be a clear and simple way of showing the HCPC how you have met the
            Standards.
        </p>
        <p>
            As a guide, please aim for around 1,500 words in this section.
        </p>
        <b-overlay :show="loading">
            <div class="statement" >
                <b-form-group label="Standard 1 - Maintain a continuous, up-to-date and accurate record of my CPD activities">
                    <RichTextEditor v-model="statement.standard1" v-if="statement" />
                </b-form-group>
                <b-form-group label="Standard 2 - Demonstrate that my CPD activities are a mixture of learning activities relevant to current or future practice">
                    <RichTextEditor v-model="statement.standard2" v-if="statement" />
                </b-form-group>
                <b-form-group label="Standard 3 - Seek to ensure that my CPD has contributed to the quality of my practice and service and delivery">
                    <RichTextEditor v-model="statement.standard3" v-if="statement" />
                </b-form-group>
                <b-form-group label="Standard 4 - Seek to ensure that my CPD benefits the service user">
                    <RichTextEditor v-model="statement.standard4" v-if="statement" />
                </b-form-group>

                <p>{{$wordCountHtml(fullText)}} / 1500 words</p>
                <Actions ref="actions" :completed="completed" @mark-complete="markComplete" @save="save" @copy="copy" />
            </div>
        </b-overlay>

    </div>
</template>

<script>
    import Actions from "../../components/hcpc-portal/Actions";
    import RichTextEditor from "../../components/common/RichTextEditor";
    import Providers from '../../providers';
    import clipboardMixin from "../../utilities/clipboard";

    export default {
        name: "Statement",
        components: {RichTextEditor, Actions},
        mixins: [clipboardMixin],
        computed: {
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            },
            completed: {
                get() {
                    return this.$store.state.hcpcPortal.completed.statement;
                },
                set(val) {
                    this.$store.commit('hcpcPortal/markCompleted_Statement', val);
                }
            },
            fullText() {
                if (this.statement) {
                   return `${this.statement.standard1}<br />${this.statement.standard2}<br />${this.statement.standard3}<br />${this.statement.standard4}`;
                }
                return ''
            }
        },
        watch: {
            fullText(newVal, oldVal) {
                if (!this.loading) {
                    if (newVal.replace(/\n/g, '') !== oldVal.replace(/\n/g, '')) {
                        this.completed = false;
                    }
                }
            }
        },
        data() {
            return {
                loading: false,
                statement: null
            }
        },
        methods: {
            loadStatement() {
                this.loading = true;
                Providers.hcpcPortal.statement(this.accessToken).then((resp) => {
                    this.statement = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load statement', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            markComplete() {
                this.$store.commit('hcpcPortal/markCompleted_Statement', true);
            },
            save($e) {
                this.loading = true;
                Providers.hcpcPortal.updateStatement(this.accessToken, this.statement).then((resp) => {
                    $e.animate();
                    this.statement = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to save statement', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            copy() {
                this.copyRichToClipboard(this.fullText);
            }
        },
        created() {
            this.loadStatement();
        }
    }
</script>

<style scoped>
.form-group legend.col-form-label {
    font-weight: bold !important;
}
</style>
