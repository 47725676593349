<template>
    <div class="logo"></div>
</template>

<script>
    export default {
        name: "Logo"
    }
</script>

<style scoped>
    .logo {
        background-image: url('../../assets/cpdme-logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 320px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px
    }
</style>