<template>
    <div class="preview-activities">
        <b-modal title="Preview" header-bg-variant="info" header-text-variant="light" ref="modal" size="lg" scrollable>
            <pdf :resize="true" :src="pdfUrl" :page="currentPage" v-if="pdfUrl" @numpages="updatePageCount">
                <template slot="loading">
                    <Pulse />
                </template>
            </pdf>
            <template slot="modal-footer">
                <b-button variant="info" @click="download"><download-icon /> Download</b-button>
                <b-button variant="light" @click="cancel">Cancel</b-button>
                <b-button :disabled="!canMoveBack" @click="previous"><chevron-left-icon /> Previous Page</b-button>
                <b-button :disabled="!canMoveForward" @click="next">Next Page <chevron-right-icon /></b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft";
    import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
    import DownloadIcon from "vue-material-design-icons/Download";
    import Pulse from "../loaders/Pulse";

    export default {
        name: "PreviewActivityModal",
        components: {
            ChevronLeftIcon,
            ChevronRightIcon,
            DownloadIcon,
            pdf: () => import('pdfvuer'),
            Pulse
        },
        computed: {
            accessToken() {
                return this.$store.state.hcpcPortal.accessToken;
            },
            canMoveForward() {
                return this.currentPage < this.numPages;
            },
            canMoveBack() {
                return this.currentPage > 1;
            },
            entryParams() {
                let str = '';
                for (let entryId of this.entryIds) {
                    str += (str !== '' ? '&' : '') + encodeURIComponent('entryId[]') + '=' + entryId;
                }
                return str;
            },
            pdfUrl() {
                return this.apiRoot + `hcpc-portal/${this.accessToken}/entries-pdf?${this.entryParams}`;
            },
            downloadUrl() {
                return this.pdfUrl + '&download=true';
            }
        },
        data() {
            return {
                apiRoot: '',
                numPages: 0,
                currentPage: 1,
                entryIds: []
            }
        },
        methods: {
            show(entryIds) {
                this.entryIds = entryIds;
                this.$refs.modal.show();
            },
            cancel() {
                this.$refs.modal.hide();
            },
            updatePageCount(pageCount) {
                this.numPages = pageCount;
            },
            next() {
                this.currentPage++;
            },
            previous() {
                this.currentPage--;
            },
            download() {
                window.open(this.downloadUrl);
            }
        },
        created() {
            this.apiRoot = process.env.VUE_APP_API_ROOT;
        }
    }
</script>

<style scoped>

</style>
