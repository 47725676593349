const FormTools = {};

FormTools.install = function (Vue) {

    function flattenPage(page) {
        return flattenItems(activatedItems(page));
    }

    function flattenItems(items) {
        let data = new Map();
        for (let item of items) {
            if (item.type === 8) {
                if (item.value && item.value.length > 0) {
                    data.set(item.identifier, item.value.map(v => v.id))
                }
            } else {
                data.set(item.identifier, item.value);
            }
        }
        return data;
    }

    function activatedItems(page) {
        let items = Array();
        for (let item of page.items) {
            for (let activatedItem of activatedItemsForItem(item)) {
                items.push(activatedItem);
            }
        }
        return items;
    }

    function activatedItemsForItem(item) {
        let items = [];
        items.push(item);
        if (!isEmptyObject(item.activators)) {
            for (let activatorKey in item.activators) {
                if (item.activators.hasOwnProperty(activatorKey) && activatorKey === item.value) { // TODO: check array of values
                    let activatedItems = item.activators[activatorKey];
                    for (let activatedItem of activatedItems) {
                        for (let subItem of activatedItemsForItem(activatedItem)) {
                            items.push(subItem);
                        }
                    }
                }
            }
        }
        return items;
    }

    function mergeMaps(map1, map2) {
        let merged = new Map();
        map1.forEach((v, k) => merged.set(k, v));
        map2.forEach((v, k) => merged.set(k, v));
        return merged;
    }

    function isEmptyObject(obj) {
        for(let prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    Vue.prototype.$formTools = {};

    Vue.prototype.$formTools.flatten = (form) => {
        let pages = form.pages;
        let data = new Map();
        for (let page of pages) {
            data = mergeMaps(data, flattenPage(page));
        }
        return data;
    };

    Vue.prototype.$formTools.mapToJson = (map) => {
        let obj = Object.create(null);
        for (let [k,v] of map) {
            obj[k] = v;
        }
        return obj;

    };

    Vue.prototype.$formTools.activatedItems = activatedItems;

};

export default FormTools;