const clipboardMixin = {
    methods: {
        copyRichToClipboard(htmlStr) {
            let listener = (e) => {
                e.clipboardData.setData("text/html", htmlStr);
                e.clipboardData.setData("text/plain", htmlStr);
                e.preventDefault();
            }
            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
        }
    }
}

export default clipboardMixin;