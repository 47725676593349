<template>
    <b-form-group label-class="mb-0 label-title" :label="item.title" label-for="long-text__form-item">
        <b-textarea id="free-text__form-item" v-model="item.value" :rows="6" @focus="showHelp" @blur="hideHelp"/>
        <span class="wordcount">{{wordCount}}</span>
        <p class="help-text mt-2" v-html="helpText"></p>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'

    export default {
        name: "LongTextFormItem",
        extends: FormItem,
        computed: {
            wordCount: function() {
                let wc = this.$wordCount(this.item.value);
                if (wc === 0) return '';

                let suffix = wc !== 1 ? ' words' : ' word';
                return '' + wc + suffix;
            }
        }
    }
</script>

<style scoped>
    .wordcount {
        font-size: 12px;
        text-transform: uppercase;
    }
    .help-text {
        font-size: 12px;
    }
</style>